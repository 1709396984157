import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Web3Context } from "../web3/contexts/web3Context";

import Icon from "./Icon";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/PlayShare.svg";
const perfectScrollbarOptions = {
  wheelSpeed: 1,
  wheelPropagation: false,
};
export const Sidebar = () => {
  const location = useLocation()
  const { networkDetails } = useContext(Web3Context);
  const handleClassBody = () => {
    document.body.classList.toggle("ShowSidebar");
  };
  return (
    <>
      <div className="PR_sidebar">
        <div>
          <Link to="/" className="PR_logo">
            <img src={logo} alt="" />
          </Link>
        </div>
        <PerfectScrollbar
          className="scroll"
          options={perfectScrollbarOptions}
          style={{ height: "calc(100vh - 145px)", position: "relative" }}
        >
          <ul className="list-unstyled PR_menu">
            {/*  */}
           
            <li>
              <Link to="/home" className={`${location.pathname === "/home" ? "active" : ''}`} onClick={handleClassBody}>
                Dashboard
              </Link>
           
              <ul className="list-unstyled PR_submenu">
                {/* <li>
                  <ScrollLink
                    activeClass="active"
                    onClick={handleClassBody}
                    to="test1"
                    spy={true}
                    smooth={true}
                  >
                    <Icon name="ongoing" className="mr-2 w-20px" />
                    Ongoing
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    onClick={handleClassBody}
                    activeClass="active"
                    to="test2"
                    spy={true}
                    smooth={true}
                  >
                    <Icon name="upcoming" className="mr-2 w-20px" />
                    Upcoming
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    onClick={handleClassBody}
                    activeClass="active"
                    to="test3"
                    spy={true}
                    smooth={true}
                  >
                    <Icon name="announced" className="mr-2 w-20px" />
                    Completed
                  </ScrollLink>
                </li> */}
              </ul>
            </li>
            <hr></hr>
            {/* <li><Link to="/"></Link></li> */}

            {networkDetails.isAdmin && (
              <li>
                <Link to="/create" className={`${location.pathname === "/create" ? "active" : ''}`} onClick={handleClassBody}>
                  Create token Sale
                </Link>
              </li>
            )}
            {networkDetails.isAdmin && (
              <li>
                <Link to="/campaigns-history" className={`${location.pathname === "/campaigns-history" ? "active" : ''}`} onClick={handleClassBody}>
                  Token Sale Campaign History
                </Link>
              </li>
            )}
             <hr></hr>
          
            {networkDetails.isAdmin && (
              <li>
                <Link to="/create-mystery" className={`${location.pathname === "/create-mystery" ? "active" : ''}`} onClick={handleClassBody}>
                  Create Mystery Box
                </Link>
              </li>
            )}

            {/* <li><Link to="/detail">Detail</Link></li> */}


            {networkDetails.isAdmin && (
              <li>
                <Link to="/mysteryBox" className={`${location.pathname === "/mysteryBox" ? "active" : ''}`} onClick={handleClassBody}>
                  Mysterybox Listing
                </Link>
              </li>
            )}
     <hr></hr>
{networkDetails.isAdmin && (
              <li>
                <Link to="/banners" className={`${location.pathname === "/banners" ? "active" : ''}`} onClick={handleClassBody}>
                  Dashboard Banners
                </Link>
              </li>
            )}
{/* 
            <li>
              <Link to="/mystery-boxes-details" onClick={handleClassBody}>
                Mystery-Boxes
              </Link>
              <ul className="list-unstyled PR_submenu">
                <li>
                  <Link to="/mystery-boxes-details" className={`${location.pathname === "/mystery-boxes-details" ? "active" : ''}`} onClick={handleClassBody}>
                    <Icon name="mystery" className="mr-2 w-20px" /> My Master Box
                  </Link>
                </li></ul>
            </li> */}
            {/* {
              networkDetails.isAdmin  == false ?  (<li>
               <Link to="/my-campaigns-history"  onClick={handleClassBody}>My Campaign History</Link>
              </li>): ''
            } */}
          </ul>
        </PerfectScrollbar>
      </div>
    </>
  );
};
