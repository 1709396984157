import React, { useContext, useEffect, useState } from "react";

import { Container, Table } from "react-bootstrap";
import { createMethods } from "../../functions/create";
// import { campignMethods } from "../../functions/campaign";

import { Web3Context } from "../../web3/contexts/web3Context";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
// import { enviornment } from "../../constants/constants";
// import AddWhiteList from "../../Components/AddWhiteList";
import { useHistory, useParams } from "react-router-dom";

const UserTranctions = () => {
  const { networkDetails } = useContext(Web3Context);
  const { campaignId } = useParams();

  const history = useHistory();
  // const inputRef = useRef(null);

  const [allCampaignsData, setAllCampaignsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (networkDetails && networkDetails.connected) {
      if (!networkDetails.isAdmin) {
        getCamps(networkDetails.address);
      } else {
        // setLoading(true);
        return toast.warning("You are a admin.", "Attention");
      }
    }
    // else {
    // setLoading(false);
    // toast.warning("You have to connect first !", "Attention");

    // }
  }, [networkDetails]);

  const getCamps = async (userADR) => {
    let response = await createMethods.getUserDashboard(userADR);
    if (response.data.success) {
      setLoading(false);
      setAllCampaignsData(response.data.data);
    } else {
      setLoading(false);
      return toast.warning("Error while Fetching.", "Attention");
    }
  };
  //

  const getClassName = (status) => {
    switch (status) {
      case undefined: {
        return "Pending";
      }
      case null: {
        return "Pending";
      }
      case 0: {
        return "InProgress";
      }
      case 1: {
        return "Approved";
      }
      case -1: {
        return "Failed";
      }

      default:
        return "Pending";
    }
  };

  const getCreateClassName = (status) => {
    switch (status) {
      case undefined: {
        return "Pending";
      }
      case null: {
        return "Pending";
      }
      case 0: {
        return "InProgress";
      }
      case 1: {
        return "Created";
      }
      case -1: {
        return "Failed";
      }

      default:
        return "Pending";
    }
  };
  return (
    <>
      {loading && <Loader />}
      <Container fluid className="px-md-5 px-4">
        <h1 className="text-white mb-5">My Campaigns History</h1>

          <Table className="text-white">
            <thead>
              <tr>
                <th> Campaign Name</th>
                <th>Token symbol</th>
                <th>Create Status</th>
                <th>Approve Status</th>
              </tr>
            </thead>
            <tbody>
              {allCampaignsData &&
                allCampaignsData.length ?
                allCampaignsData.map((res, ind) => {
                  return (
                    <tr key={ind + 1}>
                      <td
                        onClick={() => {
                          history.push(`/detail/${res._id}`);
                        }}
                      >
                        {res.projectName}
                      </td>
                      <td>{res.tokenName}</td>
                      <td>{getCreateClassName(res.create_status)}</td>
                      <td>
                      {getClassName(res.approve_status)}
                      </td>
                    </tr>
                  );
                }) : (

                  <tr className="text-center">
                    <td colSpan="4" >  No records Found.</td>
                  </tr>)}

            </tbody>
          </Table>
     
      </Container>
    </>
  );
};
export default UserTranctions;
