import React from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

// import ArrowLeftWhite from "./../../../assets/images/ArrowLeftWhite.svg";
// import Frame from "./../../../assets/images/Frame.svg";
// import info from "../../../assets/images/info.svg";


const DeleteModal = (props) => {
  const { modalShow, onCloseModal ,deleteData} = props;
  const handleDelete = () => {
    deleteData()
  };
  return (
    <>
      <Modal show={modalShow} onHide={onCloseModal} size="lg" centered>
        <Modal.Header className="px-4 pb-0 pt-4 border-0" closeButton>
          <div className="h2 text-center py-2 w-100">Remove the FAQ</div>
        </Modal.Header>
        <Modal.Body className="text-center px-4 pb-5">
          {/* <img src={Frame} alt="" /> */}
          <div className="p2 py-4">
            Are you sure you want to
            <br />
            remove the Item?
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Button variant="green" className="px-4" onClick={onCloseModal}>
              {/* <img src={ArrowLeftWhite} alt="" className="mr-2" /> */}
              BACK
            </Button>
            <Button
              variant="blue"
              onClick={handleDelete}
              className="ml-3 text-uppercase"
            >
              Remove Item
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteModal;
