import React, { useEffect, useContext, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, FieldArray, Field, Form } from "formik";
import * as Yup from "yup";
import { Button, Col } from "react-bootstrap";
import { Web3Context } from "../web3/contexts/web3Context";
import { enviornment } from "../constants/constants";
import { campignMethods } from "../functions/campaign";
import { createMethods } from "../functions/create";
import { toast } from "react-toastify";
import { createMysteryMethods } from "../functions/createMystery";
import { ethers } from "ethers";
import ReactTooltip from 'react-tooltip';


const AddMysteryBoxItem = (props) => {
  const formikRef = useRef();
  const { networkDetails } = useContext(Web3Context);
  const [isApproveEnable, setIsApproveEnable] = useState(true);
  const [isApproveNFTEnable, setIsApproveNFTEnable] = useState(false);

  const [itemCategory, setItemCategory] = useState("");
  const { addItemBox, onCloseItemBox, loading, setLoading, item, getMysteryBoxes } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('----------in side page')
  }, []);

  const initialValues = {
    network_type: "",
    item_category: "",
    item_id: "",
    item_amount: "",
    item_value: "",
    item_count: "",
    erc20_address: "",
    erc721_address: "",
    NFT_idARY: [],
    item_decimal: ""
  };

  const validationSchema = Yup.object().shape({
    network_type: Yup.string().nullable().required("Network type is required."),
    item_category: Yup.string().trim().required("Item category is required"),
    item_id: Yup.string().trim().required("Item id is required"),
    item_amount:
      itemCategory !== "Premium NFT"
        ? Yup.number().min(0).required("Item amount is required")
        : Yup.number(),
    item_value:
      itemCategory !== "Airdrop"
        ? Yup.number().min(0).required("Item value is required")
        : Yup.number(),
    item_count: Yup.number().min(0).required("Item count is required"),
    erc20_address:
      (itemCategory === "IGO Allocation Token" || itemCategory === "IGO+NFT" || itemCategory === "Airdrop")
        ? Yup.string()
          .trim()
          .required("Erc20 address is required")
          .matches(/^0x[a-fA-F0-9]{40}$/, "please enter valid erc20 address")
        : Yup.string(),
    erc721_address:
      (itemCategory === "Premium NFT" || itemCategory === "IGO+NFT")
        ? Yup.string()
          .trim()
          .required("Erc721 address is required")
          .matches(/^0x[a-fA-F0-9]{40}$/, "please enter valid Erc721 address")
        : Yup.string(),
    NFT_idARY:
      (itemCategory === "Premium NFT" || itemCategory === "IGO+NFT")
        ? Yup.array().of(Yup.number().min(0)
          .required("NFT Id is required."))
        // of(

        // Yup.object().shape({
        //   address: Yup.string()
        //     .trim()
        //     .matches(
        //       /^0x[a-fA-F0-9]{40}$/,
        //       "please enter valid NFT address"
        //     )
        //     .required("NFT id is required"),
        // })
        // )
        : Yup.array(),

    item_decimal: ((itemCategory !== "Airdrop" && itemCategory !== "Premium NFT" ) || itemCategory === "IGO+NFT" || itemCategory === "IGO Allocation Token")
      ? Yup.number().min(0).required("Item decimal is required")
      : Yup.number(),

  });

  const onCloseModal = () => {
console.log('-0---------')
    onCloseItemBox();

    setIsApproveEnable(true)
    setLoading(false);
    
    setNewtorkType()
    getMysteryBoxes();
    formikRef.current?.resetForm();

    let a = networkDetails.chainData && networkDetails.chainData != undefined ? networkDetails.chainData.network_type : 'other';
    if (formikRef.current) {
      formikRef.current.setFieldValue("network_type", a);
    }


  };
  const setNewtorkType = () => {
    if (networkDetails && networkDetails.connected) {
      let a = networkDetails.chainData && networkDetails.chainData != undefined ? networkDetails.chainData.network_type : 'other';
      if (formikRef.current) {
        formikRef.current.setFieldValue("network_type", a);
      }
    }
  }

  const itemCategoryChange = (field, event, resetForm, setFieldValue) => {
    resetForm();
    let a =
      networkDetails.chainData && networkDetails.chainData != undefined
        ? networkDetails.chainData.network_type
        : "other";

    setFieldValue("network_type", a);
    field.onChange(event);
    setItemCategory(event.target.value);
  };

  useEffect(() => {
    if (networkDetails && networkDetails.connected) {
      // getTotalTicketAPI();
      let a =
        networkDetails.chainData && networkDetails.chainData != undefined
          ? networkDetails.chainData.network_type
          : "other";

      if (formikRef.current) {
        formikRef.current.setFieldValue("network_type", a);
      }
    }
  }, [networkDetails]);

  const onChangeItemCount = (e, values, setValues, field) => {
    // update dynamic form
    if ((values.item_category === "Premium NFT" || values.item_category === "IGO+NFT")) {
      const NFT_idARY = [...values.NFT_idARY];
      const numberOfRows = e.target.value || 0;
      const previousNumber = parseInt(values.item_count || "0");
      if (previousNumber < numberOfRows) {
        for (let i = previousNumber; i < numberOfRows; i++) {
          NFT_idARY.push("");
        }
      } else {
        for (let i = previousNumber; i >= numberOfRows; i--) {
          NFT_idARY.splice(i, 1);
        }
      }
      setValues({ ...values, NFT_idARY });
    }
    // call formik onChange method
    field.onChange(e);
  };
  const onSubmit = async (fields, { resetForm }) => {
    console.log('------------------------------0.0111', )

    try {
      console.log('------------------------------0.000', )

      setLoading(true);
      if (networkDetails.connected) {
        let obj = fields;
        // ------------------------------------------------------------------FIRST CASE ------------------------------------
        console.log('------------------------------0.1', );
        if (obj.item_category === "Premium NFT") {
          console.log('------------------------------0.1', )

          let balanceDetail = await getSCBalanceDetails(obj.erc721_address, 'erc721',0);
          console.log('------------------------------0', )

          obj.mysteryboxid = item.mysterySC_id;
          obj.isERC20 = false;
          obj.isERC721 = true;
          obj.total_erc20 = 0
          obj.isAirdrop = false;
          obj.erc20_address = "0x0000000000000000000000000000000000000000";
          obj.item_amount = 0;
          if (balanceDetail && balanceDetail.NFTInstance != undefined) {
            console.log('------------------------------1', )

            let ownerARY = []
            console.log('------------------------------2', )

            for (let i = 0; i < obj.NFT_idARY.length; i++) {

              await balanceDetail.NFTInstance.methods.ownerOf(obj.NFT_idARY[i]).call({ from: networkDetails.address }).then((data) => {

                if (data.toLowerCase() == networkDetails.address.toLowerCase()) {
                  ownerARY.push(true);
                }

              }).catch((er) => {
                console.log('------------------------------er', er)

              });

            }
            console.log('------------------------------3', )


            if (ownerARY.length == obj.NFT_idARY.length) {
              console.log('------------------------------4', )

              // 
              let isApprovedForAll = await balanceDetail.NFTInstance.methods.isApprovedForAll(networkDetails.address, enviornment[networkDetails.networkId].mysterySC).call({ from: networkDetails.address });

              if (isApprovedForAll && isApproveEnable) {
                setLoading(false);
                return setIsApproveEnable(false);
              }            console.log('------------------------------5', )


              if (isApproveEnable && !isApprovedForAll) {
                console.log('------------------------------6', )

                return await balanceDetail.NFTInstance.methods.setApprovalForAll(enviornment[networkDetails.networkId].mysterySC, true).
                  send({ from: networkDetails.address }).then(async (data) => {
                    if (data) {
                      setIsApproveEnable(false);
                      return setLoading(false);
                      // await props.handleFormSubmit2(values);
                      // toast.success("Tokens approved successfully.");
                      // await props.next();
                    }
                  })
                  .catch((er) => {
                    setLoading(false);
                    toast.error(er.message);
                  });

              }
              // 

            } else {
              console.log('------------------------------7', )

              toast.error("You are not Owner of this NFT ids.");
              setLoading(false);
              return;
            }
          }
        }
        // ----------------------------------------------------------------DONE--FIRST CASE ------------------------------------

        // ------------------------------------------------------------------SECOND CASE ------------------------------------
        if (fields.item_category === "IGO Allocation Token") {
          let balanceDetail = await getSCBalanceDetails(obj.erc20_address, 'erc20', obj.item_decimal);
          console.log('-------------balanceDetail------------', balanceDetail)

          obj.mysteryboxid = item.mysterySC_id;
          obj.isERC20 = true;
          obj.total_erc20 = parseInt(obj.item_amount) * parseInt(obj.item_count)
          obj.isERC721 = false;
          obj.NFT_idARY = []
          obj.isAirdrop = false;
          obj.erc721_address = "0x0000000000000000000000000000000000000000";

          if (balanceDetail && balanceDetail.balanceToken > 0) {
            let TAMT = obj.total_erc20;
            if (
              balanceDetail.allowancToken >= TAMT &&
              balanceDetail.balanceToken >= TAMT &&
              isApproveEnable
            ) {
              setLoading(false);
              return setIsApproveEnable(false);
            }

            if (balanceDetail.allowancToken >= TAMT && balanceDetail.balanceToken >= TAMT && !isApproveEnable) {

            } else {

              if (balanceDetail.balanceToken > TAMT && isApproveEnable) {
                return await createMethods
                  .approveWithDec(
                    balanceDetail.tokenInstance,
                    enviornment[networkDetails.networkId].mysterySC,
                    ethers.utils.parseUnits(balanceDetail.balanceToken, obj.item_decimal),
                    networkDetails.address
                  )
                  .then(async (data) => {
                    if (data) {
                      setIsApproveEnable(false);
                      return setLoading(false);
                      // await props.handleFormSubmit2(values);
                      // toast.success("Tokens approved successfully.");
                      // await props.next();
                    }
                  })
                  .catch((er) => {
                    setLoading(false);
                    toast.error(er.message);
                  });
              } else {
                setLoading(false);
                toast.error(
                  "You don't have enough presale tokens to Create Item."
                );
                return;
              }
            }
          } else {
            toast.error("balance is zero");
            setLoading(false);
            return;
            // balance is zero
          }

        }
        // ----------------------------------------------------------------DONE--SECOND CASE ------------------------------------

        // ------------------------------------------------------------------ThIRD CASE ------------------------------------

        if (fields.item_category === "IGO+NFT") {

          let ERC20Detail = await getSCBalanceDetails(obj.erc20_address, 'erc20', obj.item_decimal);
          let ERC721Detail = await getSCBalanceDetails(obj.erc721_address, 'erc721', obj.item_decimal);


          obj.mysteryboxid = item.mysterySC_id;
          obj.isERC20 = true;
          obj.total_erc20 = parseInt(obj.item_amount) * parseInt(obj.item_count)
          obj.isERC721 = true;
          obj.isAirdrop = false;


          if (ERC20Detail && ERC20Detail.balanceToken > 0) {
            let TAMT = obj.total_erc20;
            if (
              ERC20Detail.allowancToken >= TAMT &&
              ERC20Detail.balanceToken >= TAMT &&
              isApproveEnable
            ) {
              setLoading(false);
              setIsApproveEnable(false);
              setIsApproveNFTEnable(true)
              return;
            }

            if (ERC20Detail.allowancToken >= TAMT && ERC20Detail.balanceToken >= TAMT && !isApproveEnable) {
              if (isApproveNFTEnable && ERC721Detail && ERC721Detail.NFTInstance != undefined) {


                let ownerARY = []

                for (let i = 0; i < obj.NFT_idARY.length; i++) {

                  // let owner = await ERC721Detail.NFTInstance.methods.ownerOf(obj.NFT_idARY[i]).call({ from: networkDetails.address });

                  // if (owner.toLowerCase() == networkDetails.address.toLowerCase()) {
                  //   ownerARY.push(true);
                  // }

                  await ERC721Detail.NFTInstance.methods.ownerOf(obj.NFT_idARY[i]).call({ from: networkDetails.address }).then((data) => {

                    if (data.toLowerCase() == networkDetails.address.toLowerCase()) {
                      ownerARY.push(true);
                    }

                  }).catch((er) => {
                    console.log('------------------------------er', er)

                  });

                }


                if (ownerARY.length == obj.NFT_idARY.length) {

                  //   // 
                  let isApprovedForAll = await ERC721Detail.NFTInstance.methods.isApprovedForAll(networkDetails.address, enviornment[networkDetails.networkId].mysterySC).call({ from: networkDetails.address });

                  if (isApprovedForAll && isApproveNFTEnable) {
                    setLoading(false);
                    return setIsApproveNFTEnable(false);
                  }

                  if (isApproveNFTEnable && !isApprovedForAll) {

                    return await ERC721Detail.NFTInstance.methods.setApprovalForAll(enviornment[networkDetails.networkId].mysterySC, true).
                      send({ from: networkDetails.address }).then(async (data) => {
                        if (data) {
                          setIsApproveNFTEnable(false);
                          return setLoading(false);

                        }
                      })
                      .catch((er) => {
                        setLoading(false);
                        toast.error(er.message);
                      });

                  }

                } else {
                  toast.error("You are not Owner of this NFT ids.");
                  setLoading(false);
                  return;
                }


              }
            } else {

              if (ERC20Detail.balanceToken > TAMT && isApproveEnable) {
                return await createMethods
                  .approve(
                    ERC20Detail.tokenInstance,
                    enviornment[networkDetails.networkId].mysterySC,
                    ERC20Detail.balanceToken,
                    networkDetails.address
                  )
                  .then(async (data) => {
                    if (data) {
                      setIsApproveEnable(false);
                      setIsApproveNFTEnable(true)
                      return setLoading(false);
                      // await props.handleFormSubmit2(values);
                      // toast.success("Tokens approved successfully.");
                      // await props.next();
                    }
                  })
                  .catch((er) => {
                    setLoading(false);
                    toast.error(er.message);
                  });
              } else {
                setLoading(false);
                toast.error(
                  "You don't have enough presale tokens to Create Item."
                );
                return;
              }
            }
          } else {
            toast.error("balance is zero");
            setLoading(false);
            return;
            // balance is zero
          }



        }
        // ----------------------------------------------------------------DONE--ThIRD CASE ------------------------------------
        // ------------------------------------------------------------------FOUR CASE ------------------------------------

        if (fields.item_category === "Airdrop") {
          obj.item_value = 0;
          obj.total_erc20 = 0
          obj.erc721_address = "0x0000000000000000000000000000000000000000";
          obj.isERC20 = false;
          obj.NFT_idARY = []
          obj.isERC721 = false;
          obj.isAirdrop = true;

        }
        // ----------------------------------------------------------------DONE--FOUR CASE ------------------------------------


        // let SCDetails = await getSCBalanceDetails();
        let APIobj = {
          mystery_id: item._id,
          mysterySC_id: item.mysterySC_id,
          item_amount: obj.item_amount,
          item_value: obj.item_value,
          item_count: obj.item_count,
          item_category: obj.item_category,
          item_id: obj.item_id,
          erc20_address: obj.erc20_address,
          erc721_address: obj.erc721_address,
          is_erc20: obj.isERC20,
          is_erc721: obj.isERC721,
          is_Airdrop: obj.isAirdrop,
          NFT_idARY: obj.NFT_idARY,
          network_type: obj.network_type,
          walletAddress: networkDetails.address,
          create_transaction_hash: '',
          item_decimal: obj.item_decimal,
        };

        await createMysteryMethods
          .createItem(
            APIobj
          )
          .then((resApprove) => {
            toast.info(
              "Item created successfully."
            );
            setIsApproveEnable(true)
            setLoading(false);
            resetForm();
            setNewtorkType()
            getMysteryBoxes();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
        // await createMysteryMethods
        //   .createItem(
        //     SCDetails.mysteryInstance,
        //     networkDetails.address,
        //     APIobj.mysterySC_id,
        //     APIobj.item_category,
        //     APIobj.is_erc20,
        //     APIobj.is_erc721,
        //     APIobj.is_Airdrop,
        //     APIobj.item_amount,
        //     APIobj.item_value,
        //     APIobj.item_count,
        //     APIobj.erc20_address,
        //     APIobj.erc721_address,
        //     APIobj.NFT_idARY,
        //     APIobj
        //   )
        //   .then((resApprove) => {
        //     toast.info(
        //       "Transaction confirmed."
        //     );
        //     setLoading(false);
        //     resetForm();
        //     window.location.reload()
        //     // history.push("/dashboard");
        //   })
        //   .catch((er) => {
        //     if (er && er.code) {
        //       toast.error(er.message);
        //       setLoading(false);
        //     }
        //   });


      } else {
        setLoading(false);
        return toast.warning("You have to connect first !", "Attention");
      }
    } catch (err) {
      toast.error("There is some error please, try again after some time.");
      setLoading(false);
    }
  };


  const getSCBalanceDetails = async (token_address, type, decimal) => {
    let obj = {
      balanceToken: 0,
      allowancToken: 0,
      tokenInstance: "",

      NFTInstance: '',

    };


    if (type == 'erc20') {
      let instance = await createMethods.getInstance(
        networkDetails.web3,
        token_address,
        enviornment[networkDetails.networkId].commanERCABI
      );

      if (instance && instance !== undefined) {
        obj.tokenInstance = instance;

        obj.balanceToken = await createMethods.getBalanceWithDec(
          instance,
          networkDetails.address
        );
        obj.balanceToken = ethers.utils.formatUnits(obj.balanceToken, decimal);


        obj.allowancToken = await createMethods.allowanceWithDec(
          instance,
          networkDetails.address,
          enviornment[networkDetails.networkId].mysterySC
        );
        obj.allowancToken = ethers.utils.formatUnits(obj.allowancToken, decimal);

      }
    } else {
      let instance = await createMethods.getInstance(
        networkDetails.web3, token_address, enviornment[networkDetails.networkId].ERC721ABI
      );
      if (instance && instance !== undefined) {
        obj.NFTInstance = instance;


      }
    }


    return obj;
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values, touched, setValues, setFieldValue, resetForm }) => (
          <Modal
            show={addItemBox}
            onHide={onCloseModal}
            className="PR_modal"
            centered
            size="lg"
          >
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
              <div className=" py-2 text-center text-white">Add items</div>
            </Modal.Header>
            <Modal.Body className="px-4">
              <>
                <Form>
                  <div className="row">
                    <Col md={6}>
                      <div className="form-group">
                        <label className="fs-14px text-white opacity-70 d-flex align-items-baseline">
                          Network Type

                        </label>
                        <Field name="network_type">
                          {({ field }) => (
                            <select
                              {...field}
                              disabled
                              className="form-control PR_input re_input item_categy_select"
                            // onChange={(e) => handleSelectTask(field, e)}
                            >
                              <option value="">Select one</option>
                              <option key="binance" value="binance">
                                Binance Smart Chain
                              </option>
                              <option key="ethereum" value="ethereum">
                                Ethereum Network
                              </option>
                              <option key="harmony" value="harmony">
                                Harmony
                              </option>
                              <option key="polygon" value="polygon">
                                Polygon
                              </option>
                              <option key="other" value="other">
                                Please select valid network
                              </option>
                            </select>
                          )}
                        </Field>
                        {touched.network_type && errors.network_type ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block  text-danger">
                              {errors.network_type}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group item_categy_select">
                        <label className="fs-14px text-white opacity-70 d-flex align-items-baseline">
                          Item Category<span className="ml-2 info_icon ">
                            <p data-tip="Please select Category between IGO Allocation Token ,  Premium NFT , IGO+NFT , Airdrop." >
                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABf0lEQVRIidWVTU4CQRCFqyGwEW9gPAC60gOIC13qYIxXIBDjzyXIxDPoHeQaRheiC0VdafQAbAQjnwuqpSPd8yMrKul0Zt7rejVvqrtF5j1MEgiURSTSsSYiSwq9iciNiHRE5NIYM8ytDOwBL6THM1DPk7gAnDkJboEjoAos6FgBjoGuw4uBQhYBm/wTaCQt0mKaygWIs9hik2/k+OqaIxKFSGXH80aAA0AAayn8BJR8hAPHc68tKQJF4E4p+/a9m8h2wrkxZuRLYjQC2LeIXOjjtE3Ao6pXfQmyhHYXwIMP7CtYSUgQtEjxRaX07bv0vs0X1r5fi12Bd52XZxCwaz98Atc6b80gsK3z1RTitGl3hja9/9umLqHM+OACaP5D4FDhnnejKanuHBU1L8m/bhMYACNgJ40cOyItoJjALWrlA13TzlJNwRFBt/+JbqKKjlXg1PF8BLRD/y4kFDE+uNKil2RL2pVZEpFdGZ8t6zK5Ml9lcmV2jDFfmSufu/gBIo4rqpw825AAAAAASUVORK5CYII="></img>
                            </p><ReactTooltip effect="solid"
                              aria-haspopup="true"
                              className="custom-tooltip" offset={{ 'left': -130, 'top': -50 }} /></span>
                        </label>
                        <Field name="item_category">
                          {({ field }) => (
                            <select
                              {...field}
                              disabled={!isApproveEnable}
                              className="form-control PR_input   re_input"
                              onChange={(e) =>
                                itemCategoryChange(
                                  field,
                                  e,
                                  resetForm,
                                  setFieldValue
                                )
                              }
                            >
                              <option value="" disabled hidden>
                                Select one
                              </option>
                              <option
                                key="IGO Allocation Token"
                                value="IGO Allocation Token"
                              >
                                IGO Allocation Token
                              </option>
                              <option key="Premium NFT" value="Premium NFT">
                                Premium NFT
                              </option>
                              <option key="IGO+NFT" value="IGO+NFT">
                                IGO+NFT
                              </option>
                              <option key="Airdrop" value="Airdrop">
                                Airdrop
                              </option>
                            </select>
                          )}
                        </Field>
                        {touched.item_category && errors.item_category ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block  text-danger">
                              {errors.item_category}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="form-group">
                        <label className="fs-14px text-white opacity-70">
                          Item Id
                        </label>

                        <Field name="item_id">
                          {({ field }) => (
                            <input
                              {...field}
                              disabled={!isApproveEnable}
                              type="text"
                              placeholder="Item Id "
                              className={
                                "form-control PR_input " +
                                (errors.item_id && touched.item_id
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          )}
                        </Field>
                        {touched.item_id && errors.item_id ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">
                              {errors.item_id}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    {itemCategory !== "Premium NFT" && itemCategory !== "" && (
                      <Col md={6}>
                        <div className="form-group">
                          <label className="fs-14px text-white opacity-70 d-flex">
                            Item Amount   <span className="ml-2 info_icon ">
                              <p data-tip="Please enter offered ERC20 amount which we have to transfer to the user." >
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABf0lEQVRIidWVTU4CQRCFqyGwEW9gPAC60gOIC13qYIxXIBDjzyXIxDPoHeQaRheiC0VdafQAbAQjnwuqpSPd8yMrKul0Zt7rejVvqrtF5j1MEgiURSTSsSYiSwq9iciNiHRE5NIYM8ytDOwBL6THM1DPk7gAnDkJboEjoAos6FgBjoGuw4uBQhYBm/wTaCQt0mKaygWIs9hik2/k+OqaIxKFSGXH80aAA0AAayn8BJR8hAPHc68tKQJF4E4p+/a9m8h2wrkxZuRLYjQC2LeIXOjjtE3Ao6pXfQmyhHYXwIMP7CtYSUgQtEjxRaX07bv0vs0X1r5fi12Bd52XZxCwaz98Atc6b80gsK3z1RTitGl3hja9/9umLqHM+OACaP5D4FDhnnejKanuHBU1L8m/bhMYACNgJ40cOyItoJjALWrlA13TzlJNwRFBt/+JbqKKjlXg1PF8BLRD/y4kFDE+uNKil2RL2pVZEpFdGZ8t6zK5Ml9lcmV2jDFfmSufu/gBIo4rqpw825AAAAAASUVORK5CYII="></img>
                              </p><ReactTooltip effect="solid"
                                aria-haspopup="true"
                                className="custom-tooltip" offset={{ 'left': -130, 'top': -50 }} /></span>

                          </label>
                          <Field name="item_amount">
                            {({ field }) => (
                              <input
                                {...field}
                                disabled={!isApproveEnable}
                                type="number"
                                min={0}
                                placeholder="Item Amount "
                                className={
                                  "form-control PR_input " +
                                  (errors.item_amount && touched.item_amount
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            )}
                          </Field>
                          {touched.item_amount && errors.item_amount ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block text-danger">
                                {errors.item_amount}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    )}
                    {itemCategory !== "Airdrop" && itemCategory !== "" && (
                      <Col md={6}>
                        <div className="form-group">
                          <label className="fs-14px text-white opacity-70 d-flex">
                            Item Value  <span className="ml-2 info_icon ">
                              <p data-tip="Please enter USDC payment value." >
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABf0lEQVRIidWVTU4CQRCFqyGwEW9gPAC60gOIC13qYIxXIBDjzyXIxDPoHeQaRheiC0VdafQAbAQjnwuqpSPd8yMrKul0Zt7rejVvqrtF5j1MEgiURSTSsSYiSwq9iciNiHRE5NIYM8ytDOwBL6THM1DPk7gAnDkJboEjoAos6FgBjoGuw4uBQhYBm/wTaCQt0mKaygWIs9hik2/k+OqaIxKFSGXH80aAA0AAayn8BJR8hAPHc68tKQJF4E4p+/a9m8h2wrkxZuRLYjQC2LeIXOjjtE3Ao6pXfQmyhHYXwIMP7CtYSUgQtEjxRaX07bv0vs0X1r5fi12Bd52XZxCwaz98Atc6b80gsK3z1RTitGl3hja9/9umLqHM+OACaP5D4FDhnnejKanuHBU1L8m/bhMYACNgJ40cOyItoJjALWrlA13TzlJNwRFBt/+JbqKKjlXg1PF8BLRD/y4kFDE+uNKil2RL2pVZEpFdGZ8t6zK5Ml9lcmV2jDFfmSufu/gBIo4rqpw825AAAAAASUVORK5CYII="></img>
                              </p><ReactTooltip effect="solid"
                                aria-haspopup="true"
                                className="custom-tooltip" offset={{ 'left': -130, 'top': -50 }} /></span>
                          </label>
                          <Field name="item_value">
                            {({ field }) => (
                              <input
                                {...field}
                                disabled={!isApproveEnable}
                                type="number"
                                min={0}
                                placeholder="Item Value "
                                className={
                                  "form-control PR_input " +
                                  (errors.item_value && touched.item_value
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            )}
                          </Field>
                          {touched.item_value && errors.item_value ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block text-danger">
                                {errors.item_value}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    )}
                    <Col md={6}>
                      <div className="form-group">
                        <label className="fs-14px text-white opacity-70">
                          Item Count
                        </label>
                        <Field name="item_count">
                          {({ field }) => (
                            <input
                              {...field}
                              type="number"
                              disabled={!isApproveEnable}
                              min={0}
                              onChange={(e) =>
                                onChangeItemCount(e, values, setValues, field)
                              }
                              placeholder="Item Count"
                              className={
                                "form-control PR_input " +
                                (errors.item_count && touched.item_count
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          )}
                        </Field>
                        {touched.item_count && errors.item_count ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block text-danger">
                              {errors.item_count}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    {(itemCategory === "IGO Allocation Token" ||
                      itemCategory === "IGO+NFT" || itemCategory === "Airdrop") && (
                        <Col md={6}>
                          <div className="form-group">
                            <label className="fs-14px text-white opacity-70">
                              Erc20 Address
                            </label>
                            <Field name="erc20_address">
                              {({ field }) => (
                                <input
                                  {...field}
                                  disabled={!isApproveEnable}
                                  type="text"
                                  placeholder="Erc20 Address"
                                  className={
                                    "form-control PR_input " +
                                    (errors.erc20_address && touched.erc20_address
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              )}
                            </Field>
                            {touched.erc20_address && errors.erc20_address ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">
                                  {errors.erc20_address}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      )}

                    {(itemCategory === "IGO Allocation Token" ||
                      itemCategory === "IGO+NFT" || itemCategory === "Airdrop") && (
                        <Col md={6}>
                          <div className="form-group item_categy_select">
                            <label className="fs-14px text-white opacity-70 d-flex">
                              Item Decimal <span className="ml-2 info_icon ">
                                <p data-tip="Please select Decimal places for Offered ERC20." >
                                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABf0lEQVRIidWVTU4CQRCFqyGwEW9gPAC60gOIC13qYIxXIBDjzyXIxDPoHeQaRheiC0VdafQAbAQjnwuqpSPd8yMrKul0Zt7rejVvqrtF5j1MEgiURSTSsSYiSwq9iciNiHRE5NIYM8ytDOwBL6THM1DPk7gAnDkJboEjoAos6FgBjoGuw4uBQhYBm/wTaCQt0mKaygWIs9hik2/k+OqaIxKFSGXH80aAA0AAayn8BJR8hAPHc68tKQJF4E4p+/a9m8h2wrkxZuRLYjQC2LeIXOjjtE3Ao6pXfQmyhHYXwIMP7CtYSUgQtEjxRaX07bv0vs0X1r5fi12Bd52XZxCwaz98Atc6b80gsK3z1RTitGl3hja9/9umLqHM+OACaP5D4FDhnnejKanuHBU1L8m/bhMYACNgJ40cOyItoJjALWrlA13TzlJNwRFBt/+JbqKKjlXg1PF8BLRD/y4kFDE+uNKil2RL2pVZEpFdGZ8t6zK5Ml9lcmV2jDFfmSufu/gBIo4rqpw825AAAAAASUVORK5CYII="></img>
                                </p><ReactTooltip effect="solid"
                                  aria-haspopup="true"
                                  className="custom-tooltip" offset={{ 'left': -130, 'top': -50 }} /></span>
                            </label>
                            <Field name="item_decimal">

                              {({ field }) => (
                                <select
                                  {...field}
                                  className="form-control PR_input   re_input"
                                >
                                  <option value="" disabled hidden>
                                    Select one
                                  </option>
                                  <option
                                    key="18"
                                    value="18"
                                  >
                                    18
                                  </option>
                                  <option key="9" value="9">
                                    9
                                  </option>
                                  <option key="6" value="6">
                                    6
                                  </option>

                                </select>
                              )}

                            </Field>
                            {touched.item_decimal && errors.item_decimal ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">
                                  {errors.item_decimal}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      )}


                    {(itemCategory === "Premium NFT" ||
                      itemCategory === "IGO+NFT") && (
                        <Col md={6}>
                          <div className="form-group">
                            <label className="fs-14px text-white opacity-70">
                              Erc721 Address
                            </label>
                            <Field name="erc721_address">
                              {({ field }) => (
                                <input
                                  {...field}
                                  disabled={!isApproveEnable}
                                  type="text"
                                  placeholder="Erc721 Address"
                                  className={
                                    "form-control PR_input " +
                                    (errors.erc721_address &&
                                      touched.erc721_address
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              )}
                            </Field>
                            {touched.erc721_address && errors.erc721_address ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block text-danger">
                                  {errors.erc721_address}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      )}
                    {(itemCategory === "Premium NFT" ||
                      itemCategory === "IGO+NFT") && (
                        <FieldArray name="NFT_idARY">
                          {/* {console.log('-----------------------values.NFT_idARY-------',values.NFT_idARY)} */}
                          {() =>
                            values.NFT_idARY.map((address, i) => {
                              const rowsErrors =
                                (errors.NFT_idARY?.length &&
                                  errors.NFT_idARY[i]) ||
                                "";
                              const rowsTouched =
                                (touched.NFT_idARY?.length &&
                                  touched.NFT_idARY[i]) ||
                                "";
                              return (

                                <Col md={6} key={i}>
                                  <div className="form-group" >
                                    <label className="fs-14px text-white opacity-70">
                                      NFT id {i + 1}
                                    </label>
                                    <Field name={`NFT_idARY.${i}`} >
                                      {({ field }) => (
                                        <input
                                          {...field}
                                          disabled={!isApproveEnable}
                                          min={0}
                                          type="number"
                                          placeholder="NFT id"
                                          className={
                                            "form-control PR_input " +
                                            (rowsErrors &&
                                              rowsTouched
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                      )}
                                    </Field>
                                    {rowsErrors && rowsTouched ? (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block text-danger">
                                          {rowsErrors}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </Col>
                              );
                            })
                          }
                        </FieldArray>
                      )}

                    <Col md={12}>
                      {itemCategory !== "Airdrop" &&
                        <Button
                          className="btn-blue btn w-150px mr-2 "
                          disabled={!isApproveEnable}
                          type="submit"
                        >
                          <span>Approve</span>
                        </Button>
                      }

                      {(itemCategory === "IGO+NFT") ? <Button
                        className="btn-blue btn w-150px mr-2"
                        disabled={!isApproveNFTEnable}
                        type="submit"
                      >
                        <span>Approve NFT</span>
                      </Button>
                        : ''}

                      {itemCategory !== "Airdrop" &&
                        <Button
                          type="submit"
                          variant="blue"
                          className="px-4"
                          disabled={isApproveEnable}
                        >
                          {loading ? "Adding..." : "Add Item"}
                        </Button>
                      }
                      {itemCategory === "Airdrop" &&
                        <Button
                          type="submit"
                          variant="blue"
                          className="px-4"
                        >
                          {loading ? "Adding..." : "Add Item"}
                        </Button>
                      }
                    </Col>
                  </div>
                </Form>
              </>
            </Modal.Body>
          </Modal>
        )
        }
      </Formik >
    </>
  );
};
export default AddMysteryBoxItem;
