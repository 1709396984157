import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import Create from "../pages/Create/Create";
import CreateMystery from "../pages/Create/CreateMysteryBox"
import Detail from "../pages/Detail/Detail";
import TokenSales from "../pages/TokenSales/TokenSales";
import ScrollToTop from "./ScrollToTop";
import { Web3Context } from "../web3/contexts/web3Context";
import Loader from "../pages/loader/loader";
import Tranctions from "../pages/Transactions/Transactions";
import UserTranctions from "../pages/UserTransactions/UserTransactions";
import Banner from "../pages/Banners/Banners";
import MysteryBoxListing from "../pages/MysteryBoxListing/MysteryBoxListing";
import MysteryBoxes from "../pages/MysteryBoxes/MysteryBoxes";
import MysteryBoxesDetails from "../pages/MysteryBoxes/MysteryBoxesDetails";
import LandingPage from "../pages/LandingPage/LandingPage";

const AppRoutes = () => {
  const { loading } = useContext(Web3Context);

  return (
    <Router>
      {loading ? (
        <Loader />
      ) : (<>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <main className="hideLandingPage">
            <Sidebar />
            <div className="PR_contentMain">
              <Header />
              <ScrollToTop>
                {/* <Switch> */}
                <Route exact path="/home" component={TokenSales} />
                <Route exact path="/create" component={Create} />
                <Route exact path="/detail/:campaignId" component={Detail} />
                <Route exact path="/campaigns-history" component={Tranctions} />
                <Route exact path="/banners" component={Banner} />
                <Route exact path="/create-mystery" component={CreateMystery} />
                <Route exact path="/mysteryBox" component={MysteryBoxListing} />
                <Route
                  exact
                  path="/my-campaigns-history"
                  component={UserTranctions}
                />
                <Route exact path="/mystery-boxes/:mysteryId" component={MysteryBoxes} />
                <Route exact path="/mystery-boxes-details/:mysteryId/:boxId" component={MysteryBoxesDetails} />
                {/* <Route exact path="/token-sales" component={TokenSales} /> */}
                {/* </Switch> */}
              </ScrollToTop>
            </div>
          </main>
        </Switch>
      </>)
      }
    </Router >
  );
};

export default AppRoutes;
