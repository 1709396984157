import { enviornment } from "../constants/constants";
import axios from "axios";
import Web3 from "web3";



function tokenCampaign(Instance, token_address, userAccount) {
  return new Promise(async (resolve, reject) => {
    try {
      await Instance.methods
        .tokenCampaign(token_address)
        .call({ from: userAccount }, (err, data) => {
          if (err) {
            reject({ error: "Error in fetching token_address" });
          }
          resolve(data);
        });
    } catch (error) {
      reject({ error: error });
    }
  });
}

function approveCampaign(Instance, userAccount, APIobj, setLoading) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .adminApproval()
      .send({ from: userAccount })
      .on("transactionHash", async function (transactionHash) {
        APIobj.approve_status = 0;
        APIobj.approve_transaction_hash = transactionHash;
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await axios.post(
          enviornment.URL + "/api/approveCampain",
          APIobj,
          { headers }
        );
        if (response) {
          resolve(response);
        } else {
          reject({ error: "Their is some error with server" });
        }
      })
      .catch((error) => {
        console.log('-----2----er')

        setLoading(false);
        reject(error);
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}



function whiteListUser(Instance, userAccount, address, ticket) {
  return new Promise(async (resolve, reject) => {
    try {
      await Instance.methods
        .adminWhitelist(address, ticket)
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        })
    } catch (error) {
      console.log('-------------whiteListUser-2--',)
      reject(error);
    }
  });
}
function batchAdminWhitelisted(Instance, userAccount, address, ticket) {
  return new Promise(async (resolve, reject) => {
    try {
      await Instance.methods
        .batchAdminWhitelisted(address, ticket)
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        })
    } catch (error) {
      console.log('-------------whiteListUser-2--', error)
      reject(error);
    }
  });
}

function buyTokens(Instance, userAccount, amt) {
  return new Promise(async (resolve, reject) => {
    // try {
    //  amt = Web3.utils.toWei(`${amt}`);

    await Instance.methods
      .buyTokens(amt)
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--', error)
    //   reject(error);
    // }
  });
}
function withdrawFunds(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {

    await Instance.methods
      .withdrawFunds()
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--',)
    //   reject(error);
    // }
  });
}
// id , approve_status , approve_transaction_hash

// -----------------------------------new Functions---------------------


function withDrawRemainingAssetsFailed(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {

    await Instance.methods
      .withDrawRemainingAssetsFailed()
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--',)
    //   reject(error);
    // }
  });
}

//   // -----------------------------------MANUAL---------------------

function adminAddLiquidityManual(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .adminAddLiquidityManual()
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   console.log('---------error----------', error)
    //   reject(error);
    // }
  });
}

function adminReleseToken(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .adminReleseToken()
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   reject(error);
    // }
  });
}

function manualWithDrawRemainingAssets(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {

    await Instance.methods
      .manualWithDrawRemainingAssets()
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--',)
    //   reject(error);
    // }
  });
}

function manualWithdrawTokens(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {

    await Instance.methods
      .manualWithdrawTokens()
      .send({ from: userAccount })
      .then((ress) => {
        if (ress) {
          resolve(ress);
        }
      }).catch((error) => {
        reject(error);
      })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--',)
    //   reject(error);
    // }
  });
}

//   //   // -----------------------------------AUTOMATIC---------------------
function bakedVAULT(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
      await Instance.methods
        .bakedVAULT()
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        }).catch((error) => {
          reject(error);
        })
    // } catch (error) {
    //   reject(error);
    // }
  });
}


function unlock(Instance, userAccount, adr, amt) {
  return new Promise(async (resolve, reject) => {
    // try {
      
      // amt = amt * 1e18;
        console.log('---------amt----------', amt)
      await Instance.methods
        .unlock(adr, amt)
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        }).catch((error) => {
          reject(error);
        })
    // } catch (error) {
    //   console.log('---------error----------', error)
    //   reject(error);
    // }
  });
}

function automaticWithDrawRemainingAssets(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {

      await Instance.methods
        .automaticWithDrawRemainingAssets()
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        }).catch((error) => {
          reject(error);
        })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--',)
    //   reject(error);
    // }
  });
}

function automaticWithdrawTokens(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {

      await Instance.methods
        .automaticWithdrawTokens()
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        }).catch((error) => {
          reject(error);
        })
    // } catch (error) {
    //   console.log('-------------whiteListUser-2--',)
    //   reject(error);
    // }
  });
}



function withdrawFee(Instance, userAccount, adr) {
  return new Promise(async (resolve, reject) => {
    // try {
      await Instance.methods
        .withdrawFee(adr)
        .send({ from: userAccount })
        .then((ress) => {
          if (ress) {
            resolve(ress);
          }
        }).catch((error) => {
          reject(error);
        })
    // } catch (error) {
    //   console.log('---------error----------', error)
    //   reject(error);
    // }
  });
}


export const campignMethods = {
  withdrawFunds,

  buyTokens,
  tokenCampaign,
  approveCampaign,
  whiteListUser,

  batchAdminWhitelisted,



  // -----new Functions
  withDrawRemainingAssetsFailed, // 1

  //------ MANUAL
  adminAddLiquidityManual,// 1
  adminReleseToken,// 1
  manualWithDrawRemainingAssets,// 1
  manualWithdrawTokens,// 1

  // ------AUTOMATIC
  bakedVAULT,// 1
  unlock,// 1
  automaticWithDrawRemainingAssets,// 1
  automaticWithdrawTokens,// 1
  withdrawFee,


};
