import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import Loader from "../loader/loader";
import Web3 from "web3";
import { enviornment } from "../../constants/constants";
import { Web3Context } from "../../web3/contexts/web3Context";
import { createMethods } from "../../functions/create";
import { campignMethods } from "../../functions/campaign";
import { toast } from "react-toastify";
import moment from "moment";
import { useParams } from "react-router-dom";
import Countdown from "react-countdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import img1 from "./../../assets/images/logo1.png";

import img3 from "./../../assets/images/usdt.png";

import harmony from "./../../assets/images/harmony.png";
import polygon from "./../../assets/images/polygon.png";
import binance from "./../../assets/images/binance.png";

import img4 from "./../../assets/images/external-link.png";
import img6 from "./../../assets/images/twitter.png";

import DetailTimer from "../../Components/Detailtimer";
import RSlider from "../../Components/RangeSlider/Slider";
import Icon from "../../Components/Icon";


const domain = [0, 100];
// const defaultValues = [100];
// NOTE =  (tokenAmount + max USDT )/playshae percentage
const Detail = () => {
  const { networkDetails } = useContext(Web3Context);
  const { campaignId } = useParams();

  const [campaignsData, setCampaignsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isApproveEnable, setIsApproveEnable] = useState(true);
  const [buyModal, setBuyModal] = useState(false);
  // const [lockModal, setLockModal] = useState(false);
  const [unlockModal, setUnLockModal] = useState(false);
  const [feeModal, setFeeModal] = useState(false);
  const [bal, setBal] = useState(0);

  const [vaultdata, setVaultData] = useState({
    collected: 0,
    checkWhitelisted: false,
    admiWhitelisted: false,
    failed: false,
    isLive: false,
    locked: false,
    success: false,
    percentage: 0,
    scBalance: 0,
    getGivenAmount: 0,
    adminApproveStatus: false,
    owner: "",
    isCampOwner: false,
    calTicket: 0,
    tokeReleseStatus: 0,
    withdrawRemainingAssetsStatus: 0,
    lastVestingTime: 0,
    unlock_date: 0,
    withdrawFeeLocked: 0,
  });

  const initialValues = {
    amt: "",
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const validationSchema = Yup.object().shape({
    amt: Yup.number()
      .required("amount is required")
      .min(1, "amount less then 0"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        setLoading(true);
        let obj = await getSCBalanceDetails(campaignsData.token_address);
        let balanceDetail = {};

        if (obj && obj.USDTInstance) {
          balanceDetail.balanceToken = await createMethods.getBalanceUSDC(
            obj.USDTInstance,
            networkDetails.address
          );
          balanceDetail.allowancToken = await createMethods.allowanceUSDC(
            obj.USDTInstance,
            networkDetails.address,
            obj.campaignAddress
          );
          if (
            balanceDetail.allowancToken >= values.amt &&
            balanceDetail.balanceToken >= values.amt &&
            isApproveEnable
          ) {
            setLoading(false);
            return setIsApproveEnable(false);
          }

          if (balanceDetail && balanceDetail.balanceToken > 0) {
            if (
              balanceDetail.allowancToken >= values.amt &&
              balanceDetail.balanceToken >= values.amt &&
              !isApproveEnable
            ) {

              if (
                parseInt(values.amt) >= parseInt(campaignsData.min_allowed) &&
                parseInt(values.amt) <= parseInt(campaignsData.max_allowed)
              ) {

                let tamt = parseInt(values.amt) + parseInt(vaultdata.getGivenAmount);
                if (campaignsData.status == "upcoming") {

                  if (
                    tamt <= (parseInt(campaignsData.max_allowed) * parseInt(vaultdata.calTicket))
                  ) {
                    await campignMethods
                      .buyTokens(
                        obj.campaignInstance,
                        networkDetails.address,
                        values.amt
                      )
                      .then((resApprove) => {
                        toast.info("Transaction success.");
                        setIsApproveEnable(true);
                        setLoading(false);
                        resetForm();
                        setBuyModal(false);
                        window.location.reload();
                      })
                      .catch((er) => {
                        if (er && er.code) {
                          toast.error(er.message);
                          setLoading(false);
                        }
                      });
                  } else {
                    setLoading(false);
                    toast.error(
                      "You reached your Maximum allowance."
                    );
                  }
                }
                if (campaignsData.status == "ongoing") {
                  if (vaultdata.checkWhitelisted || vaultdata.admiWhitelisted) {
                    if (
                      tamt <= (parseInt(campaignsData.max_allowed) * parseInt(vaultdata.calTicket))
                    ) {
                      await campignMethods
                        .buyTokens(
                          obj.campaignInstance,
                          networkDetails.address,
                          values.amt
                        )
                        .then((resApprove) => {
                          toast.info("Transaction success.");
                          setIsApproveEnable(true);
                          setLoading(false);
                          resetForm();
                          setBuyModal(false);
                          window.location.reload();
                        })
                        .catch((er) => {
                          if (er && er.code) {
                            toast.error(er.message);
                            setLoading(false);
                          }
                        });
                    } else {
                      setLoading(false);
                      toast.error(
                        "You reached your Maximum allowance."
                      );
                    }

                  } else {
                 
                    if (
                      parseInt(tamt) >= parseInt(campaignsData.min_allowed) &&
                      parseInt(tamt) <= parseInt(campaignsData.max_allowed)
                    ) {
                      await campignMethods
                        .buyTokens(
                          obj.campaignInstance,
                          networkDetails.address,
                          values.amt
                        )
                        .then((resApprove) => {
                          toast.info("Transaction success.");
                          setIsApproveEnable(true);
                          setLoading(false);
                          resetForm();
                          setBuyModal(false);
                          window.location.reload();
                        })
                        .catch((er) => {
                          if (er && er.code) {
                            toast.error(er.message);
                            setLoading(false);
                          }
                        });

                    } else {
                      setLoading(false);
                      toast.error(
                        "You reached your Maximum allowance."
                      );
                    }


                  }
                }

              } else {
                toast.error(
                  "Please enter amount between minimum and maximum range."
                );
                setLoading(false);
              }

            } else {
              if (balanceDetail.balanceToken > values.amt && isApproveEnable) {
                await createMethods
                  .approveUSDC(
                    obj.USDTInstance,
                    obj.campaignAddress,
                    balanceDetail.balanceToken,
                    networkDetails.address
                  )
                  .then(async (data) => {
                    if (data) {
                      setIsApproveEnable(false);
                      setLoading(false);
                      // await props.handleFormSubmit2(values);
                      // toast.success("Tokens approved successfully.");
                      // await props.next();
                    }
                  })
                  .catch((er) => {
                    setLoading(false);
                    toast.error(er.message);
                  });
              } else {
              
                setLoading(false);
                toast.error(
                  "You don't have enough presale tokens to join campaign"
                );
              }
            }
          } else {
            toast.error("USDC balance is zero");
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });

  // const initialValues1 = {
  //   percentage: "",
  //   duration: ""
  // };
  // const validationSchema1 = Yup.object().shape({
  //   percentage: Yup.number()
  //     .min(1, "percentage less then 1")
  //     .required("percentage is required")
  //     .max(100, "amount less then 100")
  //   ,
  //   duration: Yup.number()
  //     .min(60, "duration greater then 60")
  //     .required("duration is required")
  // });
  // const formik1 = useFormik({
  //   initialValues: initialValues1,
  //   enableReinitialize: true,
  //   validationSchema: validationSchema1,
  //   onSubmit: async (values, { resetForm, setErrors }) => {
  //     setLoading(true);
  //     let obj = await getSCBalanceDetails(campaignsData.token_address);
  //     if (obj && obj.campaignInstance) {
  //       await campignMethods
  //         .adminAddLiquidityManual(obj.campaignInstance, networkDetails.address)
  //         .then((resApprove) => {
  //           toast.info("Transaction success.");
  //           setLoading(false);
  //           onCloseLockModal()
  //           window.location.reload();
  //         })
  //         .catch((er) => {
  //           if (er && er.code) {
  //             toast.error(er.message);
  //             setLoading(false);
  //           }
  //         });

  //     }
  //   },
  // });

  const initialValues2 = {
    address: "",
  };
  const validationSchema2 = Yup.object().shape({
    address: Yup.string()
      .required("amount is required")
      .matches(/^0x[a-fA-F0-9]{40}$/, "please enter valid token address")
  });
  const formik2 = useFormik({
    initialValues: initialValues2,
    enableReinitialize: true,
    validationSchema: validationSchema2,
    onSubmit: async (values, { resetForm, setErrors }) => {
      setLoading(true);
      let obj = await getSCBalanceDetails(campaignsData.token_address);
      if (obj && obj.campaignInstance) {


        let lpInstance = await createMethods.getInstance(
          networkDetails.web3,
          values.address,
          enviornment[networkDetails.networkId].LPABI
        );


        let tempBAL = await lpInstance.methods.balanceOf(obj.campaignAddress).call({ from: networkDetails.address }).catch((er) => {
          if (er) {
          
            toast.error('Please enter Valid LP Address.', 'Error')
            setLoading(false);
            return;
          }
        });
        await campignMethods
          .unlock(
            obj.campaignInstance,
            networkDetails.address,
            values.address,
            tempBAL
          )
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            onCloseUnLockModal();
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    },
  });

  const initialValues3 = {
    address: "",
  };
  const validationSchema3 = Yup.object().shape({
    address: Yup.string()
      .required("amount is required")
      .matches(/^0x[a-fA-F0-9]{40}$/, "please enter valid token address"),
  });
  const formik3 = useFormik({
    initialValues: initialValues3,
    enableReinitialize: true,
    validationSchema: validationSchema3,
    onSubmit: async (values, { resetForm, setErrors }) => {
      setLoading(true);
      let obj = await getSCBalanceDetails(campaignsData.token_address);
      if (obj && obj.campaignInstance) {
        await campignMethods
          .withdrawFee(
            obj.campaignInstance,
            networkDetails.address,
            values.address
          )
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            // onCloseLockModal();
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    },
  });

  useEffect(() => {

    setLoading(true);
    if (networkDetails && networkDetails.connected && campaignId) {


    } else {

      setLoading(false);

      if (networkDetails && networkDetails.isUpdated) {
        toast.error('Please Connect with Metamask first.')
      }
    }
    getCamps(campaignId);
  }, [networkDetails]);

  const getCamps = async (campaignId) => {
    try {
      if (networkDetails && networkDetails.connected && campaignId) {
        let balance = await createMethods.getBalAPI(networkDetails.address);
        if (balance.data.success) {
          setBal(balance.data.data);
        }
      }

      let response = await createMethods.getCampignsById(campaignId);
      if (response.data.success) {
        setLoading(false);
        setCampaignsData(response.data.data);
        setLoading(true);

        let tkData = response.data.data;
        let obj = await getSCBalanceDetails(tkData["token_address"]);
        let mainObj = {
          collected: 0,
          checkWhitelisted: false,
          adminWhitelisted: false,
          failed: false,
          isLive: false,
          locked: false,
          success: false,
          doRefund: false,
          percentage: 0,
          scBalance: 0,
          adminApproveStatus: false,
          getGivenAmount: 0,
          owner: "",
          isCampOwner: false,
          calTicket: 0,
          tokeReleseStatus: 0,
          withdrawRemainingAssetsStatus: 0,
          lastVestingTime: 0,
          unlock_date: 0,
          withdrawFeeLocked: 0
        };

        if (obj && obj.campaignInstance) {
          mainObj.collected = await obj.campaignInstance.methods
            .collected()
            .call({ from: networkDetails.address });
          // mainObj.collected = Web3.utils.fromWei(amt);
         
          mainObj.percentage =
            (parseInt(mainObj.collected) / parseInt(tkData.hardCap)) * 100;
          mainObj.checkWhitelisted = await obj.campaignInstance.methods
            .checkWhitelisted(networkDetails.address)
            .call({ from: networkDetails.address });
       

          mainObj.adminWhitelisted = await obj.campaignInstance.methods
            .adminWhitelisted(networkDetails.address)
            .call({ from: networkDetails.address });
        

          mainObj.isLive = await obj.campaignInstance.methods
            .isLive()
            .call({ from: networkDetails.address });
          mainObj.failed = await obj.campaignInstance.methods
            .failed()
            .call({ from: networkDetails.address });
         

          mainObj.locked = await obj.campaignInstance.methods
            .locked()
            .call({ from: networkDetails.address });
        

          mainObj.success = await obj.campaignInstance.methods
            .success()
            .call({ from: networkDetails.address });
          mainObj.doRefund = await obj.campaignInstance.methods
            .doRefund()
            .call({ from: networkDetails.address });
         

          await obj.campaignInstance.methods
            .owner()
            .call({ from: networkDetails.address }).then((res) => {
              mainObj.owner = res
            })
          mainObj.adminApproveStatus = await obj.campaignInstance.methods
            .adminApproveStatus()
            .call({ from: networkDetails.address });
   
          mainObj.withdrawRemainingAssetsStatus = await obj.campaignInstance.methods
            .campaignOwnerWiyhdrawStatus()
            .call({ from: networkDetails.address });

          let gamt = await obj.campaignInstance.methods
            .getGivenAmount(networkDetails.address)
            .call({ from: networkDetails.address });
          mainObj.getGivenAmount = Web3.utils.fromWei(gamt);
       

          // mainObj.tokeReleseStatus = await obj.campaignInstance.methods
          //   .tokeReleseStatusManual()
          //   .call({ from: networkDetails.address });

          if (tkData.lock_Liquidity === "automatic") {
          

            mainObj.tokeReleseStatus = await obj.campaignInstance.methods
              .tokeReleseStatusSC()
              .call({ from: networkDetails.address });
          }
          if (tkData.lock_Liquidity === "manual") {
          

            mainObj.tokeReleseStatus = await obj.campaignInstance.methods
              .tokeReleseStatusManual()
              .call({ from: networkDetails.address });
          }
          // mainObj.tokeReleseStatus = await obj.campaignInstance.methods
          // .tokeReleseStatusManual()
          // .call({ from: networkDetails.address });


          mainObj.unlock_date = await obj.campaignInstance.methods
            .unlock_date()
            .call({ from: networkDetails.address });
          mainObj.withdrawFeeLocked = await obj.campaignInstance.methods
            .withdrawFeeLocked()
            .call({ from: networkDetails.address });


          await obj.campaignInstance.methods
            .ticket(networkDetails.address)
            .call({ from: networkDetails.address })
            .then((res) => {
              mainObj.calTicket = res;
            })
            .catch((er) => {
              mainObj.calTicket = 1;
            });

          

          let tamt = await obj.ercInstance.methods
            .balanceOf(obj.campaignAddress)
            .call({ from: networkDetails.address });
          mainObj.scBalance = Web3.utils.fromWei(tamt);

          mainObj.lastVestingTime = await obj.campaignInstance.methods
            .lastVestingTime(networkDetails.address)
            .call({ from: networkDetails.address });
          // 
          



          if (
            mainObj.owner.toLowerCase() == networkDetails.address.toLowerCase()
          ) {
            mainObj.isCampOwner = true;
          }

          await setVaultData(mainObj);
          setLoading(false);
        }
      } else {
        setLoading(false);
        return toast.warning("Error while Fetching.", "Attention");
      }


    } catch (er) {
      setLoading(false);
      return toast.warning(er);
    }
  };

  const getSCBalanceDetails = async (token_address) => {
    let obj = {
      campaignAddress: "",
      campaignInstance: "",
      factoryInstance: "",
      ercInstance: "",
      bakedInstance: "",
      USDTInstance: "",
    };

    let factoryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].FACTORYContract,
      enviornment[networkDetails.networkId].FACTORYABI
    );

    if (factoryInstance && factoryInstance !== undefined) {
      obj.factoryInstance = factoryInstance;

      obj.campaignAddress = await campignMethods.tokenCampaign(
        factoryInstance,
        token_address,
        networkDetails.address
      );

      let campaignInstance = await createMethods.getInstance(
        networkDetails.web3,
        obj.campaignAddress,
        enviornment[networkDetails.networkId].compaignABI
      );
      obj.campaignInstance = await campaignInstance;

      let ercInstance = await createMethods.getInstance(
        networkDetails.web3,
        token_address,
        enviornment[networkDetails.networkId].commanERCABI
      );
      obj.ercInstance = await ercInstance;
      let bakedInstance = await createMethods.getInstance(
        networkDetails.web3,
        enviornment[networkDetails.networkId].BAKEDToken,
        enviornment[networkDetails.networkId].commanERCABI
      );
      obj.bakedInstance = await bakedInstance;

      let USDTInstance = await createMethods.getInstance(
        networkDetails.web3,
        enviornment[networkDetails.networkId].USDTAddress,
        enviornment[networkDetails.networkId].commanERCABI
      );
      obj.USDTInstance = await USDTInstance;

      return obj;
    } else {
      return obj;
    }
  };

  const onClickBuy = async () => {
    // getSCBalanceDetails
    if (networkDetails.connected) {
      // let obj = await getSCBalanceDetails(campaignsData.token_address);
     

      // if (obj && obj.bakedInstance) {
      let amt = bal;
      // await obj.bakedInstance.methods
      //   .balanceOf(networkDetails.address)
      //   .call({ from: networkDetails.address });
      // amt = Web3.utils.fromWei(amt);

      if (campaignsData.status === "upcoming") {
        let currentDate = new Date().getTime();

        currentDate = parseInt(currentDate) / 1000;
        currentDate = parseInt(currentDate);
        let start_date = parseInt(campaignsData.start_date);
        let diff = currentDate - start_date;

        if (diff <= 43200) {
          if (vaultdata.checkWhitelisted) {
            setBuyModal(true);
          } else if (vaultdata.admiWhitelisted) {
            setBuyModal(true);
          } else {
            toast.warning(
              "You are not a whitelisted User.You dont have access Please wait untill start.",
              "Attetion"
            );
          }
        } else {
          toast.warning("Camp is not started yet.", "Attetion");
        }
      }

      if (campaignsData.status === "ongoing") {
        if (amt && amt >= 5000) {
          setBuyModal(true);
        } else {
          // setLoading(false);
          return toast.warning(
            "You should have 5000 BAKED token.",
            "Attention"
          );
        }
      }
      // }
    } else {
      setLoading(false);
      return toast.warning("You have to connect first !", "Attention");
    }
  };
  const onClickWithdrawRemainingAssets = async () => {
    let obj = await getSCBalanceDetails(campaignsData.token_address);

    if (obj && obj.campaignInstance) {
      setLoading(true);

      if (vaultdata.success) {

        if (
          campaignsData && campaignsData.lock_Liquidity && campaignsData.lock_Liquidity === "manual"
        ) {
          await campignMethods
            .manualWithDrawRemainingAssets(obj.campaignInstance, networkDetails.address)
            .then((resApprove) => {
              toast.info("Transaction success.");
              setLoading(false);
              window.location.reload();
            })
            .catch((er) => {
              if (er && er.code) {
                toast.error(er.message);
                setLoading(false);
              }
            });
        } else if (campaignsData.lock_Liquidity === "automatic") {

          await campignMethods
            .automaticWithDrawRemainingAssets(obj.campaignInstance, networkDetails.address)
            .then((resApprove) => {
              toast.info("Transaction success.");
              setLoading(false);
              window.location.reload();
            })
            .catch((er) => {
              if (er && er.code) {
                toast.error(er.message);
                setLoading(false);
              }
            });
        }


      }
      else if (vaultdata.failed) {

        await campignMethods
          .withDrawRemainingAssetsFailed(obj.campaignInstance, networkDetails.address)
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }

    }
  };
  const onClickWithdrawFund = async () => {
    let obj = await getSCBalanceDetails(campaignsData.token_address);

    if (obj && obj.campaignInstance) {
      setLoading(true);

      await campignMethods
        .withdrawFunds(obj.campaignInstance, networkDetails.address)
        .then((resApprove) => {
          toast.info("Transaction success.");
          setLoading(false);
          window.location.reload();
        })
        .catch((er) => {
          if (er && er.code) {
            toast.error(er.message);
            setLoading(false);
          }
        });
    }
  };

  const ConvertSectoDay = async (n) => {
    let day = n / (24 * 3600);

    n = n % (24 * 3600);
    let hour = n / 3600;

    n %= 3600;
    let minutes = n / 60;

    n %= 60;
    let seconds = n;

    let a = parseInt(day) + ' ' + 'days ' + parseInt(hour) + ' hours ' + parseInt(minutes) + ' minutes ' + parseInt(seconds) + ' seconds ';
    return a;
  }



  const onClickWithdrawTokens = async () => {
    let obj = await getSCBalanceDetails(campaignsData.token_address);

    if (parseInt(vaultdata.lastVestingTime) && parseInt(vaultdata.lastVestingTime) != 0) {
      let currentTime = new Date().getTime() / 1000;
     
      if (parseInt(currentTime) > (parseInt(vaultdata.lastVestingTime) + parseInt(campaignsData.lock_Duration))) {
       
      } else {
     
        let dif1 = (parseInt(vaultdata.lastVestingTime) + parseInt(campaignsData.lock_Duration)) - parseInt(currentTime);

        let a = await ConvertSectoDay(dif1);
     
        toast.warning('You can withdraw tokens after   ' + a)
        return
      }
    }




    if (obj && obj.campaignInstance) {

      setLoading(true);
      if (
        campaignsData && campaignsData.lock_Liquidity && campaignsData.lock_Liquidity === "manual"
      ) {



        await campignMethods
          .manualWithdrawTokens(obj.campaignInstance, networkDetails.address)
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      } else if (campaignsData.lock_Liquidity === "automatic") {


        await campignMethods
          .automaticWithdrawTokens(obj.campaignInstance, networkDetails.address)
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }

    }
  };

  const onClickLockLiquidity = async () => {
    if (
      campaignsData &&
      campaignsData.lock_Liquidity &&
      campaignsData.lock_Liquidity === "manual"
    ) {
      setLoading(true);
      let obj = await getSCBalanceDetails(campaignsData.token_address);
      if (obj && obj.campaignInstance) {
        await campignMethods
          .adminAddLiquidityManual(obj.campaignInstance, networkDetails.address)
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            // onCloseLockModal();
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    } else {
      let obj = await getSCBalanceDetails(campaignsData.token_address);
      setLoading(true);
      if (obj && obj.campaignInstance) {
        await campignMethods
          .bakedVAULT(obj.campaignInstance, networkDetails.address)
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    }
    //
  };

  const onClickUnLock = async () => {
    if (
      campaignsData &&
      campaignsData.lock_Liquidity &&
      campaignsData.lock_Liquidity === "manual"
    ) {
      let obj = await getSCBalanceDetails(campaignsData.token_address);

      if (obj && obj.campaignInstance) {
        setLoading(true);
        await campignMethods
          .adminReleseToken(
            obj.campaignInstance,
            networkDetails.address
          )
          .then((resApprove) => {
            toast.info(
              "Transaction success."
            );
            setLoading(false);
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    } else {

      if (parseInt(vaultdata.unlock_date) && parseInt(vaultdata.unlock_date) != 0) {
        let currentTime = new Date().getTime() / 1000;
      
        if (parseInt(currentTime) > (parseInt(vaultdata.unlock_date))) {
      
          setUnLockModal(true);
        } else {
          //   console.log('----------------2')
          let dif1 = (parseInt(vaultdata.unlock_date)) - parseInt(currentTime);
       

          let a = await ConvertSectoDay(dif1);
          
          toast.warning('You can Release tokens after   ' + a)
          return
        }
      }


    }
  };

  const onClickWithdrawFee = async () => {
    setFeeModal(true);
  };

  // const onCloseLockModal = () => {
  // setLockModal(false);
  // };

  const onCloseUnLockModal = () => {
    setUnLockModal(false);
  };
  const onCloseFeeModal = () => {
    setFeeModal(false);
  };

  const onCloseBuyModal = () => {
    setBuyModal(false);
  };
  return (
    <>
      {loading && <Loader />}
      <div className="container detail_page">
        {/* <p className="close_app">
          Applications close on 27 Nov 2021, 1:00 PM UTC{" "}
          <button className="btn-blue">Whitelist Open</button>
          <img src={img8} alt="alter image1"></img>
        </p> */}

        <div className="subhead">
          <div className="subhead_logo ">
            {/* {campaignsData && campaignsData.tokenLogo ? campaignsData.tokenLogo :img1} */}
            <img alt="alter image2"
              style={{ maxWidth: "72px", maxHeight: "72px" }}
              src={
                campaignsData && campaignsData.tokenLogo
                  ? campaignsData.tokenLogo
                  : img1
              }
            ></img>
          </div>
          <div className="subhead_content">
            <h1>{campaignsData && campaignsData.projectName}</h1>
            {/* <p>
              Uniquely monetize cross functional initiatives for orthogonal
              scenarios.
            </p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8">
            <div className="main_banner mb-4">
              <img alt="alter image3"
                src={
                  campaignsData &&
                    campaignsData.campaignBanner &&
                    campaignsData.campaignBanner !== undefined
                    ? campaignsData.campaignBanner
                    : ''
                }
              />
            </div>
            <div className="token_wrap">
              <div className="token">
                <img src={img3} alt="alter image4"></img>
                <span>USDC</span>
              </div>
              {/* <div className="token">
                <img src={img3} alt="alter image6"></img>
                <span>No rank required at Min Rank</span>
              </div> */}
              <div className="token">


                {campaignsData && campaignsData.network_type == 'binance'
                  ? (<img src={binance} height={'24px'} width={'24px'} alt="alter image5"></img>)
                  : ""}

                {campaignsData && campaignsData.network_type == 'polygon'
                  ? (<img src={polygon} height={'24px'} width={'24px'} alt="alter image5"></img>)
                  : ""}

                {campaignsData && campaignsData.network_type == 'harmony'
                  ? (<img src={harmony} height={'24px'} width={'24px'} alt="alter image5"></img>)
                  : ""}

                {campaignsData && campaignsData.network_type == 'binance'
                  ? (<span>Binance smartchain</span>)
                  : ""}

                {campaignsData && campaignsData.network_type == 'polygon'
                  ? (<span>Polygon (MATIC) Network</span>)
                  : ""}

                {campaignsData && campaignsData.network_type == 'harmony'
                  ? (<span>Harmony Network</span>)
                  : ""}
              </div>
              {/* <div className="token">
                <img src={img3} alt="alter image7"></img>
                <span>Launching in 4 DAYS</span>
              </div> */}
            </div>
            <hr />
            <h2>Pool Details</h2>
            <ul className="pool_details">
              <li>
                <span>Token Start Time (GMT+05:30)</span>
                {/* <p>4:45 pm, 28 Oct 2021 </p> */}
                <p>
                  {" "}
                  {campaignsData && campaignsData.start_date
                    ? moment(campaignsData.start_date * 1000).format(
                      "LT, DD MMM y"
                    )
                    : "-"}
                </p>
              </li>
              <li>
                <span>Token Name / Symbol</span>
                <p>
                  {campaignsData && campaignsData.tokenName
                    ? campaignsData.tokenName
                    : ""}
                </p>
              </li>
              <li>
                <span>Total Raise(For campaign)</span>
                <p>
                  {campaignsData && campaignsData.hardCap
                    ? parseInt(campaignsData.hardCap) *
                    parseInt(Web3.utils.fromWei(campaignsData.tokenPerUSDT))
                    : "-"}{" "}
                </p>
              </li>
            </ul>



            <ul className="pool_details">
              <li>
                <span>Token End Time (GMT+05:30)</span>
                <p>
                  {" "}
                  {campaignsData && campaignsData.end_date
                    ? moment(campaignsData.end_date * 1000).format(
                      "LT, DD MMM y"
                    )
                    : "-"}
                </p>
              </li>
              {/* <li>
                <span>Schedule by Ranks</span>
                <p>
                  Click to see details{" "}
                  <a href="">
                    {" "}
                    <img src={img4} alt="alter image8"></img>
                  </a>
                </p>
              </li>
              <li>
                <span>Type</span>
                <p>Claimble</p>
              </li> */}

              <li>
                <span>Maximum Allowed</span>
                <p>
                  {campaignsData && campaignsData.max_allowed
                    ? campaignsData.max_allowed
                    : "-"}
                </p>
              </li>
              <li>
                <span>Minimum Allowed</span>
                <p>
                  {campaignsData && campaignsData.min_allowed
                    ? campaignsData.min_allowed
                    : "-"}
                </p>
              </li>
            </ul>

            <ul className="pool_details">
              <li>
                <span>Initial supply</span>
                <p>
                  {" "}
                  {campaignsData && campaignsData.initial_supply
                    ? campaignsData.initial_supply
                    : "-"}
                </p>
              </li>

              <li>
                <span>Initial Market capitalization</span>
                <p>
                  {campaignsData && campaignsData.market_cap
                    ? campaignsData.market_cap
                    : "-"}
                </p>
              </li>
              <li>
                <span>Total raise </span>
                <p>
                  {campaignsData && campaignsData.total_raise
                    ? campaignsData.total_raise
                    : "-"}
                </p>
              </li>
            </ul>


            <ul className="pool_details">
              <li>
                <span>Lock Liquidity</span>
                <p>
                  {" "}
                  {campaignsData && campaignsData.lock_Liquidity
                    ? campaignsData.lock_Liquidity
                    : "-"}{" "}
                </p>
              </li>
              {/* <li>
                <span>Pool Rate</span>
                <p>   {campaignsData && campaignsData.pool_rate
                    ? campaignsData.pool_rate
                    : "-"} </p>
              </li> */}
              <li>
                <span>Soft Cap</span>
                <p>
                  {campaignsData && campaignsData.softCap
                    ? campaignsData.softCap
                    : "-"}
                </p>
              </li>
              <li>
                <span>Hard Cap</span>
                <p>
                  {campaignsData && campaignsData.hardCap
                    ? campaignsData.hardCap
                    : "-"}
                </p>
              </li>
             
            </ul>
            <ul className="pool_details">
              <li>
                <span>Lock durations</span>
                <p>
                  {campaignsData && campaignsData.lock_Duration
                    ? parseInt(parseInt(campaignsData.lock_Duration) / 60)
                    : ""}{" "}
                  Minutes
                  {/*  <a href="">
                    {" "}
                    <img src={img4} alt="alter image9"></img>
                  </a> */}
                </p>
              </li>
              <li>
              <span>Vesting Percentange</span>
                <p>
                  {campaignsData && campaignsData.vesting_Percentage
                    ? campaignsData.vesting_Percentage
                    : "0"} %
                </p>
               
              </li>
              <li>
                <span>Social</span>
                <div className="social_wrap">

                  {campaignsData && campaignsData.twitterLink != '' ? (<a href={campaignsData.twitterLink}>
                    <img src={img6} alt="alter image11" />
                  </a>) : ''}

                  {campaignsData && campaignsData.discordLink != '' ? (<a href={campaignsData.discordLink}>
                    <Icon name="discord" className="w-16px h-16px" />
                  </a>) : ''}

                  {campaignsData && campaignsData.telegramLink != '' ? (<a
                    href={ campaignsData.telegramLink}
                    className="text-white"
                  >
                    <Icon name="telegram" />
                  </a>) : ''}




                </div>
              </li>
            </ul>
            <ul className="pool_details">
            <li>
                <span>Website</span>
                <p>
                  {campaignsData && campaignsData.website}{" "}
                  <a
                    href={campaignsData && campaignsData.website}
                    target="_blank"
                  >
                    {" "}
                    <img src={img4} alt="alter image10"></img>
                  </a>
                </p>
              </li>
            </ul>
            <hr />
            <h2>Project Information</h2>
            <p className="mb-5">{campaignsData && campaignsData.projectInfo}</p>
            <div className="main_banner mb-5">
              <img alt="alter image12"
                src={
                  campaignsData &&
                    campaignsData.banners &&
                    campaignsData.banners.length
                    ? campaignsData.banners[0].image
                    : ''
                }
              />
            </div>
            {/* <ul className="custom_listing mb-5">
              <li>
                <span> Dynamically: </span> Collaboratively unleash extensive
                core competencies through global platforms.Appropriately supply
                long-term high-impact total linkage after high-quality
                networks.Interactively maximize emerging vortals via market
                positioning alignments.Dynamically engineer adaptive e-commerce
                via team driven communities.Quickly exploit intermandated
                innovation whereas high-payoff nickhes corporate.
              </li>
              <li>
                <span>Bandwidth: </span> Completely reconceptualize
                enterprise-wide information with progressive
                deliverables.Conveniently impact mission-critical web services
                rather than strategic bandwidth.Progressively evisculate global
                "outside the box" thinking with innovative e-markets.Credibly
                embrace resource-leveling technologies and backend
                relationships.Objectively disseminate interoperable bandwidth
                with pandemic methods of empowerment.
              </li>
              <li>
                <span>Dramatically: </span> Rapidiously synergize functional
                convergence are after provided by Phosfluorescently drive
                customer directed testing procedures via corporate
                infrastructures.Dramatically fabricate low-risk high-yield
                imperatives via competitive applications.
              </li>
            </ul> */}
          </div>
          <div className="col-xl-4 join_competition">
            <div className="PR_saleItemBox">
              <div className="sale_row">
                <p>Swap Amount</p>
                <div className="sale_amt">
                  {campaignsData && campaignsData.tokenAmount
                    ? parseInt(Web3.utils.fromWei(campaignsData.tokenAmount))
                    : "-"}
                  <span>
                    {campaignsData && campaignsData.tokenName
                      ? campaignsData.tokenName
                      : ""}
                  </span>
                </div>
                <span>
                  1{" "}USDC

                  ={" "}
                  {campaignsData && campaignsData.tokenPerUSDT
                    ? parseInt(Web3.utils.fromWei(campaignsData.tokenPerUSDT))
                    : "-"}{" "}
                  {campaignsData && campaignsData.tokenName
                    ? campaignsData.tokenName
                    : ""}{" "}
                </span>
              </div>
              <div className="progress_wrap">
                <p className="">Swap Progress</p>

                <div className="progress_value">
                  <div id="rangeValue">
                    {vaultdata.percentage ? vaultdata.percentage : 0}%
                  </div>
                  <div className="amnt">
                    {" "}
                    {vaultdata && vaultdata.collected !== undefined
                      ? vaultdata.collected
                      : 0}{" "}
                    /{" "}
                    {campaignsData && campaignsData.hardCap
                      ? campaignsData.hardCap
                      : 0}
                  </div>
                </div>
                {/* <input
                  type="range"
                  id="myRange"
                  value={`${vaultdata.percentage ? vaultdata.percentage : 0}`}
                  max="100"
                  className={`form-control`}
                /> */}
                <div className="re_slider1">
                  <RSlider
                    defaultValues={[
                      vaultdata.percentage ? vaultdata.percentage : 0,
                    ]}
                    domain={domain}
                    mode={2}
                    step={1}
                    left={true}
                  />
                </div>
              </div>
              {/* upcoming / ongoing / completed / success /failed  */}
              <div className="sale_timer_wrap">
                <p>
                  {campaignsData && !vaultdata.success && campaignsData.status === "upcoming"
                    ? "End to apply for the Whitelist in" : ''
                    // : campaignsData && !vaultdata.success && campaignsData.status === "ongoing"
                    //   ? "End to Participate in"
                    //   : "Ended"
                  }
                  {campaignsData && !vaultdata.success && campaignsData.status === "ongoing"
                    ? "End to Participate in" : ''
                    // : campaignsData && !vaultdata.success && campaignsData.status === "ongoing"
                    //   ? "End to Participate in"
                    //   : "Ended"
                  }
                  {campaignsData && vaultdata.success
                    ? "Success" : ''
                    // : campaignsData && !vaultdata.success && campaignsData.status === "ongoing"
                    //   ? "End to Participate in"
                    //   : "Ended"
                  }
                  {campaignsData && vaultdata.failed
                    ? "Failed" : ''
                    // : campaignsData && !vaultdata.success && campaignsData.status === "ongoing"
                    //   ? "End to Participate in"
                    //   : "Ended"
                  }
                </p>

                {campaignsData && campaignsData.status === "upcoming" ? (
                  <Countdown
                    date={parseInt(campaignsData.start_date + "000")}
                    vaultdata={vaultdata}
                    renderer={DetailTimer}
                    campaignsData={campaignsData}
                  />
                ) : campaignsData && campaignsData.status === "ongoing" ? (
                  <Countdown
                    date={parseInt(campaignsData.end_date + "000")}
                    status={campaignsData.status}
                    renderer={DetailTimer}
                    vaultdata={vaultdata}
                    campaignsData={campaignsData}
                  />
                ) : (
                  <>{/* {new Date.now()} */}</>
                )}
              </div>

              <div className="pool_timline_wrap">
                <p>Pool Timeline</p>
                <div className="d-flex justify-content-between align-items-start PR_StepgrpMain">
                  <div className="PR_progressbar">
                    <div className="PR_progress" style={{ width: "25%" }} />
                  </div>
                  <div className={`PR_Stepgrp active`}>
                    <div className="PR_StepNumber">1</div>
                    <div className="fs-xxl-11px fs-10px text-white">
                      Upcoming
                    </div>
                  </div>
                  <div
                    className={`PR_Stepgrp ${campaignsData && campaignsData.status !== "upcoming"
                      ? "active"
                      : ""
                      }`}
                  >
                    <div className="PR_StepNumber">2</div>
                    <div className="fs-xxl-11px fs-10px text-white">
                      Ongoing
                    </div>
                  </div>
                  <div
                    className={`PR_Stepgrp ${vaultdata && vaultdata.success ? "active" : ""
                      }`}
                  >
                    <div className="PR_StepNumber">3</div>
                    <div className="fs-xxl-11px fs-10px text-white">
                      Success
                    </div>
                  </div>
                  <div
                    className={`PR_Stepgrp ${vaultdata && vaultdata.failed ? "active" : ""
                      }`}
                  >
                    <div className="PR_StepNumber">3</div>
                    <div className="fs-xxl-11px fs-10px text-white">Failed</div>
                  </div>
                  <div
                    className={`PR_Stepgrp ${vaultdata &&
                      vaultdata.tokeReleseStatus == 1
                      ? "active"
                      : ""
                      }`}
                  >
                    <div className="PR_StepNumber">4</div>
                    <div className="fs-xxl-11px fs-10px text-white">
                      Claimable
                    </div>
                  </div>
                  <div
                    className={`PR_Stepgrp ${vaultdata && vaultdata.scBalance == 0 ? "active" : ""
                      }`}
                  >
                    <div className="PR_StepNumber">5</div>
                    <div className="fs-xxl-11px fs-10px text-white">End</div>
                  </div>
                </div>
                {/* <div className="pool_timline_steps">
                  <div
                    className="nav justify-content-between"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="button_wrap">
                      <button
                        className="nav-link active circlebutton"
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-selected="true"
                      >
                        <span>1</span>
                      </button>
                      <p className="step_ttl">Upcoming</p>
                    </div>
                    <div className="button_wrap">
                      <button
                        className={
                          campaignsData && campaignsData.status !== "upgoing"
                            ? "nav-link active circlebutton"
                            : "nav-link circlebutton"
                        }
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-selected="true"
                      >
                        <span>2</span>
                      </button>
                      <p className="step_ttl">Ongoing</p>
                    </div>
                    <div className="button_wrap">
                      <button
                        className={
                          vaultdata && vaultdata.success
                            ? "nav-link active circlebutton"
                            : "nav-link circlebutton"
                        }
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-selected="true"
                      >
                        <span>3</span>
                      </button>
                      <p className="step_ttl">Success</p>
                    </div>
                    <div className="button_wrap">
                      <button
                        className={
                          vaultdata &&
                          ((vaultdata.success && vaultdata.locked > 0) ||
                            (vaultdata.failed && vaultdata.locked == 0))
                            ? "nav-link active circlebutton"
                            : "nav-link circlebutton"
                        }
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-selected="true"
                      >
                        <span>4</span>
                      </button>
                      <p className="step_ttl">Claimable</p>
                    </div>
                    <div className="button_wrap">
                      <button
                        className={
                          vaultdata && vaultdata.scBalance == 0
                            ? "nav-link active circlebutton"
                            : "nav-link circlebutton"
                        }
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-selected="true"
                      >
                        <span>5</span>
                      </button>
                      <p className="step_ttl">End</p>
                    </div>
                  </div>
                </div> */}
              </div>


              <div className="text-center">
                {!vaultdata.success &&
                  !vaultdata.failed &&
                  vaultdata.isLive &&
                  vaultdata.adminApproveStatus ? (
                  <button
                    className="btn-blue w-100 mb-2"
                    type="button"
                    onClick={() => {
                      onClickBuy();
                    }}
                  >
                    JOIN COMPETITION{" "}
                  </button>
                ) : (
                  ""
                )}

                {/* BUYTOKENS  AMOUNT */}
                {/* NOTE approve USDT */}
                {/*
                  - CAMP IS APPROVE / NOT  &&  
                  - CAMP IS LIVE / NOT  &&  
                  - MINIMUM BACKED AMT  5000 &&  
               
                  - AMT SHOULD BE between min& max &&  
-  white listed & ticket & all.
- if campign is success / failed  not need to show 
                  */}

                {vaultdata.failed && vaultdata.getGivenAmount > 0 ? (
                  <button
                    className="btn-blue w-100 mb-2"
                    type="button"
                    onClick={() => {
                      onClickWithdrawFund();
                    }}
                  >
                    WITHDRAW FUND{" "}
                  </button>
                ) : (
                  ""
                )}

                {/* withdraw funds */}
                {/*
                 ( vaultdata.failed && vaultdata.locked == 0)  && getGivenAmount > 0  
                  */}
                {(vaultdata.success || vaultdata.failed) &&
                  // vaultdata.scBalance !== 0 && 
                  vaultdata.tokeReleseStatus == 1 &&
                  vaultdata.withdrawRemainingAssetsStatus != 1 &&
                  vaultdata.isCampOwner ? (
                  <button
                    className="btn-blue w-100 mb-2"
                    type="button"
                    onClick={() => {
                      onClickWithdrawRemainingAssets();
                    }}
                  >
                    WITHDRAW REMAINING ASSETS{" "}
                  </button>
                ) : (
                  ""
                )}


                {(vaultdata.failed) &&
                  // vaultdata.scBalance !== 0 && 
                  vaultdata.withdrawRemainingAssetsStatus != 1 &&
                  vaultdata.isCampOwner ? (
                  <button
                    className="btn-blue w-100 mb-2"
                    type="button"
                    onClick={() => {
                      onClickWithdrawRemainingAssets();
                    }}
                  >
                    WITHDRAW REMAINING ASSETS{" "}
                  </button>
                ) : (
                  ""
                )}
                {/* Lock Liquidity */}
                {vaultdata.success &&
                  vaultdata.isCampOwner &&
                  vaultdata.locked == 0 && (
                    <button
                      className="btn-blue w-100 mb-2"
                      type="button"
                      onClick={() => {
                        onClickLockLiquidity();
                      }}
                    >
                      Lock Liquidity
                    </button>
                  )}
                {/* withdrawremainingassets */}
                {/*
                 ( (vaultdata.success|| vaultdata.failed) && campignOwner  )
                  */}

                {vaultdata.success &&
                  vaultdata.locked == 1 &&
                  vaultdata.getGivenAmount > 0 && vaultdata.tokeReleseStatus == 1 ? (
                  <button
                    className="btn-blue w-100 mb-2"
                    type="button"
                    onClick={() => {
                      onClickWithdrawTokens();
                    }}
                  >
                    WITHDRAW TOKENS{" "}
                  </button>
                ) : (
                  ""
                )}

                {/* {vaultdata.success && vaultdata.locked == 0 &&  getGivenAmount > 0  } */}

                {/* unlock ---------- */}
                {vaultdata.success &&
                  vaultdata.locked != 0 &&
                  vaultdata.isCampOwner &&
                  vaultdata.tokeReleseStatus != 1 && (
                    <button
                      className="btn-blue w-100 mb-2"
                      type="button"
                      onClick={() => {
                        onClickUnLock();
                      }}
                    >
                      Release{" "}
                    </button>
                  )}

                {/* withdraw fee */}

                {vaultdata.success && vaultdata.locked == 1 && vaultdata.isCampOwner && vaultdata.tokeReleseStatus == 1 && vaultdata.withdrawFeeLocked != 1 && (
                  <button
                    className="btn-blue w-100 mb-2"
                    type="button"
                    onClick={() => {
                      onClickWithdrawFee();
                    }}
                  >
                    Withdraw fee{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal Buy */}
      <Modal show={buyModal} onHide={onCloseBuyModal} size="" centered className="PR_modal"
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Buy Tokens</div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik.handleSubmit}>
              {/* <Col md={12}>
                <div className="h4 pb-3 ">Whitelist Address</div>
              </Col> */}
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="amt"
                    type="number"
                    placeholder="token amount"
                    className={`form-control PR_input ${getInputClasses(
                      "amt"
                    )}`}
                    {...formik.getFieldProps("amt")}
                  />
                  {formik.touched.amt && formik.errors.amt ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.amt}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={!isApproveEnable || loading}
                // disabled={loading}
                >
                  {loading ? "Approve..." : "Approve"}
                </Button>{" "}
                &nbsp; &nbsp;
                <Button
                  type="submit"
                  variant="blue"
                  disabled={isApproveEnable || loading}
                  className="px-4"
                // disabled={loading}
                >
                  {loading ? "buying..." : "Buy"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>

      {/* modal L:ock */}
      {/* <Modal show={lockModal} onHide={onCloseLockModal} centered className="PR_modal">
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Lock Liquidity</div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik1.handleSubmit}>

              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="percentage"
                    type="number"
                    placeholder="percentage"
                    className={`form-control PR_input ${getInputClasses(
                      "percentage"
                    )}`}
                    {...formik1.getFieldProps("percentage")}
                  />
                  {formik1.touched.percentage && formik1.errors.percentage ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.percentage}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="duration"
                    type="number"
                    placeholder="duration"
                    className={`form-control PR_input ${getInputClasses(
                      "duration"
                    )}`}
                    {...formik1.getFieldProps("duration")}
                  />
                  {formik1.touched.duration && formik1.errors.duration ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.duration}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={loading}
                >
                  {loading ? "locking..." : "Lock"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal> */}

      {/* modal Unlock */}
      <Modal
        show={unlockModal}
        onHide={onCloseUnLockModal}
        centered
        className="PR_modal"
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Unlock Liquidity</div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik2.handleSubmit}>
              <Col md={12} lg={12}>
                <div className="form-group" data-toggle="tooltip" data-placement="top" title="Please enter LP token address to unlock liquidity"
                  value="44">
                  <input
                    autoComplete="false"
                    name="address"
                    type="text"
                    tooltip="Please enter LP token address to unlock liquidity"
                    placeholder="address"
                    className={`form-control PR_input ${getInputClasses(
                      "address"
                    )}`}
                    {...formik2.getFieldProps("address")}
                  />
                  {formik2.touched.address && formik2.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik2.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={loading}
                >
                  {loading ? "unlocking..." : "Unlock"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>

      {/* modal Fee */}

      <Modal
        show={feeModal}
        onHide={onCloseFeeModal}
        centered
        className="PR_modal"
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Withdraw Fee</div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik3.handleSubmit}>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="address"
                    type="text"
                    placeholder="address"
                    className={`form-control PR_input ${getInputClasses(
                      "address"
                    )}`}
                    {...formik3.getFieldProps("address")}
                  />
                  {formik3.touched.address && formik3.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik3.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={loading}
                >
                  {loading ? "withdrawing..." : "Withdraw Fee"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Detail;
