import { enviornment } from "../constants/constants";
import axios from "axios";
import Web3 from "web3";
import { toast } from "react-toastify";

function getInstance(web3, address, abi) {
  return new Promise(async (resolve, reject) => {
    try {
      if (web3) {
        const Instance = await new web3.eth.Contract(abi, address);
        if (Instance) {
          resolve(Instance);
        } else {
          reject({ error: "Issue with instance" });
        }
      } else {
        reject({ error: "Error with initialisation" });
      }
    } catch (error) {
      console.log(error, "erorr000000000000000");
      reject(error);
    }
  });
}

function getBalance(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .balanceOf(userAccount)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching balance" });
          toast.error("Error in fetching balance");
        }
        if (data) {
          const result = Web3.utils.fromWei(`${data}`);
          resolve(result);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}



function getBalanceWithDec(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .balanceOf(userAccount)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching balance" });
          toast.error("Error in fetching balance");
        }
        if (data) {
          // const result = Web3.utils.fromWei(`${data}`);
          resolve(data);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}


function getBalanceUSDC(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .balanceOf(userAccount)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching balance" });
          toast.error("Error in fetching balance");
        }
        if (data) {
          let result = data / 1e6;
          result = result.toLocaleString("fullwide", { useGrouping: false });
         
          //  Web3.utils.fromWei(`${data}`);
          resolve(result);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}

function percentage(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    try {
      await Instance.methods
        .playshare_percent()
        .call({ from: userAccount }, (err, data) => {
          if (err) {
            reject({ error: "Error in fetching percentage" });
          }
          resolve(data);
        });
    } catch (error) {
      reject({ error: error });
    }
  });
}
function allowance(contractInstance, walletAddress, FACTORYContract) {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await contractInstance.methods
        .allowance(walletAddress, FACTORYContract)
        .call({
          from: walletAddress,
        });
      if (result) {
        result = Web3.utils.fromWei(`${result}`);
        resolve(result);
      } else {
        reject({ error: "their is some issue in allowance" });
      }
    } catch (err) {
      reject(err);
    }
  });
}

function allowanceWithDec(contractInstance, walletAddress, FACTORYContract) {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await contractInstance.methods
        .allowance(walletAddress, FACTORYContract)
        .call({
          from: walletAddress,
        });
      if (result) {
     
        resolve(result);
      } else {
        reject({ error: "their is some issue in allowance" });
      }
    } catch (err) {
      reject(err);
    }
  });
}

function allowanceUSDC(contractInstance, walletAddress, FACTORYContract) {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await contractInstance.methods
        .allowance(walletAddress, FACTORYContract)
        .call({
          from: walletAddress,
        });
      if (result) {
        let data = result / 1e6;
        data = data.toLocaleString("fullwide", { useGrouping: false });

        resolve(data);
      } else {
        reject({ error: "their is some issue in allowance" });
      }
    } catch (err) {
      reject(err);
    }
  });
}
function approve(contractInstance, FACTORYContract, balance, userAccount) {
  const balance1 = Web3.utils.toWei(`${balance}`);
  return new Promise((resolve, reject) => {
    contractInstance.methods
      .approve(FACTORYContract, balance1)
      .send({ from: userAccount })
      .then((data) => {
        if (data) {
          resolve(data);
        }
      })
      .catch((er) => {
        if (er) {
          reject(er);
        }
      });
  });
}

function approveWithDec(contractInstance, FACTORYContract, balance, userAccount) {

  return new Promise((resolve, reject) => {
    contractInstance.methods
      .approve(FACTORYContract, balance)
      .send({ from: userAccount })
      .then((data) => {
        if (data) {
          resolve(data);
        }
      })
      .catch((er) => {
        if (er) {
          reject(er);
        }
      });
  });
}

function approveUSDC(contractInstance, FACTORYContract, balance, userAccount) {
  let balance1 = balance * 1e6;
  balance1 = balance1.toLocaleString("fullwide", { useGrouping: false });

  return new Promise((resolve, reject) => {
    contractInstance.methods
      .approve(FACTORYContract, balance1)
      .send({ from: userAccount })
      .then((data) => {
        if (data) {
          resolve(data);
        }
      })
      .catch((er) => {
        if (er) {
          reject(er);
        }
      });
  });
}

// function approve(contractInstance, networkId, balance, userAccount) {
//   const balance1 = Web3.utils.toWei(`${balance}`);
//   return new Promise((resolve, reject) => {
//     contractInstance.methods
//       .approve(enviornment[networkId].FACTORYContract, balance1)
//       .send({ from: userAccount })
//       .then((data) => {
//         if (data) {
//           resolve(data);
//         }
//       })
//       .catch((er) => {
//         if (er) {
//           reject(er);
//         }
//       });
//   });
// }
function uploadData(type, d) {
  return new Promise(async (resolve, reject) => {
    try {
      const fd = new FormData();

      fd.append("updDocs", d);
      fd.append("updType", type);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const response = await axios.post(
        enviornment.URL + "/api/uploadData",
        fd,
        { headers }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      reject(err);
    }
  });
}

function createCampaign(
  factoryInstance,
  userAccount,
  ary,
  token_address,
  transferAddress,
  vesting_Percentage,
  lock_Duration,
  APIobj
) {
  return new Promise(async (resolve, reject) => {
    
    factoryInstance.methods
      .createCampaign(
        ary,
        token_address,
        transferAddress,
        vesting_Percentage,
        lock_Duration
      )
      .send({ from: userAccount })
      .on("transactionHash", async function (transactionHash) {
        APIobj.create_transaction_hash = transactionHash;
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await axios.post(
          enviornment.URL + "/api/create-Campain",
          APIobj,
          { headers }
        );
        if (response) {
          resolve(response);
        } else {
          reject({ error: "Their is some error with server" });
        }
      })
      .on("error", function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        // toast.error("Transaction was rejected by the network");
        // setLoading(false);
      });
    // } catch (err) {
    //   reject(err);
    // }
  });
}

function getCampignsDashboard() {
  return new Promise(async (resolve, reject) => {
    try {
     ;
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(enviornment.URL + "/api/getCampains", {
        headers,
      });
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getAdminsDashboard() {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + "/api/getAdminCampains",
        { headers }
      );
      if (response) {
        resolve(response);
      } else {
        
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getUserDashboard(userADR) {
  return new Promise(async (resolve, reject) => {
    try {
  
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + "/api/getUserCampains/" + userADR,
        { headers }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function setBannerImage(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(
        enviornment.URL + "/api/create-banner",
        data,
        { headers }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getCampignsById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + `/api/campaign/${id}`,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}
function getBanners() {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(enviornment.URL + `/api/getBanners`, {
        headers,
      });
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function editBanners(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(
        enviornment.URL + `/api/update-banner`,
        data,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}
function getBalAPI(adr) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + `/api/get-balance/` + adr,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}
function getTicket(adr) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + `/api/get-tickets/` + adr,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}
function deleteCamp(obj) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(
        enviornment.URL + `/api/update-campaign`,
        obj,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getTotalTicket(adr) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(
        enviornment.URL + `/api/get-totaltickets`,
        adr,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

export const createMethods = {
  getInstance,
  allowance,
  getBalance,
  percentage,
  approve,
  createCampaign,
  uploadData,
  getCampignsDashboard,
  getAdminsDashboard,
  getUserDashboard,
  setBannerImage,
  getCampignsById,
  getBanners,
  editBanners,
  getBalAPI,
  getTicket,
  getTotalTicket,
  deleteCamp,
  getBalanceUSDC,
  allowanceUSDC,
  approveUSDC,
  allowanceWithDec,
  getBalanceWithDec,
  approveWithDec
};
