import React from "react";

const DetailTimer = ({ days, hours, minutes, seconds, completed ,props}) => {
    // console.log('----------detail comp',props.vaultdata)
    // Render a countdown
    return (
       
        <>
         {props.vaultdata.success?
          (<h1 className="text-white">Success</h1>)
          :( <div className="sale_timer">
                <div className="time">
                    {days}<span>DAY</span>
                </div>
                <div className="time">
                    {hours}<span>HOUR</span>
                </div>
                <div className="time">
                    {minutes}<span>MIN</span>
                </div>
                <div className="time">
                    {seconds}<span>SEC</span>
                </div>
            </div>)} 

           
        </>
    );
};

export default DetailTimer