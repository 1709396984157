import React, { useContext } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { Web3Context } from '../web3/contexts/web3Context'
import logo from '../assets/PlayShare.svg'

const Header = () => {
    const { networkDetails, handleConnect, resetApp } = useContext(Web3Context)
    const handleClassBody = () => {
        document.body.classList.toggle('ShowSidebar')

    }
    return (<>
        <Container fluid className="d-flex align-items-center justify-content-lg-center justify-content-between py-3">
            <Link to="/" className="d-inline-block d-lg-none mr-0"><img src={logo} alt="" width="120px" /></Link>
            <div className="d-flex align-items-center">
                {networkDetails.address === "" && <Button variant="blue" onClick={() => handleConnect()}><Icon name="wallet" className="mr-2" />Connect Wallet</Button>}
                {networkDetails.address !== "" && <Dropdown>
                    <Dropdown.Toggle variant="blue">
                        <Icon name="wallet" className="mr-2" />{networkDetails.address.slice(0, 3)}...{networkDetails.address.slice(-4)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => resetApp()}>Disconnect</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
                <Button variant="link" className="text-white ml-2 p-0 d-inline-block d-lg-none" onClick={handleClassBody}>
                    <Icon name="menu" className="w-40px h-30px" />
                </Button>
            </div>

        </Container>

    </>)
}
export default Header