import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col } from "react-bootstrap";
import { createMysteryMethods } from "../functions/createMystery";
import { Web3Context } from "../web3/contexts/web3Context";
import { toast } from "react-toastify";


const EditDescription = (props) => {
  const { dataToEdit, onCloseModal, showEditModal,loading, setLoading } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initialValues = {
    projectLongDesc: dataToEdit.projectLongDesc||"",
    projectShortDesc:dataToEdit.projectShortDesc||"",
  };

  const validationSchema = Yup.object().shape({
    projectLongDesc: Yup.string().trim().required("Project long description is required."),
    projectShortDesc: Yup.string().trim().required("Project short description is required"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      setLoading(true)
      let obj = {
        id: dataToEdit._id,
        projectLongDesc: values.projectLongDesc,
        projectShortDesc: values.projectShortDesc

      }
      createMysteryMethods.updateMysteryBox(obj).then((res)=>{
        toast.success("Updated successfully.")
        onCloseModal()
        resetForm()
      }).catch(err=>{
        toast.error("There is some error while updating, please try again after sometime.")
        onCloseModal()
        setLoading(false)
        resetForm()
      })
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  return (
    <>
      <Modal
        show={showEditModal}
        onHide={onCloseModal}
        className="PR_modal"
        centered
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">
            Edit mystery box description
          </div>
        </Modal.Header>
        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik.handleSubmit}>
              <Col md={12} lg={12}>
                <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                    Project Long Description
                  </label>
                  <textarea
                    name="projectLongDesc"
                    rows={4}
                    placeholder="Please enter long description"
                    className="form-control re_input PR_input"
                    {...formik.getFieldProps("projectLongDesc")}
                  ></textarea>

                  {formik.touched.projectLongDesc && formik.errors.projectLongDesc ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectLongDesc}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} lg={12}>
                <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                    Project Short Description
                  </label>
                  <textarea
                    name="projectShortDesc"
                    rows={4}
                    placeholder="Please enter short description"
                    className="form-control re_input PR_input"
                    {...formik.getFieldProps("projectShortDesc")}
                  ></textarea>

                  {formik.touched.projectShortDesc && formik.errors.projectShortDesc ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectShortDesc}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={loading}
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditDescription;
