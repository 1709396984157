import React, { useState, useContext, useEffect } from "react";
import { Form, Col, Row, Container, Modal, Button, } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Web3Context } from "../../web3/contexts/web3Context";
import { createMethods } from "../../functions/create";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";



import "react-datepicker/dist/react-datepicker.css";
import { enviornment } from "../../constants/constants";
// NOTE =  (tokenAmount + max TUSD )/playshae percentage
const Create = () => {
  const { networkDetails } = useContext(Web3Context);
  const [tokenUrl, setTokenUrl] = useState(null);
  const [campaignBanner, setCampaignBanner] = useState(null);
  const [tempCampaignBanner,setTempCampaignBanner] = useState(null)
  const [tempTokenUrl,setTempTokenUrl] = useState(null)
  const [loading, setLoading] = useState(false);
  const [isApproveEnable, setIsApproveEnable] = useState(true);
  const [lock_Liquidity, setlockLiquidity] = useState("");
  const [multipleTicketModal, setMultipleTicketModal] = useState(false);
  const [network, setNetwork] = useState('other');

  // const [ticketData, setTicketData] = useState({
  //   ticket: 0,
  //   users: [],
  // });

  // initial values for form
  const initialFormValues = {
    network_type: "",
    token_address: "",
    softCap: "",
    hardCap: "",
    // tokenPerUSDT: "",
    max_allowed: "",
    min_allowed: "",
    tokenAmount: "",
    start_date: "",
    end_date: "",
    projectName: "",
    tokenName: "",
    website: "",
    tokenLogo: "",
    campaignBanner: "",
    lock_Liquidity: "",
    telegramLink: "",
    twitterLink: "",
    discordLink: "",
    projectInfo: "",
    transferAddress: "",
    pool_rate: "",
    walletAddress: "",
    vesting_Percentage: "",
    lock_Duration: "",
    pancake_Duration: "",

    initial_supply: "",
    market_cap: "",
    total_raise: "",

  };

  //   validations for form
  const formSchema = Yup.object().shape({
    network_type: Yup.string()
      .nullable()
      .required("This field is required."),
    token_address: Yup.string()
      .nullable()
      .required("Token address is required.")
      .matches(/^0x[a-fA-F0-9]{40}$/, "Please enter valid token address."),
    softCap: Yup.number()
      .integer("Please enter without decimals.")
      .typeError("This field is required.")
      .nullable()
      .min(1, `Please enter a value greater than 0`)
      .required("Soft cap is required."),
    hardCap: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("This field is required.")
      .min(1, `Please enter value greater than 0`)
      .moreThan(Yup.ref("softCap"), "Value should be greater than soft Cap")
      .required("Hard cap is required."),
    // tokenPerUSDT: Yup.number()
    //   .min(0, `please enter value greater then 0`)
    //   .when(["tokenAmount", "hardCap"], (tokenAmount, hardCap, schema) => {
    //     return tokenAmount > 0 && hardCap > 0
    //       ? schema.max(
    //         tokenAmount / hardCap,
    //         `token per ETH/TUSD must be less than or equal to ${tokenAmount / hardCap
    //         }`
    //       )
    //       : schema.min(0);
    //   })
    //   .nullable()
    //   .required("tokenPerUSDT is required."),
    vesting_Percentage: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("vesting percentage is required.")
      .min(0, `please enter value greater than 0`)
      .max(100, `please enter value less than 100`)
      .required("Vesting percentage is required."),
    min_allowed: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("This field is required.")
      .min(1, `Please enter a value greater than 0`)
      .required("Min USDC is required."),

    initial_supply: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("This field is required.")
      .min(1, `Please enter a value greater than 0`)
      .required("Initial supply is required."),
    market_cap: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("This field is required.")
      .min(1, `Please enter a value greater than 0`)
      .required("Inital market capitalization is required."),
    total_raise: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("This field is required.")
      .min(1, `Please enter value greater than 0`)
      .required("Total supply is required."),

    max_allowed: Yup.number()
      .integer("Please Enter without decimals.")
      .typeError("This field is required.")
      .min(1, `Please enter value greater than 0`)
      .moreThan(Yup.ref("min_allowed"), "Value should be greater than the min allowed")
      .required("Max USDC is required."),
    tokenAmount: Yup.number()
      .typeError("This field is required.")
      // .min(min_allowed, `please enter value greater than ${min_allowed}`)
      // .max(max_allowed, `please enter value less than ${max_allowed}`)
      .moreThan(Yup.ref("hardCap"), "Value should be greater than the hard Cap")
      .required("Token amount is required."),
    start_date: Yup.string().required("Start date and time is required"),
    lock_Duration: Yup.number()
      .integer("Please Enter without decimals.")
      .required("Please enter lock duration")
      .min(60, "Please enter atleast 60 seconds"),
    projectInfo: Yup.string().required("Project description is required"),
    end_date: Yup.string().required("End date and time is required"),
    projectName: Yup.string().required("Project name is required"),
    tokenName: Yup.string().required("Token name is required"),
    tokenLogo: Yup.mixed().required("Please upload the token logo"),
    transferAddress:
      lock_Liquidity === "manual"
        ? Yup.string().trim().required("Transfer address is required")
        : Yup.string(),
    pool_rate:
      lock_Liquidity === "automatic"
        ? Yup.number().required("Pancake rate is required")
        : Yup.number(),
    pancake_Duration: lock_Liquidity === "automatic"
      ? Yup.number().required("Pancake duration is required")
      : Yup.number(),
    pancake_percentage:
      lock_Liquidity === "automatic"
        ? Yup.number().required("Pancake percentage is required")
        : Yup.number(),
    lock_Liquidity: Yup.string().required("Please select lock liquidity method"),
    campaignBanner: Yup.mixed().required("Please upload the campaign banner"),
    website: Yup.string()
      .trim()
      .url("Please enter valid link. example:'https://www.example.com/'")
      .required("Website is required"),
    twitterLink: Yup.string()
      .trim(),
    telegramLink: Yup.string()
      .trim(),
    discordLink: Yup.string()
      .trim()
    // .url("Please enter valid link. example:'https://www.example.com/'")
    // .required("discordLink is required"),
  });

  useEffect(()=>{
    return()=>{
      setTempCampaignBanner(null)
      setTempTokenUrl(null)
    }
  },[])

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      try {
        let PLAYPERCENTAGE = 0;

        if (networkDetails.connected) {
          if (new Date(values.end_date) > new Date(values.start_date)) {
            if (tokenUrl !== null && tokenUrl !== "" && campaignBanner !== null && campaignBanner !== "") {
              setLoading(true);
              let data = values;
              data.tokenLogo = tokenUrl;
              data.campaignBanner = campaignBanner;

              let balanceDetail = await getSCBalanceDetails(data.token_address);
              if (balanceDetail && balanceDetail.balanceToken > 0) {
                let TAMT = data.tokenAmount + data.max_allowed;
                PLAYPERCENTAGE = balanceDetail.percentage;
                TAMT =
                  TAMT + parseFloat(parseFloat(TAMT * PLAYPERCENTAGE) / 100);
                if (
                  balanceDetail.allowancToken >= TAMT &&
                  balanceDetail.balanceToken >= TAMT &&
                  isApproveEnable
                ) {
                  setLoading(false);
                  return setIsApproveEnable(false);
                }
                if (
                  balanceDetail.allowancToken >= TAMT &&
                  balanceDetail.balanceToken >= TAMT &&
                  !isApproveEnable
                ) {
                  let obj = {

                    initial_supply: data.initial_supply,
                    market_cap: data.market_cap,
                    total_raise: data.total_raise,
                    discordLink: data.discordLink && data.discordLink != undefined ? data.discordLink : '',
                    end_date: parseInt(data.end_date / 1000),
                    hardCap: data.hardCap,
                    lock_Duration: data.lock_Duration,
                    max_allowed: data.max_allowed,
                    min_allowed: data.min_allowed,
                    network_type: networkDetails.chainData.network_type,
                    projectInfo: data.projectInfo,
                    projectName: data.projectName,
                    softCap: data.softCap,
                    start_date: parseInt(data.start_date / 1000),
                    telegramLink: data.telegramLink,
                    tokenAmount: networkDetails.web3.utils.toWei(
                      `${data.tokenAmount}`
                    ),
                    totalTokenAmount: networkDetails.web3.utils.toWei(
                      `${TAMT}`
                    ),
                    tokenLogo: data.tokenLogo,
                    campaignBanner: data.campaignBanner,
                    tokenName: data.tokenName,
                    tokenPerUSDT: networkDetails.web3.utils.toWei(
                      `${parseFloat(data.tokenAmount / data.hardCap)}`
                    ),
                    token_address: data.token_address,
                    twitterLink: data.twitterLink,
                    vesting_Percentage: data.vesting_Percentage,
                    walletAddress: networkDetails.address,
                    website: data.website,
                    pool_rate:
                      data.lock_Liquidity === "automatic" ? data.pool_rate : 0,
                    lock_Liquidity: data.lock_Liquidity,
                    transferAddress:
                      data.lock_Liquidity === "manual"
                        ? data.transferAddress
                        : "0x0000000000000000000000000000000000000000",
                    pancake_percentage:
                      data.lock_Liquidity === "automatic"
                        ? networkDetails.web3.utils.toWei(
                          `${data.pancake_percentage}`
                        )
                        : 0,
                    pancake_Duration: data.lock_Liquidity === "automatic" ? data.pancake_Duration : 0,
                  };
                  let ary = [
                    obj.softCap,
                    obj.hardCap,
                    obj.start_date,
                    obj.end_date,
                    obj.tokenPerUSDT,
                    obj.min_allowed,
                    obj.max_allowed,
                    obj.tokenAmount,
                    obj.pool_rate,
                    obj.pancake_percentage,
                    obj.pancake_Duration
                  ];
                  // obj.token_address ,obj.tokenAmount ,obj.vesting_Percentage,obj.lock_Duration
                  await createMethods
                    .createCampaign(
                      balanceDetail.factoryInstance,
                      networkDetails.address,
                      ary,
                      obj.token_address,
                      obj.transferAddress,
                      obj.vesting_Percentage,
                      obj.lock_Duration,
                      obj
                    )
                    .then((resApprove) => {
                      setIsApproveEnable(true);
                      toast.info(
                        "Transaction confirmed."
                      );
                      setLoading(false);
                      resetForm();
                      setTempCampaignBanner(null)
                      setTempTokenUrl(null)
                      window.location.reload()
                      // history.push("/dashboard");
                    })
                    .catch((er) => {
                      if (er && er.code) {
                        toast.error(er.message);
                        setLoading(false);
                      }
                    });
                } else {
                  if (balanceDetail.balanceToken > TAMT && isApproveEnable) {
                    await createMethods
                      .approve(
                        balanceDetail.tokenInstance,
                        enviornment[networkDetails.networkId].FACTORYContract,
                        balanceDetail.balanceToken,
                        networkDetails.address
                      )
                      .then(async (data) => {
                        if (data) {
                          setIsApproveEnable(false);
                          setLoading(false);
                          // await props.handleFormSubmit2(values);
                          // toast.success("Tokens approved successfully.");
                          // await props.next();
                        }
                      })
                      .catch((er) => {
                        setLoading(false);
                        toast.error(er.message);
                      });
                  } else {
                   
                    setLoading(false);
                    toast.error(
                      "You don't have enough presale tokens to launch campaign"
                    );
                  }
                }
              } else {
                toast.error("balance is zero");
                setLoading(false);
                // balance is zero
              }
            } else {
              setFieldError("tokenLogo", "Please select a logo");
              setLoading(false);
            }
          } else {
            setFieldError(
              "end_date",
              "End date/time should be older than start date/time"
            );
            // setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.warning("You have to connect first !", "Attention");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });

  const onCloseMultipleTicket = () => {
    setMultipleTicketModal(false);
  };


  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const initialValues = {
    address: "",
  };


  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required("Address is required")
  });

  const formik1 = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {

        if (networkDetails && networkDetails.connected) {

          setLoading(true);
          values.address = values.address.split(',')

          let response = await createMethods.getTotalTicket(values);
          if (response.data.success) {
            setMultipleTicketModal(false)

            Swal.fire({
              title: "Tickets/Allocation = " + response.data.data,
              // text: ,
              icon: "success",
              button: "Ok",
            });
          }
        } else {
          return toast.warning("You have to connect first !", "Attention");
        }



        // await createMethods.getTotalTicketAPI(values.address);

        // if (ticket.data.success) {
        // setBal(ticket.data.data);

        // resetForm()
        // }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });


  useEffect(() => {
    if (networkDetails && networkDetails.connected) {
      
      // getTotalTicketAPI();
      let a = networkDetails.chainData && networkDetails.chainData != undefined ? networkDetails.chainData.network_type : 'other';

      formik.setFieldValue('network_type', a)
      // formik.setFieldValue('network_type', a);
    }
  }, [networkDetails])




  // const getTotalTicketAPI = async () => {
  //   if (networkDetails && networkDetails.connected) {
  //     let response = await createMethods.getTotalTicket(networkDetails.address);
  //     if (response.data.success) {
  //       setTicketData({
  //         ticket: response.data.data,
  //         users: []
  //       })
  //     }
  //   } else {
  //     return toast.warning("You have to connect first !", "Attention");
  //   }
  // };



  const LiquidityChange = (event) => {
    formik.handleChange(event);
    setlockLiquidity(event.target.value);
  };

  const onSelectDocument = async (event, formik) => {
    formik.setTouched({...formik.touched,"tokenLogo": true });
    if (networkDetails && networkDetails.connected) {
      if (event.target.files && event.target.files[0]) {
        if (event.target.files[0].name.match(/\.(jpeg|jpg|png|)$/)) {
          formik.setFieldValue("tokenLogo", event.target.files[0]);
          setTempTokenUrl(event.target.files[0])
          const formData = event.target.files[0];
          let uploadType = "image";
          setLoading(true);
          const response = await createMethods.uploadData(
            uploadType,
            formData,
            networkDetails.networkId
          );
          if (response) {
            setTokenUrl(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            console.log("error in uploading");
          }
        } else {
          formik.setFieldError("tokenLogo", "format not supported");
          setLoading(false);
          toast.error(
            "The file you are trying to upload is not in supported format, please try again"
          );
        }
      }
    } else {
      return toast.warning("You have to connect first !", "Attention");
    }
  };

  const onSelectCampaignBanner = async (event, formik) => {
    formik.setTouched({...formik.touched,"campaignBanner": true });
    if (networkDetails && networkDetails.connected) {
      if (event.target.files && event.target.files[0]) {
        if (event.target.files[0].name.match(/\.(jpeg|jpg|png|)$/)) {
          formik.setFieldValue("campaignBanner", event.target.files[0]);
          setTempCampaignBanner(event.target.files[0]);
          const formData = event.target.files[0];
          let uploadType = "image";
          setLoading(true);
          const response = await createMethods.uploadData(
            uploadType,
            formData,
            networkDetails.networkId
          );
          if (response) {
            setCampaignBanner(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            console.log("error in uploading");
          }
        } else {
          formik.setFieldError("campaignBanner", "format not supported");
          setLoading(false);
          toast.error(
            "The file you are trying to upload is not in supported format, please try again"
          );
        }
      }
    } else {
      return toast.warning("You have to connect first !", "Attention");
    }
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const getSCBalanceDetails = async (token_address) => {
    let obj = {
      balanceToken: 0,
      allowancToken: 0,
      percentage: 0,
      tokenInstance: "",
      factoryInstance: "",
    };
    let instance = await createMethods.getInstance(
      networkDetails.web3,
      token_address,
      enviornment[networkDetails.networkId].commanERCABI
    );
    let factoryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].FACTORYContract,
      enviornment[networkDetails.networkId].FACTORYABI
    );

    if (
      instance &&
      factoryInstance &&
      instance !== undefined &&
      factoryInstance !== undefined
    ) {
      obj.tokenInstance = instance;
      obj.factoryInstance = factoryInstance;
      obj.balanceToken = await createMethods.getBalance(
        instance,
        networkDetails.address
      );
      obj.allowancToken = await createMethods.allowance(
        instance,
        networkDetails.address,
        enviornment[networkDetails.networkId].FACTORYContract
      );
      obj.percentage = await createMethods.percentage(
        factoryInstance,
        networkDetails.address
      );
      return obj;
    }
  };
  const showToaster = (e) => {
    e.preventDefault();
    toast.warning("You have to connect first !", "Attention");
  };

  return (
    <>
      {loading && <Loader />}
      <Container className="mb-5">
        <Row className="justify-content-center">

          <Col md={12} lg={8}>
            <Form
              className="row"
              onSubmit={(e) =>
                networkDetails.connected
                  ? formik.handleSubmit(e)
                  : showToaster(e)
              }
            >
              <Col md={12}>
                <div className="fs-20px fw-600 border-bottom-yellow pb-2 text-yellow mb-4">
                  1 - Token Information
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Tickets Available
                  </label>
                  <input readOnly="true" style={{backgroundColor:'#1e2235'}}
                    type="number"
                    className={`form-control PR_input re_inputRouded`}
                    name="ticket"
                  value={ticketData.ticket}
                  />
               
                </div>
              </Col> */}
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Network Type
                  </label>
               
                  <select
                    disabled
                    style={{ background: "#1e2235" }}
                    name="network_type"
                    className="form-control PR_input re_input"
                    // defaultValue={network}  
                    {...formik.getFieldProps("network_type")}
                  // defaultValue={network} 
                  >
                    <option value="" >
                      Select one
                    </option>
                    <option key="binance" value="binance">
                      Binance Smart Chain
                    </option>
                    <option key="ethereum" value="ethereum">
                      Ethereum Network
                    </option>
                    <option key="harmony" value="harmony">
                      Harmony
                    </option>
                    <option key="polygon" value="polygon">
                      Polygon
                    </option>
                    <option key="other" value="other">
                      Please select valid network
                    </option>
                  </select>
                  {formik.touched.network_type &&
                    formik.errors.network_type ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block  text-danger">
                        {formik.errors.network_type}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group text-right">
                  {/* <label className="fs-14px text-white opacity-70">
                 
                  </label> */}
                  <button
                    className="btn-blue btn w-200px "
                    type="button"
                    onClick={() => {
                      setMultipleTicketModal(true);
                    }}
                  >
                    <span>   Check Tickets/Allocation</span>
                  </button>
                </div>
              </Col>

              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Token Address (presale token address link)
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "token_address"
                    )}`}
                    name="token_address"
                    {...formik.getFieldProps("token_address")}
                  />
                  {formik.touched.token_address &&
                    formik.errors.token_address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.token_address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Soft cap (USDC)
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "softCap"
                    )}`}
                    name="softCap"
                    {...formik.getFieldProps("softCap")}
                  />
                  {formik.touched.softCap && formik.errors.softCap ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.softCap}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Hard cap (USDC)
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "hardCap"
                    )}`}
                    name="hardCap"
                    {...formik.getFieldProps("hardCap")}
                  />
                  {formik.touched.hardCap && formik.errors.hardCap ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.hardCap}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Min investment per Wallet (USDC)
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "min_allowed"
                    )}`}
                    name="min_allowed"
                    {...formik.getFieldProps("min_allowed")}
                  />
                  {formik.touched.min_allowed && formik.errors.min_allowed ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.min_allowed}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Max investment per Wallet (USDC)
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "max_allowed"
                    )}`}
                    name="max_allowed"
                    {...formik.getFieldProps("max_allowed")}
                  />
                  {formik.touched.max_allowed && formik.errors.max_allowed ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.max_allowed}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Token Amount ( No. of tokens for presale )
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "tokenAmount"
                    )}`}
                    name="tokenAmount"
                    {...formik.getFieldProps("tokenAmount")}
                  />
                  {formik.touched.tokenAmount && formik.errors.tokenAmount ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.tokenAmount}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>


              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Initial supply
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "initial_supply"
                    )}`}
                    name="initial_supply"
                    {...formik.getFieldProps("initial_supply")}
                  />
                  {formik.touched.initial_supply && formik.errors.initial_supply ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.initial_supply}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Initial Market capitalization
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "market_cap"
                    )}`}
                    name="market_cap"
                    {...formik.getFieldProps("market_cap")}
                  />
                  {formik.touched.market_cap && formik.errors.market_cap ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.market_cap}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Total raise </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "total_raise"
                    )}`}
                    name="total_raise"
                    {...formik.getFieldProps("total_raise")}
                  />
                  {formik.touched.total_raise && formik.errors.total_raise ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.total_raise}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>


              <Col md={12}>
                <div className="fs-20px fw-600 border-bottom-yellow pt-4 pb-2 text-yellow mb-4">
                  2 - Liquidity Information
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Start Date and Time
                  </label>
                  <DatePicker
                    showTimeSelect
                    filterTime={filterPassedTime}
                    timeIntervals={10}
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={`form-control PR_input ${getInputClasses(
                      "start_date"
                    )}`}
                    selected={formik.values.start_date}
                    minDate={new Date()}
                    name="start_date"
                    // onChangeRaw={handleDateChangeRaw}
                    onChange={(date) => {
                      if (date) {
                        formik.setFieldValue("start_date", date.getTime());
                      } else {
                        formik.setFieldValue("start_date", "");
                      }
                    }}
                    selectsStart
                    startDate={formik.values.start_date}
                    endDate={formik.values.end_date}
                    placeholderText="Start Date"
                    showYearDropdown={true}
                    showMonthDropdown={true}
                  />
                  {formik.touched.start_date && formik.errors.start_date ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.start_date}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
            
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    End Date and Time
                  </label>
                  <DatePicker
                    showTimeSelect
                    filterTime={filterPassedTime}
                    timeIntervals={10}
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={`form-control PR_input ${getInputClasses(
                      "end_date"
                    )}`}
                    selected={formik.values.end_date}
                    name="end_date"
                    minDate={formik.values.start_date}
                    // onChangeRaw={handleDateChangeRaw}
                    onChange={(date) => {
                      if (date && formik.values.start_date!=="") {
                        formik.setFieldValue("end_date", date.getTime());
                      } else {
                        toast.warning(
                          "Please select start date first."
                        );
                        formik.setFieldValue("end_date", "");
                        formik.setTouched({...formik.touched,"end_date": true });
                        
                      }
                    }}
                    selectsEnd
                    startDate={formik.values.start_date}
                    endDate={formik.values.end_date}
                    placeholderText="End Date"
                    showYearDropdown={true}
                    showMonthDropdown={true}
                  />
                  {formik.touched.end_date && formik.errors.end_date ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.end_date}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Vesting Percentage
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "vesting_Percentage"
                    )}`}
                    name="vesting_Percentage"
                    {...formik.getFieldProps("vesting_Percentage")}
                  />
                  {formik.touched.vesting_Percentage &&
                    formik.errors.vesting_Percentage ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.vesting_Percentage}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Lock Duration(secs)
                  </label>
                  <input
                    type="number"
                    onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "lock_Duration"
                    )}`}
                    name="lock_Duration"
                    {...formik.getFieldProps("lock_Duration")}
                  />
                  {formik.touched.lock_Duration &&
                    formik.errors.lock_Duration ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block  text-danger">
                        {formik.errors.lock_Duration}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Lock Liquidity Method
                  </label>
                  <select
                    style={{ background: "#1e2235" }}
                    name="lock_Liquidity"
                    className="form-control PR_input re_input"
                    onChange={LiquidityChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lock_Liquidity}
                    selected=""
                  >
                    <option value="" disabled hidden>
                      select one
                    </option>
                    <option key="automatic" value="automatic">
                      Automatic
                    </option>
                    <option key="manual" value="manual">
                      Manual
                    </option>
                  </select>
                  {formik.touched.lock_Liquidity &&
                    formik.errors.lock_Liquidity ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block  text-danger">
                        {formik.errors.lock_Liquidity}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              {lock_Liquidity === "automatic" && (
                <Col md={6}>
                  <div className="form-group" data-toggle="tooltip" data-placement="top" title="Percentage of USDT Earned.">
                    <label className="fs-14px text-white opacity-70">
                      Pancake Percentage
                    </label>
                    <input
                      type="text"
                      className={`form-control PR_input re_inputRouded ${getInputClasses(
                        "pool_rate"
                      )}`}
                      name="pool_rate"
                      {...formik.getFieldProps("pool_rate")}
                    />
                    {formik.touched.pool_rate && formik.errors.pool_rate ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                          {formik.errors.pool_rate}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
              {lock_Liquidity === "automatic" && (
                <Col md={6}>
                  <div className="form-group" data-toggle="tooltip" data-placement="top" title="Rate of Campaign Token On Pancakeswap per USDT.">
                    <label className="fs-14px text-white opacity-70">
                      Pancake rate
                    </label>
                    <input
                      type="text"
                      className={`form-control PR_input re_inputRouded ${getInputClasses(
                        "pancake_percentage"
                      )}`}
                      name="pancake_percentage"
                      {...formik.getFieldProps("pancake_percentage")}
                    />
                    {formik.touched.pancake_percentage &&
                      formik.errors.pancake_percentage ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                          {formik.errors.pancake_percentage}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
              {lock_Liquidity === "automatic" && (
                <Col md={6}>
                  <div className="form-group" >
                    <label className="fs-14px text-white opacity-70">
                      Pancake Duration
                    </label>
                    <input
                      type="text"
                      className={`form-control PR_input re_inputRouded ${getInputClasses(
                        "pancake_Duration"
                      )}`}
                      name="pancake_Duration"
                      {...formik.getFieldProps("pancake_Duration")}
                    />
                    {formik.touched.pancake_Duration &&
                      formik.errors.pancake_Duration ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                          {formik.errors.pancake_Duration}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}

              {lock_Liquidity === "manual" && (
                <Col md={12}>
                  <div className="form-group">
                    <label className="fs-14px text-white opacity-70">
                      Transfer Address
                    </label>
                    <input
                      type="text"
                      className={`form-control PR_input re_inputRouded ${getInputClasses(
                        "transferAddress"
                      )}`}
                      name="transferAddress"
                      {...formik.getFieldProps("transferAddress")}
                    />
                    {formik.touched.transferAddress &&
                      formik.errors.transferAddress ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                          {formik.errors.transferAddress}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Col>
              )}
              <Col md={12}>
                <div className="fs-20px fw-600 border-bottom-yellow pt-4 pb-2 text-yellow mb-4">
                  3 - Project Information
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Name
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "projectName"
                    )}`}
                    name="projectName"
                    {...formik.getFieldProps("projectName")}
                  />
                  {formik.touched.projectName && formik.errors.projectName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectName}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Token Name/Symbol
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "tokenName"
                    )}`}
                    name="tokenName"
                    {...formik.getFieldProps("tokenName")}
                  />
                  {formik.touched.tokenName && formik.errors.tokenName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.tokenName}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Website
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "website"
                    )}`}
                    name="website"
                    {...formik.getFieldProps("website")}
                  />
                  {formik.touched.website && formik.errors.website ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.website}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Campaign Banner
                  </label>
                  <div className="upload-btn-wrapper py-1 form-control PR_input">
                    <div className="text-white pr-3">
                      {tempCampaignBanner !== "" && tempCampaignBanner!==null
                        ?tempCampaignBanner.name &&tempCampaignBanner.name.length > 20? tempCampaignBanner.name.substring(0,20)+"..." :tempCampaignBanner.name
                        : ""}
                    </div>
                    <input
                      type="file"
                      name="campaignBanner"
                      onChange={(event) =>
                        onSelectCampaignBanner(event, formik)
                      }
                    />
                    <button className="btn">
                      <span>Upload</span>
                    </button>
                  </div>
                  {formik.touched.campaignBanner &&formik.errors.campaignBanner ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.campaignBanner}
                      </div>
                    </div>
                  ) : null}
                </div>
                
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Token Icon / Logo
                  </label>
                  <div className="upload-btn-wrapper py-1 form-control PR_input">
                    <div className="text-white pr-3">
                    {tempTokenUrl !== "" && tempTokenUrl!==null
                        ?tempTokenUrl.name &&tempTokenUrl.name.length > 20? tempTokenUrl.name.substring(0,20)+"..." :tempTokenUrl.name
                        : ""}
                    </div>
                    <input
                      type="file"
                      name="tokenLogo"
                      onChange={(event) => onSelectDocument(event, formik)}
                    />
                    <button className="btn">
                      <span>Upload</span>
                    </button>
                  </div>
                  {formik.touched.tokenLogo && formik.errors.tokenLogo ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.tokenLogo}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Telegram Link
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "telegramLink"
                    )}`}
                    name="telegramLink"
                    {...formik.getFieldProps("telegramLink")}
                  />
                  {formik.touched.telegramLink && formik.errors.telegramLink ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.telegramLink}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Twitter Link
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "twitterLink"
                    )}`}
                    name="twitterLink"
                    {...formik.getFieldProps("twitterLink")}
                  />
                  {formik.touched.twitterLink && formik.errors.twitterLink ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.twitterLink}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Discord Link
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "discordLink"
                    )}`}
                    name="discordLink"
                    {...formik.getFieldProps("discordLink")}
                  />
                  {formik.touched.discordLink && formik.errors.discordLink ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.discordLink}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Description
                  </label>

                  <textarea
                    name="projectInfo"
                    rows={4}
                    placeholder="Project Description"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "projectInfo"
                    )}`}
                    {...formik.getFieldProps("projectInfo")}
                  ></textarea>
                  {formik.touched.projectInfo && formik.errors.projectInfo ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectInfo}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} className="text-right mt-10">
                <button
                  className="btn-blue btn w-150px "
                  disabled={!isApproveEnable}
                  type="submit"
                >
                  <span>Approve</span>
                </button>
                ' '
                <button
                  className="btn-blue btn w-150px "
                  disabled={isApproveEnable}
                  type="submit"
                >
                  <span>Create</span>
                </button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>

      <Modal
        show={multipleTicketModal}
        onHide={onCloseMultipleTicket}
        className="PR_modal"
        centered
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">
            Multiple User's Check
          </div>
        </Modal.Header>
        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik1.handleSubmit}>
              {/* <Col md={12}>
                <div className="h4 pb-3 ">Whitelist Address</div>
              </Col> */}
              <Col md={12} lg={12}>
                <div className="form-group">
                  <textarea
                    name="address"
                    rows={4}
                    placeholder="Enter 0x,Xo,JD ..."
                    className="form-control re_input PR_input"
                    {...formik1.getFieldProps("address")}
                  ></textarea>

                  {formik1.touched.address && formik1.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={loading}
                >
                  {loading ? "Checking..." : "Check"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default Create;
