import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Web3 from "web3";
import OngoingTimer from "./OngoingTimer";
import UpcomingTimer from "./UpcomingTimer";
import { useHistory } from "react-router-dom";
// import Profile from "../assets/images/Profile.png";
// import Profile1 from "../assets/images/Profile-1.png";
// import Profile2 from "../assets/images/Profile-2.png";
// import Profile3 from "../assets/images/Profile-3.png";
import Countdown from "react-countdown";
export const SaleItem = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="PR_saleItemBox">
        <div className="PR_img">
          <img src={props.item.tokenLogo} alt="" />
          <img src={props.item.tokenLogo} alt="" className="PR_Blur" />
        </div>
        <div className="text-center text-white fs-14px fw-400 py-3">
          <div className="d-flex justify-content-around">
            {/* <div>
              <ul>
                <li><img src={Profile} /></li>
                <li><img src={Profile1} /></li>
                <li><img src={Profile2} /></li>
                <li><img src={Profile3} /></li>

              </ul>
            </div> */}
            <div>{props.item.tokenName}</div>
          </div>
        </div>
        <div className="text-white fs-xl-20px fs-16px fw-700 text-truncate">
          {props.item.projectName}
        </div>
        <hr />
        <Row className="align-items-center pb-1 pt-3">
          <Col
            xs={6}
            className="fs-xxl-14px fs-12px fw-400 text-white opacity-70 "
          >
            TOTAL RAISE
          </Col>
          <Col xs={6} className="text-right fs-xxl-18px fs-16px text-white">
            {Web3.utils.fromWei(props.item.tokenAmount)}
          </Col>
        </Row>
        <Row className="align-items-center py-1">
          <Col
            xs={6}
            className="fs-xxl-14px fs-12px fw-400 text-white opacity-70 "
          >
            EXCHANGE RATE
          </Col>
          <Col xs={6} className="text-right fs-xxl-18px fs-16px text-white">
            {parseFloat(Web3.utils.fromWei(props.item.tokenPerUSDT)).toFixed(3)}
          </Col>
        </Row>
        <Row className="align-items-center py-1">
          <Col
            xs={6}
            className="fs-xxl-14px fs-12px fw-400 text-white opacity-70 "
          >
            SUPPORTED
          </Col>
          <Col xs={6} className="text-right fs-xxl-18px fs-16px text-white">
            {props.item.network_type}
          </Col>
        </Row>
        <>
          {" "}
          <div className="text-center">
            <hr />
            <Button
              variant="blue"
              className="px-4 text-uppercase"
              onClick={() => {
                history.push(`/detail/${props.item._id}`);
              }}
            >
              Check Now
            </Button>
          </div>
          {props.item.status &&
            props.item.status !== undefined &&
            props.item.status === "ongoing" && (
              <Countdown
                date={parseInt(props.item.end_date + "000")}
                status={props.item.status}
                renderer={OngoingTimer}
              />
            )}
          {props.item.status &&
            props.item.status !== undefined &&
            props.item.status === "upcoming" && (
              <Countdown
                date={parseInt(props.item.start_date + "000")}
                status={props.item.status}
                renderer={UpcomingTimer}
              />
            )}
          {props.item.status &&
            props.item.status !== undefined &&
            props.item.status === "completed" ? (
            <>
              <div className="text-center text-white fs-14px opacity-70 fw-400 text-uppercase pt-3">
                Ended
              </div>
              {/* <div className="PR_bottomShape"> {new Date()}</div> */}
            </>
          ) : (
            ""
          )}
          {/* {props.item.start_date && props.item.end_date!== undefined && props.item.end_date !== null && <Countdown date={new Date(props.item.end_date)} renderer={Timer} />} */}
        </>
      </div>
    </>
  );
};
