import React, { useEffect, useState, useContext, useCallback } from "react";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Icon from "../../Components/Icon";
import RSlider from "../../Components/RangeSlider/Slider";
import img1 from "./../../assets/images/logo1.png";
import dops_img from "./../../assets/images/dops_img.png";
import img2 from "./../../assets/images/hero_banner.png";
import Loader from "../loader/loader";
import img3 from "./../../assets/images/usdt.png";
import harmony from "./../../assets/images/harmony.png";
import polygon from "./../../assets/images/polygon.png";
import binance from "./../../assets/images/binance.png";
import inverntory_1 from "../../assets/images/inverntory_1.png";
import cart from "../../assets/images/cart.png";
import box_o1 from "../../assets/images/box_o1.png";
import nfts_img from "../../assets/images/nfts_img.png";
import aag_token_img from "../../assets/images/aag_token_img.png";
import { ethers } from "ethers";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { enviornment } from "../../constants/constants";
import Countdown from "react-countdown";
import { createMysteryMethods } from "../../functions/createMystery";
import { Web3Context } from "../../web3/contexts/web3Context";
import { createMethods } from "../../functions/create";
import giftBox from "./../../assets/images/giftBox.png";
import igo_allocation from "./../../assets/images/igo_allocation.png";
import igo_allo_img from "./../../assets/images/igo_allo_img.png";

import prem_nft from "./../../assets/images/prem_nft.png";
import prem_nft_img from "./../../assets/images/prem_nft_img.png";
import igo_nft from "./../../assets/images/igo_nft.png";
import DetailTimer from "../../Components/MysteryDetailTimer";

const domain = [0, 100];

const MysteryBoxesDetails = () => {
  const [timer, setTimer] = useState([]);
  const { networkDetails } = useContext(Web3Context);
  const [instance, setInstance] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [mysteryData, setMysteryData] = useState({});
  const [mysteryDetailsSc, setMysteryDetailsSc] = useState({});
  const [isApproveEnable, setIsApproveEnable] = useState(true);
  const [allMysteryItems, setAllMysteryItems] = useState([])

  const { mysteryId, boxId } = useParams();
  useEffect(() => {
    if (
      mysteryId === "" ||
      mysteryId === undefined ||
      boxId === undefined ||
      boxId === ""
    ) {
      history.goBack();
    }
  }, [mysteryId, boxId]);


  const nameOnImage = name => {
    let El = null;
    switch (name) {
      case "IGO Allocation Token":
        El = "IGO Allocation"
        break;
      case "Airdrop":
        El = "Airdrop"
        break;
      case "IGO+NFT":
        El = "IGO Allocation + NFTs";
        break;
      case "Premium NFT":
        El = "Premium NFTs";
        break;
      default:
        El = '';
        break;
    }
    return El
  };

  const nftImage = name => {
    let El = null;
    switch (name) {
      case "IGO Allocation Token":
        El = igo_allo_img;
        break;
      case "Airdrop":
        El = dops_img;
        break;
      case "IGO+NFT":
        El = nfts_img;
        break;
      case "Premium NFT":
        El = prem_nft_img;
        break;
      default:
        El = igo_allo_img;
        break;
    }
    return El
  };

  useEffect(() => {
    setLoading(true);
    if (networkDetails && networkDetails.connected) {
      getMysteryDataApi();
      loadMysteryInstance();
    } else {
      setLoading(false);
      if (networkDetails && networkDetails.isUpdated) {
        toast.error("Please Connect with Metamask first.");
      }
    }
  }, [networkDetails]);

  const getMysteryDataApi = useCallback(async () => {
    if (mysteryId) {
      await createMysteryMethods
        .getMystery(mysteryId)
        .then((response) => {
          if (response.data.success) {
            setMysteryData(response.data.data);
            // setLoading(false)
          } else {
            toast.error(
              "Their is some error while fetching data. Please try again after sometime."
            );
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [mysteryId]);

  const [ShowClaim, setShowClaim] = useState(false);
  const handleShowClaimModal = () => setShowClaim(true);
  const handleHideClaimModal = () => setShowClaim(false);

  const loadMysteryInstance = async () => {
    await createMethods
      .getInstance(
        networkDetails.web3,
        enviornment[networkDetails.networkId].mysterySC,
        enviornment[networkDetails.networkId].mysteryABI
      )
      .then((data) => {
        setInstance(data);
        // setLoading(false)
      })
      .catch((err) => {
        toast.error("There is some error while creating instance.");

        setLoading(false);
      });
  };

  useEffect(() => {
    if (instance !== null && Object.keys(mysteryData).length > 0) {
      setMysteryDetails(instance);

      const getAllMysteryItems = mysteryData.itemDetails.filter(
        (mystery) => mystery.set_status && mystery.set_status !== undefined && mystery.set_status === 1
      );
      setAllMysteryItems(getAllMysteryItems)

    }
  }, [mysteryData, instance]);

  const setMysteryDetails = async (Instance) => {
    try {
      let obj = {};
      if (Object.keys(mysteryData).length > 0) {
        await Instance.methods
          .mysteryBoxes(mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }, (err, data) => {
            if (err) {
              toast.error("Error in fetching mystery box details.");
            }
            if (data) {
              let temp = {
                availableBoxes: data["availableBoxes"],
                claimTime: data["claimTime"],
                endTime: data["endTime"],
                Name: data["Name"],
                startTime: data["startTime"],
                totalTreasureBoxes: data["totalTreasureBoxes"],
                itemCount: data["itemCount"],
                mysteryBoxId: data["mysteryBoxId"],
              };

              obj.mysteryBoxDetail = temp;
            }
          });

        await Instance.methods
          .totalticket(networkDetails.address)
          .call({ from: networkDetails.address }, async (err, data) => {
            if (err) {
              toast.error("Error in fetching total tickets.");
            }
            if (data) {
              obj.totalTickets = await data;
            }
          });

        await Instance.methods
          .openBoxCount(mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }, async (err, data) => {
            if (err) {
              toast.error("Error in fetching open box count.");
            }
            if (data) {
              obj.openBoxCount = await data;
            }
          });

        await Instance.methods
          .claimBoxCount(mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }, async (err, data) => {
            if (err) {
              toast.error("Error in fetching claim box count.");
            }
            if (data) {
              obj.claimBoxCount = await data;
            }
          });

        let a = await Instance.methods
          .boxes(parseInt(boxId))
          .call({ from: networkDetails.address })


        obj.treasuryBoxDetails = a;

        if (a && a !== undefined) {
          let itemDetails = await Instance.methods
            .ItemList(parseInt(a['offereditemId']))
            .call({ from: networkDetails.address })

          obj.itemDetails = itemDetails;
          console.log('------------------itemDetails', itemDetails);

          if (itemDetails["itemName"] === "IGO Allocation Token" || "IGO+NFT" || "Airdrop" || "Premium NFT") {

            if (itemDetails["tokenAddERC20"] != "0x0000000000000000000000000000000000000000") {
              await createMethods
                .getInstance(
                  networkDetails.web3,
                  itemDetails["tokenAddERC20"],
                  enviornment[networkDetails.networkId].commanERCABI
                )
                .then(async (instance) => {
                  let symbolDetails = await instance.methods
                    .symbol()
                    .call({ from: networkDetails.address })

                  let decimal = await instance.methods
                    .decimals().call({ from: networkDetails.address })

                  console.log('------------------decimal', decimal);
                  itemDetails["itemAmountERC20"] = ethers.utils.formatUnits(itemDetails["itemAmountERC20"], decimal ? decimal : 0)


                  if (symbolDetails && symbolDetails !== undefined) {
                    obj.symbolDetails = symbolDetails
                  }
                })
                .catch((err) => {
                  console.log('------------------err', err)
                  toast.error("There is some error while creating instance.");
                });
            }
            itemDetails["itemValue"] = ethers.utils.formatUnits(itemDetails["itemValue"], 6)

          }
        }

      }
      console.log('----------------obj-------', obj)
      setMysteryDetailsSc(obj);
      setLoading(false);
      //   setRefresh(false);
    } catch (err) {
      console.log(err, "errr000000000000");
      setLoading(false);
    }
  };


  const onClickPurchase = async () => {
    try {

      setLoading(true);
      let obj = await getSCBalanceDetails();
      let balanceDetail = {};

      if (obj && obj.USDTInstance) {
        balanceDetail.balanceToken = await createMethods.getBalanceUSDC(
          obj.USDTInstance,
          networkDetails.address
        );
        balanceDetail.allowancToken = await createMethods.allowanceUSDC(
          obj.USDTInstance,
          networkDetails.address,
          enviornment[networkDetails.networkId].mysterySC
        );


        if (
          balanceDetail.allowancToken >= mysteryDetailsSc.itemDetails["itemValue"] &&
          balanceDetail.balanceToken >= mysteryDetailsSc.itemDetails["itemValue"] &&
          isApproveEnable
        ) {
          setLoading(false);
          return setIsApproveEnable(false);
        }


        if (balanceDetail && balanceDetail.balanceToken > 0) {
          if (
            parseInt(balanceDetail.allowancToken) >= mysteryDetailsSc.itemDetails["itemValue"] &&
            parseInt(balanceDetail.balanceToken) >= mysteryDetailsSc.itemDetails["itemValue"] &&
            !isApproveEnable
          ) {
            await createMysteryMethods
              .claimTreasuryBox(
                obj.mysteryInstance,
                networkDetails.address,
                mysteryDetailsSc.treasuryBoxDetails["boxid"]
              )
              .then((resApprove) => {
                toast.info("Transaction success.");
                setIsApproveEnable(true);
                setLoading(false);
                handleHideClaimModal();
                window.location.reload();
              })
              .catch((er) => {
                if (er && er.code) {
                  toast.error(er.message);
                  setLoading(false);
                }
              });
          } else {
            if (parseInt(balanceDetail.balanceToken) > mysteryDetailsSc.itemDetails["itemValue"] && isApproveEnable) {
              await createMethods
                .approveUSDC(
                  obj.USDTInstance,
                  enviornment[networkDetails.networkId].mysterySC,
                  balanceDetail.balanceToken,
                  networkDetails.address
                )
                .then(async (data) => {
                  if (data) {
                    setIsApproveEnable(false);
                    setLoading(false);
                    // await props.handleFormSubmit2(values);
                    // toast.success("Tokens approved successfully.");
                    // await props.next();
                  }
                })
                .catch((er) => {
                  setLoading(false);
                  toast.error(er.message);
                });
            } else {

              setLoading(false);
              toast.error(
                "You don't have enough tokens."
              );
            }
          }
        } else {
          toast.error("USDC balance is zero");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }

  };


  const getSCBalanceDetails = async () => {
    let obj = {
      USDTInstance: "",
    };

    let USDTInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].USDTAddress,
      enviornment[networkDetails.networkId].commanERCABI
    );
    obj.USDTInstance = await USDTInstance;

    let mysteryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].mysterySC,
      enviornment[networkDetails.networkId].mysteryABI
    );
    obj.mysteryInstance = await mysteryInstance;


    return obj;

  };

  return (
    <>
      {loading && <Loader />}
      <Container fluid className="detail_page px-md-5 px-4">
        <p className="close_app">
          {/* Applications close on 27 Nov 2021, 1:00 PM UTC{" "}
                <Icon name="arrownext" /> */}
        </p>
        <Row className="mb-3">
          <Col xl={7}>
            <div className="subhead">
              <div className="subhead_logo ">
                <img
                  alt="alter image2"
                  style={{ maxWidth: "72px", maxHeight: "72px" }}
                  src={mysteryData.projectLogo ? mysteryData.projectLogo : img1}
                ></img>
              </div>
              <div className="subhead_content">
                <h1>
                  {mysteryData.projectName ? mysteryData.projectName : ""}
                </h1>
                <p>
                  {mysteryData.projectTitle ? mysteryData.projectTitle : ""}
                </p>
              </div>
            </div>
          </Col>
          <Col xl={5} className=" d-flex flex-column align-items-xl-end">
            <div className="sale_timer_wrap max-w-250px">

              <Button variant="blue" onClick={() => history.goBack()} >Back</Button> &nbsp;
              <Button variant="blue" onClick={() => window.location.reload()} >Refresh</Button>

              <p className="mt-2">
                {mysteryDetailsSc && mysteryDetailsSc.treasuryBoxDetails !== undefined &&
                  parseInt(mysteryDetailsSc.treasuryBoxDetails["endTimestamp"] + "000") > parseInt(new Date().getTime()) ?
                  "Claim Period Ends in" : "Completed"}
              </p>
              {/* expired class add after time end */}
              {mysteryDetailsSc && mysteryDetailsSc.treasuryBoxDetails != undefined &&
                (
                  <Countdown
                    date={parseInt(mysteryDetailsSc.treasuryBoxDetails["endTimestamp"] + "000")}
                    renderer={DetailTimer}
                  />
                )}
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl={5}>
            <div className=" PR_slide_middle mb-0 h-450px PR_Matbox mb-3 mb-xl-0">
              {/* <div className="PR_TopShape">
                Unlock in {timer[0]}D:{timer[1]}H:{timer[2]}
              </div> */}
              {/* <img src={img2} alt="mystery-main" /> */}
              <Button
                variant="yellow btn_tokens middle_btns_token"

              >{nameOnImage(mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"])}
              </Button>
              <div className="box_o1 postion_img_btn">
                <img src={nftImage(mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"])} alt="mystery-main" />
              </div>
            </div>
          </Col>
          <Col xl={7} className="PR_MyContentBoxMain">
            <div className="PR_MyContentBox">
              <div className="d-flex flex-md-row flex-column">
                <span className="text-orange ">
                  <Icon name="mystery" className="mr-2 w-50px h-50px " />
                </span>

                <div>
                  <div className="fs-xl-32px fs-24px ">
                    <span className="text-white">
                      {/* {console.log(mysteryDetailsSc.itemDetails["itemName"])} */}
                      {/* You have been assigned a MysteryBox which worth $500 USD.{" "} */}
                      {mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && (mysteryDetailsSc.itemDetails["itemName"] === "IGO Allocation Token" || "IGO+NFT" || "Airdrop") &&
                        mysteryDetailsSc.itemDetails["itemAmountERC20"] + " " + `${mysteryDetailsSc.symbolDetails ? mysteryDetailsSc.symbolDetails : ""}/BOX`}
                      {mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"] === "Premium NFT" &&
                        "Premium NFT"
                      }

                    </span>
                  </div>
                  <div className="fs-xl-20px fs-16px text-white mt-3 opacity-70 fw-400  ">
 
{
                mysteryData && <p className="mb-5" dangerouslySetInnerHTML={{ __html: mysteryData.projectShortDesc }} ></p>
              }

                  </div>
                  {mysteryDetailsSc && mysteryDetailsSc.treasuryBoxDetails != undefined &&
                    parseInt(mysteryDetailsSc.treasuryBoxDetails["endTimestamp"] + "000") > parseInt(new Date().getTime()) && !mysteryDetailsSc.treasuryBoxDetails["isclaimed"] &&
                    <Button
                      variant="yellow"
                      onClick={handleShowClaimModal}
                      className="max-w-100 "
                    >
                      Purchase Mystery Box
                    </Button>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 align-items-center">
          <Col xl={5}>
            <p className="worth_token pb-0 mb-0">
              You have earned allocation worth ${mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemValue"]} of {nameOnImage(mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"])}
            </p>
          </Col>
          <Col xl={7}>
            <div className="d-flex flex-wrap justify-content-around">
              <div className="text-center px-4 w-175px">
                <div className="fs-xl-32px fs-24px fw-700 text-white">
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.mysteryBoxDetail &&
                    mysteryDetailsSc.mysteryBoxDetail.totalTreasureBoxes}
                </div>
                <div className="fs-xl-16px fs-14px fw-500 text-white opacity-70">
                  Total Mystery Boxes
                </div>
              </div>
              <div className="text-center px-4 w-175px">
                <div className="fs-xl-32px fs-24px fw-700 text-white">
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.openBoxCount &&
                    mysteryDetailsSc.openBoxCount}
                </div>
                <div className="fs-xl-16px fs-14px fw-500 text-white opacity-70">
                  Participants
                </div>
              </div>
              <div className="text-center px-4 w-175px">
                <div className="fs-xl-32px fs-24px fw-700 text-white">
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.claimBoxCount &&
                    mysteryDetailsSc.claimBoxCount}
                </div>
                <div className="fs-xl-16px fs-14px fw-500 text-white opacity-70">
                  Boxes Claimed
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Tabs
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          className="TL_tabs"
        >
          <Tab eventKey="1" title="Rule Introduction">
            <div className="max-w-850px">
              <div className="main_banner mb-5">
                <img
                  alt="alter image12"
                  src={
                    mysteryData.projectBanner ? mysteryData.projectBanner : img2
                  }
                />
              </div>
              <h2>Long Description:</h2>
              {
                mysteryData && <p className="mb-5" dangerouslySetInnerHTML={{ __html: mysteryData.projectLongDesc }} ></p>
              }
 
              {/* <ul className="custom_listing mb-5">
                <li>
                  <span> Dynamically: </span> Collaboratively unleash extensive
                  core competencies through global platforms.Appropriately
                  supply long-term high-impact total linkage after high-quality
                  networks.Interactively maximize emerging vortals via market
                  positioning alignments.Dynamically engineer adaptive
                  e-commerce via team driven communities.Quickly exploit
                  intermandated innovation whereas high-payoff nickhes
                  corporate.
                </li>
                <li>
                  <span>Bandwidth: </span> Completely reconceptualize
                  enterprise-wide information with progressive
                  deliverables.Conveniently impact mission-critical web services
                  rather than strategic bandwidth.Progressively evisculate
                  global "outside the box" thinking with innovative
                  e-markets.Credibly embrace resource-leveling technologies and
                  backend relationships.Objectively disseminate interoperable
                  bandwidth with pandemic methods of empowerment.
                </li>
                <li>
                  <span>Dramatically: </span> Rapidiously synergize functional
                  convergence are after provided by Phosfluorescently drive
                  customer directed testing procedures via corporate
                  infrastructures.Dramatically fabricate low-risk high-yield
                  imperatives via competitive applications.
                </li>
              </ul> */}
            </div>
          </Tab>
          <Tab eventKey="2" title="Inventory">
            <h1>Inventory</h1>
            <div className="max-w-850px">
              <Row>
                {allMysteryItems &&
                  allMysteryItems.length > 0 ? allMysteryItems.map((item, index) =>

                    <Col xl={6} lg={6} key={item["_id"]} >
                      <div className="main_banner mt-3 p-2 h-auto">
                        <div className="d-flex align-items-center">
                          <div className="inventory_left_img">

                            <img src={nftImage(item["item_category"])} alt="mystery-main" />

                          </div>
                          <div className="inventory_right ml-3">
                            <h3>{item["item_category"]}</h3>
                            <ul className="pl-0">
                              <li>{parseInt(item['item_value'])} of {parseInt(item['item_count']) * parseInt(item['item_amount'])} </li>
                              {/* <li className="opacity_7 pl-2 ">901 / 950x</li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : <Col xl={6} lg={6} ><div className=" text-white">No boxes available</div></Col>}


                {/* <Col xl={6} lg={6}>
                  <div className="main_banner mt-3 p-2 h-auto">
                    <div className="d-flex align-items-center">
                      <div className="inventory_left_img">
                        <img
                          alt="inverntory_1"
                          src={inverntory_1}
                          width="120px"
                          height="120px"
                        />
                      </div>
                      <div className="inventory_right ml-3">
                        <h3>Common 1x1</h3>
                        <ul className="pl-0">
                          <li>$190 |</li>
                          <li className="opacity_7 pl-2 ">901 / 950x</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col> */}

              </Row>
            </div>
          </Tab>

          {/* <Tab eventKey="4" title="Timeline">
                    <h2>Comingsoon..</h2>
                </Tab>
                <Tab eventKey="5" title="Collection (0)">
                    <h2>Comingsoon..</h2>
                </Tab> */}
        </Tabs>
      </Container>
      <Modal
        show={ShowClaim}
        size="xl"
        onHide={handleHideClaimModal}
        centered
        className="PR_modalBlack"
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={5}>
              <div className="PR_claimImagBox height_box">
                {/* <div className="PR_TopShape text-uppercase">
                  You have earned
                </div> */}
                {/* <img src={img2} alt="mystery-main" /> */}
                {/* <div className="PR_bottom text-center text-white fs-xl-20px fs-16px fw-500 w-100">$50 Allocation of $AGG tokens</div> */}
                <button
                  type="button"
                  className="btn btn-yellow aag_tokens_btn btn_tokens"
                >
                  {nameOnImage(mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"])}
                </button>
                <div className="box_o1 postion_img_btn">
                  <img src={nftImage(mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"])} alt="mystery-main" />
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="fs-xl-32px fs-24px ">
                <span className="text-orange">
                  <Icon name="mystery" className="mr-2 w-50px h-50px" />
                </span>
                <span className="text-white">{nameOnImage(mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemName"])} to be revealed</span>
              </div>
              <hr />
              <div>
                <div className="d-inline-block mr-3">
                  <p className="mb-2">Supported</p>
                  <div className="PR_fieldBox2 mb-1">
                    <img
                      src={img3}
                      className="mr-3"
                      width="24px"
                      height="24px"
                      alt=""
                    />

                    {mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && (mysteryDetailsSc.itemDetails["itemName"] === "IGO Allocation Token" || "IGO+NFT" || "Airdrop") &&
                      `${mysteryDetailsSc.symbolDetails ? mysteryDetailsSc.symbolDetails : ""}`}
                  </div>
                </div>
                <div className="d-inline-block">
                  <p className="mb-2">Chain Blockchain</p>
                  <div className="PR_fieldBox2 mb-1">
                    {/* <img
                      src={binance}
                      className="mr-3"
                      width="24px"
                      height="24px"
                      alt=""
                    />
                   {mysteryData.projectName ? mysteryData.projectName : ""} */}


                    {mysteryData && mysteryData.network_type == 'binance'
                      ? (<img className="mr-3" src={binance} height={'24px'} width={'24px'} alt="alter image5"></img>)
                      : ""}

                    {mysteryData && mysteryData.network_type == 'polygon'
                      ? (<img className="mr-3" src={polygon} height={'24px'} width={'24px'} alt="alter image5"></img>)
                      : ""}

                    {mysteryData && mysteryData.network_type == 'harmony'
                      ? (<img className="mr-3" src={harmony} height={'24px'} width={'24px'} alt="alter image5"></img>)
                      : ""}

                    {mysteryData && mysteryData.network_type == 'binance'
                      ? "Binance smartchain"
                      : ""}

                    {mysteryData && mysteryData.network_type == 'polygon'
                      ? "Polygon (MATIC) Network"
                      : ""}

                    {mysteryData && mysteryData.network_type == 'harmony'
                      ? "Harmony Network"
                      : ""}

                  </div>
                </div>
              </div>
              <div className="max_total">
                {/* <div className="d-inline-block">
                                <p className="mb-2">Amount</p>
                                <div className="PR_fieldBox2 mb-1">01</div>
                            </div> */}
                <div className="d-inline-block ">
                  <p className="mb-2">Purchase Cost</p>
                  <div className="PR_fieldBox2 mb-1">$ {mysteryDetailsSc && mysteryDetailsSc.itemDetails != undefined && mysteryDetailsSc.itemDetails["itemValue"]}</div>
                </div>
                {/* <div className="d-inline-block">
                                <p className="mb-2">Total Cost</p>
                                <div className="PR_fieldBox2 mb-1">725/1,000</div>
                            </div> */}
              </div>
              <div className="sale_timer_wrap ">
                <p>Time left to purchase your Mystery Box</p>
                {/* expired class add after time end */}
                <div className="sale_timer d-flex justify-content-start expired">
                  {/* <div className="time">{timer[0]}<span>DAY</span></div> */}
                  {mysteryDetailsSc && mysteryDetailsSc.treasuryBoxDetails != undefined &&
                    (
                      <Countdown
                        date={parseInt(mysteryDetailsSc.treasuryBoxDetails["endTimestamp"] + "000")}
                        renderer={DetailTimer}
                      />
                    )}
                </div>
              </div>
              <div className="progress_wrap">
                <p className="m-0">Total Boxes Claimed</p>
                <div className="re_slider1">
                  {/* <RSlider
                    defaultValues={[(parseInt(mysteryDetailsSc.claimBoxCount) /parseInt(mysteryDetailsSc.mysteryBoxDetail.totalTreasureBoxes)) * 100 ]}  
                    domain={domain}
                    mode={2}
                    step={1}
                    left={true}
                  /> */}
                </div>
                <div className="progress_value pt-2">
                  {/* <div id="rangeValue">{parseInt((parseInt(mysteryDetailsSc.claimBoxCount) /parseInt(mysteryDetailsSc.mysteryBoxDetail.totalTreasureBoxes)) * 100)}%</div> */}
                  <div className="amnt">{mysteryDetailsSc &&
                    mysteryDetailsSc.claimBoxCount &&
                    mysteryDetailsSc.claimBoxCount} / {mysteryDetailsSc &&
                      mysteryDetailsSc.mysteryBoxDetail &&
                      mysteryDetailsSc.mysteryBoxDetail.totalTreasureBoxes} Boxes</div>
                </div>
              </div>
              {/* className="hide" before timer end  */}
              <div className="d-flex">

                <Button
                  variant="yellow"
                  className=" mt-4 buy_now max-w-100 mr-2"
                  onClick={() => {
                    onClickPurchase();
                  }}
                  disabled={!isApproveEnable || loading}
                >
                  Approve
                </Button>

                <Button
                  variant="yellow"
                  className=" mt-4 buy_now max-w-100"
                  onClick={() => {
                    onClickPurchase();
                  }}
                  disabled={isApproveEnable || loading}
                >
                  {" "}
                  <img
                    src={cart}
                    alt="cart"
                    className="opacity_7 mr-2 "
                    width="20px"
                    height="20px"
                  />
                  Purchase Now
                </Button>

              </div>
              {/* <p className="mb-2">Amount</p>
                        <div className="PR_fieldBox mb-3">1</div>
                        <p className="mb-2">Bought/Max</p>
                        <div className="PR_fieldBox mb-3">725/1,000</div> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MysteryBoxesDetails;
