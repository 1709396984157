import React from "react";

const OngoingTimer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return (
      <>
        <div className="text-center text-white fs-14px opacity-70 fw-800 text-uppercase pt-3">Ended</div>
        {/* <div className="PR_bottomShape"> {new Date().toString()}</div> */}
      </>);
  } else {
    // Render a countdown
    return (
      <>
        <div className="text-center text-white fs-14px fw-800 text-uppercase pt-3">
          {/* {status && status == 'ongoing' ? 'Ends in' : 'Whitelist End in'} */}Ends in
        </div>
        <div className="PR_bottomShape"> {days > 0 ? days * 24 + hours : hours} HH:  {minutes} MM:  {seconds} SS</div>
        {/* <div className="font-size-lg-16px font-size-14px text-primary font-weight-500">
            Auction ending in
          </div>
          <div className="d-flex mr-3">
            <div className="mr-3">
              <div className="font-size-lg-32px font-size-24px font-weight-500 py-2">
                {days > 0 ? days * 24 + hours : hours}
              </div>
              <div className="font-size-lg-18px font-size-16px text-dark opacity-50 font-weight-500">
                Hours
              </div>
            </div>
            <div className="mr-3">
              <div className="font-size-lg-32px font-size-24px font-weight-500 py-2">
                {minutes}
              </div>
              <div className="font-size-lg-18px font-size-16px text-dark opacity-50 font-weight-500">
                Minutes
              </div>
            </div>
            <div className="mr-3">
              <div className="font-size-lg-32px font-size-24px font-weight-500 py-2">
                {seconds}
              </div>
              <div className="font-size-lg-18px font-size-16px text-dark opacity-50 font-weight-500">
                Seconds
              </div>
            </div>
          </div> */}
      </>
    );
  }
};

export default OngoingTimer