import React, { useContext, useRef, useEffect, useState } from "react";

import { Container, Table, Button } from "react-bootstrap";
import { createMethods } from "../../functions/create";
import { createMysteryMethods } from "../../functions/createMystery";

import { Web3Context } from "../../web3/contexts/web3Context";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
import AddMysteryBoxItem from "../../Components/addMysteryItem";
import EditModal from "../../Components/EditDescriptionModal"
import { enviornment } from "../../constants/constants";
import ViewMysteryBox from "../../Components/ViewMysteryBox";
import { useHistory, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import ReactPaginate from 'react-paginate';
import moment from "moment";

const MystertBoxListing = () => {
  const { networkDetails } = useContext(Web3Context);
  const history = useHistory();
  const inputRef = useRef(null);
  const inputElement = useRef();
  const [addItemBox, setAddItemBox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [refreshData, setRefreshData] = useState(true);
  const [mysteryBoxes, setMysteryBoxes] = useState([]);
  const [mysteryDetails, setMysteryDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [csvData, setCsvData] = useState([])
  const [dataToEdit, setDataToEdit] = useState({})
  const [editModal, setEditModal] = useState(false)


  // for pagination

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0)


  useEffect(() => {
    if (networkDetails && networkDetails.connected && refreshData) {
      if (networkDetails.isAdmin) {
        getMysteryBoxes();
      } else {
        // setLoading(true);
        return toast.warning("You are not a admin.", "Attention");
      }
    }
    // else {
    // setLoading(false);
    // toast.warning("You have to connect first !", "Attention");

    // }
  }, [networkDetails, refreshData]);


  useEffect(() => {

    getMysteryBoxes();

  }, [offset]);

  const onCloseItemBox = () => {
    setAddItemBox(false);
  };
  const onCloseModal = () => {
    setShowModal(false);
  };
  const closeEditModal = () => {
    setEditModal(false)
    setRefreshData(true)
  }

  const getMysteryBoxes = async () => {
    setLoading(true);

    let obj = {
      start: ((offset - 1) * 10),
      length: 10
    }
    let response = await createMysteryMethods.getMysteryBoxes(obj);
    if (response.data.success) {
      setLoading(false);
      setRefreshData(false);
      setMysteryBoxes(response.data.data['docs']);


      setPageCount(Math.ceil(response.data.data['totalDocs'] / 10))


    } else {
      setLoading(false);
      return toast.warning("Error while Fetching.", "Attention");
    }
  };
  //

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const getClassName = (status) => {
    switch (status) {
      case undefined: {
        return "Pending";
      }
      case null: {
        return "Pending";
      }
      case 0: {
        return "InProgress";
      }
      case 1: {
        return "Created";
      }
      case -1: {
        return "Failed";
      }

      default:
        return "Pending";
    }
  };

  const openCreateItem = (res) => {

    const startDate = new Date(parseInt(res["start_date"]) * 1000).getTime(); //in milliseconds
    const currentDate = new Date().getTime();
    if (currentDate < startDate) {
      setSelectedItem(res);
      setAddItemBox(true);
    } else {
      toast.warning("You can not add items in this mystery box,this mystery box is ongoing.")
      setTimeout(() => {
        window.location.reload()
      }, 2000);

    }

  };
  const openViewModal = (res) => {
    setMysteryDetails(res);
    setShowModal(true);
  };


  useEffect(() => {
    if (csvData.length > 0) {
      if (inputElement.current) {
        inputElement.current.link.click()
      }
    }
  }, [csvData])
  const callSmartContract = async (items, mysteryInstance, res) => {
    try {
      let idsArray = [];
      let APIobj = {};
      let itemName = [];
      let itemisERC20 = [];
      let itemisERC721 = [];
      let isAirdrop = [];
      let itemAmount = [];
      let itemValue = [];
      let itemCount = [];
      let tokenaddERC20 = [];
      let tokenAddERC721 = [];
      let NFTtokenArr = [];
      if (items && items.length >= 5) {
        for (let i = 0; i < 5; i++) {
          idsArray.push(items[i]._id);
          itemName.push(items[i].item_category);
          itemisERC20.push(items[i].is_erc20);
          itemisERC721.push(items[i].is_erc721);
          isAirdrop.push(items[i].is_Airdrop);
          itemAmount.push(ethers.utils.parseUnits(items[i].item_amount, items[i].item_decimal ? items[i].item_decimal : 0));
          itemValue.push(ethers.utils.parseUnits(items[i].item_value, 6));
          itemCount.push(parseInt(items[i].item_count));
          tokenaddERC20.push(items[i].erc20_address);
          tokenAddERC721.push(items[i].erc721_address);
          NFTtokenArr.push(items[i].NFT_idARY);
        }
      } else {
        for (let j = 0; j < items.length; j++) {
          idsArray.push(items[j]._id);
          itemName.push(items[j].item_category);
          itemisERC20.push(items[j].is_erc20);
          itemisERC721.push(items[j].is_erc721);
          isAirdrop.push(items[j].is_Airdrop);
          itemAmount.push(ethers.utils.parseUnits(items[j].item_amount, items[j].item_decimal ? items[j].item_decimal : 0));
          itemValue.push(ethers.utils.parseUnits(items[j].item_value, 6));
          itemCount.push(parseInt(items[j].item_count));
          tokenaddERC20.push(items[j].erc20_address);
          tokenAddERC721.push(items[j].erc721_address);
          NFTtokenArr.push(items[j].NFT_idARY);
        }
      }
      console.log('------------itemAmount--------', itemAmount)
      console.log('------------itemValue--------', itemValue)

      mysteryInstance.methods
        .setItem(
          parseInt(res.mysterySC_id),
          itemName,
          itemisERC20,
          itemisERC721,
          isAirdrop,
          itemAmount,
          itemValue,
          itemCount,
          tokenaddERC20,
          tokenAddERC721,
          NFTtokenArr
        )
        .send({ from: networkDetails.address })
        .on("transactionHash", async function (transactionHash) {
          APIobj.set_transaction_hash = transactionHash;
          APIobj._id = idsArray;
          const headers = {
            "Content-Type": "application/json",
          };
          const response = await axios.post(
            enviornment.URL + "/api/set-item",
            APIobj,
            { headers }
          );
          if (response) {
            setLoading(false);
            toast.success("Item set Successfully");
            window.location.reload();
            return response;
          } else {
            setLoading(false);
            window.location.reload();
            return toast.error("Their is some error with server");
          }
        })
        .on("error", function (error, receipt) {
          setLoading(false);
          toast.error(error);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const setSmartContractItems = async (res) => {
    try {
      const startDate = new Date(parseInt(res["start_date"]) * 1000).getTime(); //in milliseconds
      const currentDate = new Date();
      if (currentDate < startDate) {
        setLoading(true);
        let mysteryInstance = await createMethods.getInstance(
          networkDetails.web3, enviornment[networkDetails.networkId].mysterySC, enviornment[networkDetails.networkId].mysteryABI
        );
        if (res && res.itemDetails && res.itemDetails.length > 0) {
          if (mysteryInstance && mysteryInstance != undefined) {
            let filteredItems = await res.itemDetails.filter(
              (res) =>
                res.set_status == undefined &&
                res.set_status !== 0 &&
                res.set_status !== 1
            );
            if (filteredItems.length) {
              setLoading(false);
              // Swal.fire({
              //   title: "Are you sure You want to Set?"  ,
              //   // text: ,
              //   icon: "success",
              //   button: "Ok",
              // });

              Swal.fire({
                title: 'Are you sure You want to Set Item?',
                text: 'Once you set the Items, Can not be changed. Please check the Items clicking View button.',
                showDenyButton: true,
                showCancelButton: false,
                icon: 'warning',
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
                // footer:'Once you set the Items, Can not be changed. Please check the Items clicking View button.',
                customClass: {
                  actions: 'my-actions',
                  cancelButton: 'order-1 right-gap',
                  confirmButton: 'order-2',
                  denyButton: 'order-3',
                }
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await callSmartContract(filteredItems, mysteryInstance, res);

                  // Swal.fire('Saved!', '', 'success')
                } else if (result.isDenied) {
                  Swal.fire('You denied set', '', 'info')
                }
              })

              // 
            } else {
              setLoading(false);
              toast.warning("There are not items to set.");
            }
          } else {
            setLoading(false);
            toast.error("There is some error please, try again after some time.");
          }
        } else {
          setLoading(false);
          toast.warning("There are not items to set.");
        }
      } else {
        toast.warning("You can not set items in this mystery box,this mystery box is ongoing.")
        setTimeout(() => {
          window.location.reload()
        }, 2000);

      }

    } catch (err) {
      toast.error("There is some error please, try again after some time.");
      setLoading(false);
    }
  };
  const exportCsvData = async (res) => {

    setLoading(true);
    let mysteryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].mysterySC,
      enviornment[networkDetails.networkId].mysteryABI
    );
    if (mysteryInstance) {
      let airDrops = [];


      let ItemList = await mysteryInstance.methods.allItems(res.mysterySC_id).call();


      if (ItemList && ItemList.length) {
        for (let i = 0; i < ItemList.length; i++) {


          let itemDetails = await mysteryInstance.methods
            .ItemList(parseInt(ItemList[i]))
            .call();


          if (itemDetails && itemDetails['isAirdrop']) {
            let airDropDetail = await mysteryInstance.methods
              .airdropList(parseInt(itemDetails['itemId'])).call({ from: networkDetails.address });
            let symbolData = null
            await createMethods
              .getInstance(
                networkDetails.web3,
                itemDetails["tokenAddERC20"],
                enviornment[networkDetails.networkId].commanERCABI
              )
              .then(async (instance) => {
                symbolData = await instance.methods
                  .symbol()
                  .call({ from: networkDetails.address })
              })
              .catch((err) => {
                toast.error("There is some error while creating instance.");
              });

            if (airDropDetail && airDropDetail != null && airDropDetail.length) {

              let obj = {
                itemId: itemDetails['itemId'],
                mysteryboxId: itemDetails['mysteryboxId'],
                itemAmountERC20: itemDetails['itemAmountERC20'],
                userAddresses: airDropDetail,
                symbol: symbolData,
                tokenAddress: itemDetails["tokenAddERC20"],
              };

              airDrops.push(obj);

            }
          }
        }
        if (airDrops && airDrops.length) {
          setCsvData(airDrops);
        } else {
          setLoading(false)
          toast.error("No items.");
        }
        setLoading(false)
      } else {
        setLoading(false)
        toast.error("No items.");
      }



      setLoading(false)
    } else {
      setLoading(false)
      toast.warning('Their is some error with smartContract. Please try again after sometime.')
    }
  }
  useEffect(() => {
    if (Object.keys(dataToEdit).length > 0) {
      setEditModal(true)
    }
  }, [dataToEdit])
  const handleEdit = (res) => {
    setDataToEdit(res)
  }
  return (
    <>
      {loading && <Loader />}
      <Container fluid className="px-md-5 px-4">
        <h1 className="text-white  mb-5">Mystery Box Listing</h1>
        <Table className="text-white mystry_box_list">
          <thead>
            <tr>
              <th> Sr no.</th>
              {/* <th>Image</th> */}
              <th> Name</th>
              <th>Status</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {mysteryBoxes && mysteryBoxes.length ? (
              mysteryBoxes.map((res, ind) => {
                return (
                  <tr key={res._id}>
                    <td
                      onClick={() => {
                        history.push(`/mystery-boxes/${res._id}`);
                      }}
                    >
                      {ind + 1}
                    </td>

                    <td>{res.projectName}</td>
                    <td>{getClassName(res.create_status)}</td>
                    <td> 
                    {res && res.createdAt
                    ? moment(res.createdAt).format(
                      " DD MMM y ,LT"
                    )
                    : "-"}
                    </td>
                    <td>
                      {res && res.status === "upcoming" && (
                        <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                          <Button
                            type="button"
                            onClick={() => openCreateItem(res)}
                            variant="blue"
                            className="px-4"
                          >
                            Add
                          </Button>
                        </div>

                       )} 
                      <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                        <Button
                          type="button"
                          onClick={() => openViewModal(res)}
                          variant="blue"
                          className="px-4"
                        >
                          View
                        </Button>
                      </div>

                      {res && res.status !== "upcoming" && (
                        <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                          <Button
                            type="button"
                            onClick={() => exportCsvData(res)}
                            variant="blue"
                            className="px-4"
                          >
                            Export CSV
                          </Button>
                        </div>
                      )}

                      {res && res.status === "upcoming" && (
                        <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                          <Button
                            type="button"
                            onClick={() => setSmartContractItems(res)}
                            variant="blue"
                            className="px-4"
                          >
                            Set
                          </Button>
                        </div>
                      )}
                      {res && res.status === "upcoming" && (
                        <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                          <Button
                            type="button"
                            onClick={() => handleEdit(res)}
                            variant="blue"
                            className="px-4"
                          >
                            Edit
                          </Button>
                        </div>
                      )}
                      <CSVLink
                        data={csvData}
                        filename="data.csv"
                        className="hidden"
                        ref={inputElement}
                        target="_blank"
                      />
                    </td>

                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan="4"> No records Found.</td>
              </tr>
            )}
          </tbody>
        </Table>

        {
          pageCount && pageCount > 0 && (<ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />)
        }


        <AddMysteryBoxItem
          item={selectedItem && selectedItem}
          addItemBox={addItemBox}
          getMysteryBoxes={getMysteryBoxes}
          onCloseItemBox={onCloseItemBox}
          loading={loading}
          setLoading={setLoading}
        />
        <ViewMysteryBox
          onCloseModal={onCloseModal}
          getMysteryBoxes={getMysteryBoxes}
          showModal={showModal}
          loading={loading}
          setLoading={setLoading}
          mysteryDetails={mysteryDetails && mysteryDetails}
        />
        <EditModal
          onCloseModal={closeEditModal}
          dataToEdit={dataToEdit}
          showEditModal={editModal}
          loading={loading}
          setLoading={setLoading}
        />
      </Container>
    </>
  );
};
export default MystertBoxListing;
