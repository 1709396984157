import React, { useContext, useRef, useEffect, useState } from "react";

import { Container, Table } from "react-bootstrap";
import { createMethods } from "../../functions/create";

import { Web3Context } from "../../web3/contexts/web3Context";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
import { useHistory } from "react-router-dom";
import { Button, Col, Modal } from "react-bootstrap";
import * as Yup from "yup";

import { useFormik } from "formik";

const Banners = () => {
  const { networkDetails } = useContext(Web3Context);
  const history = useHistory();
  const inputRef = useRef(null);
  const [refreshData, setRefreshData] = useState(true);
  const [addUrl, setAddUrl] = useState(null);
  const [editUrl, setEditUrl] = useState('');

  const [id, setId] = useState(null);
  const [updateData, setUpdateData] = useState({});


  const [allBanners, setAllBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModel, setCreateModal] = useState(false);
  const [editModel, setEditModal] = useState(false);


  useEffect(() => {
    if (networkDetails && networkDetails.connected && refreshData) {
      if (networkDetails.isAdmin) {
        getBanners();
      } else {
        setLoading(false);
        return toast.warning("You are not a admin.", "Attention");
      }
    } else {
      setLoading(false);
    }
  }, [networkDetails, refreshData]);

  const handleAddBannerPic = async (event, type) => {
    if (networkDetails && networkDetails.connected) {
      if (event.target.files && event.target.files[0]) {
        if (event.target.files[0].name.match(/\.(jpeg|jpg|png|)$/)) {

          if (type == 'add') {
            formik.setFieldValue("image", event.target.files[0]);
          }
          if (type == 'edit') {
            formik1.setFieldValue("image", event.target.files[0]);
          }
          const formData = event.target.files[0];
          let uploadType = "image";
          setLoading(true);
          const response = await createMethods.uploadData(
            uploadType,
            formData,
            networkDetails.networkId
          );
          if (response) {
            if (type == 'add') {
              setAddUrl(response.data.data);
            }
            if (type == 'edit') {
              setEditUrl(response.data.data);
            }
            setLoading(false);
          } else {
            setLoading(false);
            console.log("error in uploading");
          }
        } else {
          setLoading(false);
          if (inputRef) {
            inputRef.current.value = "";
          }
          toast.error(
            "The file you are trying to upload is not in supported format, please try again"
          );
        }
      }
    } else {

    }
  };
  const getBanners = async () => {
    setLoading(true);
    let response = await createMethods.getBanners();
    if (response.data.success) {
      setLoading(false);
      setRefreshData(false);
      setAllBanners(response.data.data);
    } else {
      setLoading(false);
      return toast.warning("Error while Fetching.", "Attention");
    }
  };
  //
  const onCloseCreateModal = async () => {
    setCreateModal(false);
  }
  const onCloseUpdateModal = async () => {
    setEditModal(false);
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };


  const initialValues = {
    ref_url: '',
    image: "",
    fb_url: "",
    insta_url: "",
    youtube_url: "",
    linkedin_url: "",
    twitter_url: "",
  };
  const validationSchema = Yup.object().shape({
    image: Yup.string()
      .required("image is required"),
    ref_url: Yup.string()
      .required("Link is required"),
      fb_url: Yup.string(),
      insta_url: Yup.string(),
      youtube_url: Yup.string(),
      linkedin_url: Yup.string(),
      twitter_url: Yup.string()
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        if (networkDetails && networkDetails.connected) {


          setLoading(true);

          let obj = {
            image: addUrl,
            ref_url: values.ref_url,
            walletAddress: networkDetails.address,
            fb_url: values.fb_url ? values.fb_url : '',
            insta_url: values.insta_url ? values.insta_url : '',
            youtube_url: values.youtube_url ? values.youtube_url : '',
            linkedin_url: values.linkedin_url ? values.linkedin_url : '',
            twitter_url: values.twitter_url ? values.twitter_url : '',
          }

          const bannerResponse = await createMethods.setBannerImage(obj);

          if (bannerResponse.data.success) {
            setRefreshData(true);
            setLoading(false);
            resetForm();
            onCloseCreateModal()
            // window.location.reload();
            toast.success("Banner  added successfully");
          } else {
            setLoading(false);

            toast.error(
              "Their is some error while updating banner image, please try again"
            );
          }


        }



      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });

  const initialValues1 = {
    ref_url: '',
    image: "",

    fb_url: "",
    insta_url: "",
    youtube_url: "",
    linkedin_url: "",
    twitter_url: "",
  };
  const validationSchema1 = Yup.object().shape({
    image: Yup.string()
      .required("image is required"),
    ref_url: Yup.string()
      .required("Link is required"),
    fb_url: Yup.string(),
    insta_url: Yup.string(),
    youtube_url: Yup.string(),
    linkedin_url: Yup.string(),
    twitter_url: Yup.string()
  });
  const formik1 = useFormik({
    initialValues: initialValues1,
    enableReinitialize: true,
    validationSchema: validationSchema1,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        if (networkDetails && networkDetails.connected) {
          setLoading(true);

          let obj = {
            image: editUrl && editUrl != '' ? editUrl : updateData.image,
            id: updateData._id,
            ref_url: values.ref_url,
            fb_url: values.fb_url ? values.fb_url : '',
            insta_url: values.insta_url ? values.insta_url : '',
            youtube_url: values.youtube_url ? values.youtube_url : '',
            linkedin_url: values.linkedin_url ? values.linkedin_url : '',
            twitter_url: values.twitter_url ? values.twitter_url : '',

            walletAddress: networkDetails.address

          };
          const bannerResponse = await createMethods.editBanners(obj);

          if (bannerResponse.data.success) {
            setRefreshData(true);
            setLoading(false);
            onCloseUpdateModal()

            toast.success("Banner Updated successfully");
          } else {
            setLoading(false);

            toast.error(
              "Their is some error while updating banner image, please try again"
            );
          }

        }

      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });


  const setUpdateDataF = async (obj) => {
    setEditModal(true);
    setUpdateData(obj);

    formik1.setValues({
      'image': obj.image,
      'ref_url': obj.ref_url,
      fb_url: obj.fb_url ? obj.fb_url : '',
      insta_url: obj.insta_url ? obj.insta_url : '',
      youtube_url: obj.youtube_url ? obj.youtube_url : '',
      linkedin_url: obj.linkedin_url ? obj.linkedin_url : '',
      twitter_url: obj.twitter_url ? obj.twitter_url : '',
    })
  }

  const deleteBanner = async (obj) => {

    setLoading(true);

    obj = {
      id: obj._id,
      is_deleted: true
    }

    const bannerResponse = await createMethods.editBanners(obj);

    if (bannerResponse.data.success) {
      // setRefreshData(true);
      setLoading(false);
      setRefreshData(true);
      toast.success("Banner Deleted successfully");
    } else {
      setLoading(false);

      toast.error(
        "Their is some error while delete banner, please try again"
      );
    }

  }

  return (
    <>
      {console.log(loading, "loading0")}
      {loading && <Loader />}
      <Container fluid className="px-md-5 px-4">
        <h1 className="text-white  mb-5">Banners List</h1>
        <div className="re_uploadCoverBtn mb-3 float-right btn btn-blue  mr-3 mb-2">
          <input
            title=" "
            type="button"

            // type="file"
            // accept=".png, .jpg, .jpeg"
            // ref={inputRef}
            onClick={() => setCreateModal(true)}
          />
          Add Banner Image
        </div>
        <Table className="text-white">
          <thead>
            <tr>
              <th> Sr no.</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allBanners && allBanners.length ? (
              allBanners.map((res, ind) => {
                return (
                  <tr key={res._id}>
                    <td
                      onClick={() => {
                        history.push(`/detail/${res._id}`);
                      }}
                    >
                      {ind + 1}
                    </td>
                    <td>
                      {" "}
                      <img
                        style={{ maxWidth: "80px", maxHeight: "80px" }}
                        src={res.image}
                      ></img>
                    </td>
                    <td>
                      <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                        <input
                          title=" "
                          type="button"
                          accept=".png, .jpg, .jpeg"
                          onClick={() =>
                            setUpdateDataF(res)
                          }
                        />
                        Edit Banner
                      </div>
                      {
                        ' '
                      }
                      <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
                        <input
                          title=" "
                          type="button"
                          accept=".png, .jpg, .jpeg"
                          onClick={() =>
                            deleteBanner(res)
                          }
                        />
                        Delete Banner
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan="4"> No records Found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>


      {/* modal Buy */}
      <Modal show={createModel} onHide={onCloseCreateModal} size="" centered className="PR_modal"
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Create Banner</div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik.handleSubmit}>
              {/* <Col md={12}>
                <div className="h4 pb-3 ">Whitelist Address</div>
              </Col> */}
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="ref_url"
                    type="text"
                    placeholder="Refrence URL"
                    className={`form-control PR_input ${getInputClasses(
                      "ref_url"
                    )}`}
                    {...formik.getFieldProps("ref_url")}
                  />
                  {formik.touched.ref_url && formik.errors.ref_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.ref_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="fb_url"
                    type="text"
                    placeholder="Facebook URL"
                    className={`form-control PR_input ${getInputClasses(
                      "fb_url"
                    )}`}
                    {...formik.getFieldProps("fb_url")}
                  />
                  {formik.touched.fb_url && formik.errors.fb_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.fb_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="insta_url"
                    type="text"
                    placeholder="Instagram URL"
                    className={`form-control PR_input ${getInputClasses(
                      "insta_url"
                    )}`}
                    {...formik.getFieldProps("insta_url")}
                  />
                  {formik.touched.insta_url && formik.errors.insta_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.insta_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="youtube_url"
                    type="text"
                    placeholder="Youtube URL"
                    className={`form-control PR_input ${getInputClasses(
                      "youtube_url"
                    )}`}
                    {...formik.getFieldProps("youtube_url")}
                  />
                  {formik.touched.youtube_url && formik.errors.youtube_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.youtube_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="linkedin_url"
                    type="text"
                    placeholder="Linkedin URL"
                    className={`form-control PR_input ${getInputClasses(
                      "linkedin_url"
                    )}`}
                    {...formik.getFieldProps("linkedin_url")}
                  />
                  {formik.touched.linkedin_url && formik.errors.linkedin_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.linkedin_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="twitter_url"
                    type="text"
                    placeholder="twitter URL"
                    className={`form-control PR_input ${getInputClasses(
                      "twitter_url"
                    )}`}
                    {...formik.getFieldProps("twitter_url")}
                  />
                  {formik.touched.twitter_url && formik.errors.twitter_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.twitter_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} lg={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Banner Image
                  </label>
                  <div className="upload-btn-wrapper py-1 form-control PR_input">
                    <div className="text-white pr-3">
                      {formik.values.image !== ""
                        ? formik.values.image.name
                        : ""}
                    </div>
                    <input
                      type="file"
                      name="image"
                      onChange={(event) => handleAddBannerPic(event, 'add')}
                    />
                    <button className="btn">
                      <span>Upload</span>
                    </button>
                  </div>
                  {formik.errors.image ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.image}
                      </div>
                    </div>
                  ) : null}
                </div>

              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                // disabled={loading}
                >
                  {loading ? "...Adding" : "Add"}
                </Button>{" "}
                &nbsp; &nbsp;

              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>



      <Modal show={editModel} onHide={onCloseUpdateModal} size="" centered className="PR_modal"
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Update Banner</div>
        </Modal.Header>

        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik1.handleSubmit}>
              {/* <Col md={12}>
                <div className="h4 pb-3 ">Whitelist Address</div>
              </Col> */}
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="ref_url"
                    type="text"
                    placeholder="Refrence URL"
                    className={`form-control PR_input ${getInputClasses(
                      "ref_url"
                    )}`}
                    {...formik1.getFieldProps("ref_url")}
                  />
                  {formik1.touched.ref_url && formik1.errors.ref_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.ref_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="fb_url"
                    type="text"
                    placeholder="Facebook URL"
                    className={`form-control PR_input ${getInputClasses(
                      "fb_url"
                    )}`}
                    {...formik1.getFieldProps("fb_url")}
                  />
                  {formik1.touched.fb_url && formik1.errors.fb_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.fb_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="insta_url"
                    type="text"
                    placeholder="Instagram URL"
                    className={`form-control PR_input ${getInputClasses(
                      "insta_url"
                    )}`}
                    {...formik1.getFieldProps("insta_url")}
                  />
                  {formik1.touched.insta_url && formik1.errors.insta_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.insta_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="youtube_url"
                    type="text"
                    placeholder="Youtube URL"
                    className={`form-control PR_input ${getInputClasses(
                      "youtube_url"
                    )}`}
                    {...formik1.getFieldProps("youtube_url")}
                  />
                  {formik1.touched.youtube_url && formik1.errors.youtube_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.youtube_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="linkedin_url"
                    type="text"
                    placeholder="Linkedin URL"
                    className={`form-control PR_input ${getInputClasses(
                      "linkedin_url"
                    )}`}
                    {...formik1.getFieldProps("linkedin_url")}
                  />
                  {formik1.touched.linkedin_url && formik1.errors.linkedin_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.linkedin_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="twitter_url"
                    type="text"
                    placeholder="twitter URL"
                    className={`form-control PR_input ${getInputClasses(
                      "twitter_url"
                    )}`}
                    {...formik1.getFieldProps("twitter_url")}
                  />
                  {formik1.touched.twitter_url && formik1.errors.twitter_url ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.twitter_url}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} lg={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Banner Image
                  </label>
                  <div className="upload-btn-wrapper py-1 form-control PR_input">
                    <div className="text-white pr-3">
                      {formik1.values.image !== ""
                        ? formik1.values.image.name
                        : ""}
                    </div>
                    <input
                      type="file"
                      name="image"
                      onChange={(event) => handleAddBannerPic(event, 'edit')}
                    />
                    <button className="btn">
                      <span>Upload</span>
                    </button>
                  </div>
                  {formik1.errors.image ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik1.errors.image}
                      </div>
                    </div>
                  ) : null}
                </div>

              </Col>
              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                // disabled={loading}
                >
                  {loading ? "...Updating" : "Update"}
                </Button>{" "}
                &nbsp; &nbsp;

              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Banners;
