import { enviornment } from "../constants/constants";
import axios from "axios";
import Web3 from "web3";
import { toast } from "react-toastify";

// function createItem( mysteryInstance,
//   userAccount,
//   mysterySC_id,
//   item_category,
//   isERC20,
//   isERC721,
//   isAirdrop,
//   item_amount,
//   item_value,
//   item_count,
//   erc20_address,
//   erc721_address,
//   NFT_idARY,
//   APIobj) {
//   return new Promise(async (resolve, reject) => {
//     console.log('-----------ary------', APIobj);

//     mysteryInstance.methods
//       .setItem(
//         mysterySC_id,
//         item_category,
//         isERC20,
//         isERC721,
//         isAirdrop,
//         item_amount,
//         item_value,
//         item_count,
//         erc20_address,
//         erc721_address,
//         NFT_idARY,
//       )
//       .send({ from: userAccount })
//       .on("transactionHash", async function (transactionHash) {
//         APIobj.create_transaction_hash = transactionHash;
//         const headers = {
//           "Content-Type": "application/json",
//         };
//         const response = await axios.post(
//           enviornment.URL + "/api/create-item",
//           APIobj,
//           { headers }
//         );
//         if (response) {
//           resolve(response);
//         } else {
//           reject({ error: "Their is some error with server" });
//         }
//       }) .catch((error) => {
//         console.log('-----2----er')

//         reject(error);
//       });

//   });
// }

function createItem(APIobj) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(
        enviornment.URL + "/api/create-item",
        APIobj,
        { headers }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function setSubscribe(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "applicatoin/json",
        Authorization: `anystring ${enviornment.APIKEY}`,
      };
      const response = await axios.post(
        enviornment.SUBSCRIBEURL,
        payload,
        { headers }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function createMysteryBox(
  mysteryInstance,
  userAccount,
  name,
  claim_time,
  start_time,
  end_time,
  APIobj
) {
  return new Promise(async (resolve, reject) => {
    // try {


    mysteryInstance.methods
      .createmysterybox(name, claim_time, start_time, end_time)
      .send({ from: userAccount })
      .on("transactionHash", async function (transactionHash) {
        APIobj.create_transaction_hash = transactionHash;
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await axios.post(
          enviornment.URL + "/api/create-mystery-box",
          APIobj,
          { headers }
        );
        if (response) {
          resolve(response);
        } else {
          reject({ error: "Their is some error with server" });
        }
      })
      .catch((error) => {
        console.log("-----2----er");

        reject(error);
      });

    // } catch (err) {
    //   reject(err);
    // }
  });
}

function getMysteryBoxes(obj) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(enviornment.URL + `/api/getMysteries`,obj, {
        headers,
      });
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getMysteryListing() {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + `/api/getMysteriesList`,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function removeItem(itemId) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.delete(
        enviornment.URL + `/api/remove-item/${itemId}`,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getMystery(mysteryId) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.get(
        enviornment.URL + `/api/getMystery/${mysteryId}`,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

function getMysteryBoxDetailsSC(Instance, mysteryId, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .mysteryBoxes(mysteryId)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching mystery box details" });
          toast.error("Error in fetching mystery box details.");
        }
        if (data) {
          resolve(data);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}

function loadTotalTickets(Instance, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .totalticket(userAccount)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching total tickets" });
          toast.error("Error in fetching total tickets.");
        }
        if (data) {
          resolve(data);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}

function getOpenBoxCount(Instance, mysteryId, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .openBoxCount(mysteryId)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching open box count." });
          toast.error("Error in fetching open box count.");
        }
        if (data) {
          resolve(data);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}

function getClaimBoxCount(Instance, mysteryId, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .claimBoxCount(mysteryId)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching claim box count. " });
          toast.error("Error in fetching claim box count.");
        }
        if (data) {
          resolve(data);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}
function getUserTreasuryBoxes(Instance, mysteryId, userAccount) {
  return new Promise(async (resolve, reject) => {
    // try {
    await Instance.methods
      .UserTreasuryBoxes(userAccount, mysteryId)
      .call({ from: userAccount }, (err, data) => {
        if (err) {
          reject({ error: "Error in fetching treasury boxes" });
          toast.error("Error in fetching treasury boxes.");
        }
        if (data) {
          resolve(data);
        }
      });
    // } catch (error) {
    //   reject({ error: error });
    // }
  });
}


function claimTreasuryBox(
  mysteryInstance,
  userAccount,
  id
) {
  return new Promise(async (resolve, reject) => {
    // try {
    mysteryInstance.methods
      .claimTreasuryBox(id)
      .send({ from: userAccount })
      .on("transactionHash", async function (transactionHash) {

        if (transactionHash) {
          resolve(transactionHash);
        } else {
          reject({ error: "Their is some error with server" });
        }
      })
    // } catch (err) {
    //   reject(err);
    // }
  });
}
function updateMysteryBox(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      };
      const response = await axios.post(
        enviornment.URL + `/api/update-mysteryBox`,
        data,
        {
          headers,
        }
      );
      if (response) {
        resolve(response);
      } else {
        reject({ error: "Their is some error with server" });
      }
    } catch (err) {
      console.log(err, "errr");
      reject(err);
    }
  });
}

export const createMysteryMethods = {
  createItem,
  setSubscribe,
  createMysteryBox,
  getMysteryBoxes,
  removeItem,
  getMysteryListing,
  getMystery,
  getMysteryBoxDetailsSC,
  loadTotalTickets,
  getOpenBoxCount,
  getClaimBoxCount,
  getUserTreasuryBoxes,
  claimTreasuryBox,
  updateMysteryBox
};
