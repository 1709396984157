import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Col, Row, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Web3Context } from "../../web3/contexts/web3Context";
import { createMysteryMethods } from "../../functions/createMystery";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";
import { enviornment } from "../../constants/constants";
import { createMethods } from "../../functions/create";
// NOTE =  (tokenAmount + max TUSD )/playshae percentage
const Create = () => {
  const { networkDetails } = useContext(Web3Context);
  const [tokenUrl, setTokenUrl] = useState(null);
  const [tempProjectBanner, setTempProjectBanner] = useState(null)
  const [tempTokenUrl, setTempTokenUrl] = useState(null)
  const [projectBanner, setProjectBanner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lock_Liquidity, setlockLiquidity] = useState("");

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    if (networkDetails && networkDetails.connected) {
      console.log('---------connected-', networkDetails)
      // getTotalTicketAPI();
      let a = networkDetails.chainData && networkDetails.chainData != undefined ? networkDetails.chainData.network_type : 'other';

      formik.setFieldValue('network_type', a)
      // formik.setFieldValue('network_type', a);
    }
  }, [networkDetails])


  // initial values for form
  const initialFormValues = {
    network_type: "",
    projectName: "",
    projectBanner: "",
    claim_time: '',
    projectLogo: "",
    projectTitle: "",
    start_date: "",
    end_date: "",
    projectShortDesc: "",
    projectLongDesc: "",
  };

  //   validations for form
  const formSchema = Yup.object().shape({
    network_type: Yup.string()
      .nullable()
      .required("This field is required."),
    projectName: Yup.string().required("Project name is required"),
    projectBanner: Yup.mixed().required("Please upload the project banner"),
    projectLogo: Yup.mixed().required("Please upload the project icon/logo"),
    projectTitle: Yup.string().required("Project Subtitle is required"),
    start_date: Yup.string().required("Start date and time is required"),
    end_date: Yup.string().required("End date and time is required"),
    claim_time: Yup.number().min(60, "Claim time must be greater then 60 seconds").required("Claim time is required"),
    projectShortDesc: Yup.string().required("Project short description is required"),
    projectLongDesc: Yup.mixed().required("Peoject long description is required"),
  });

  useEffect(() => {
    return () => {
      setTempTokenUrl(null)
      setTempProjectBanner(null)
    }
  }, [])

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      try {

        if (networkDetails.connected) {
          if (new Date(values.end_date) > new Date(values.start_date)) {
            let SCDetails = await getSCBalanceDetails();
            let data = values;
            let obj = {
              network_type: data.network_type,
              projectName: data.projectName,
              projectLogo: tokenUrl,
              projectTitle: data.projectTitle,
              projectShortDesc: data.projectShortDesc.replace(/(?:\r\n|\r|\n)/g, '<br>'),
              projectLongDesc: data.projectLongDesc.replace(/(?:\r\n|\r|\n)/g, '<br>'),  
              start_date: parseInt(data.start_date / 1000),
              end_date: parseInt(data.end_date / 1000),
              projectBanner: projectBanner,
              walletAddress: networkDetails.address,
              create_transaction_hash: '',
              claim_time: data.claim_time,
            };
            setLoading(true);
            await createMysteryMethods
              .createMysteryBox(
                SCDetails.mysteryInstance,
                networkDetails.address,
                obj.projectName,
                obj.claim_time,
                obj.start_date,
                obj.end_date,
                obj
              )
              .then((resApprove) => {
                toast.info(
                  "Transaction confirmed."
                );
                setLoading(false);
                resetForm();
                setTempTokenUrl(null)
                setTempProjectBanner(null)
                window.location.reload()
                // history.push("/dashboard");
              })
              .catch((er) => {
                if (er && er.code) {
                  toast.error(er.message);
                  setLoading(false);
                }
              });

          } else {
            setFieldError(
              "end_date",
              "End date/time should be older than start date/time"
            );
            // setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.warning("You have to connect first !", "Attention");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });


  const onSelectDocument = async (event, formik) => {
    formik.setTouched({ ...formik.touched, "projectLogo": true });
    if (networkDetails && networkDetails.connected) {
      if (event.target.files && event.target.files[0]) {
        if (event.target.files[0].name.match(/\.(jpeg|jpg|png|)$/)) {
          formik.setFieldValue("projectLogo", event.target.files[0]);
          setTempTokenUrl(event.target.files[0])
          const formData = event.target.files[0];
          let uploadType = "image";
          setLoading(true);
          const response = await createMethods.uploadData(
            uploadType,
            formData,
            networkDetails.networkId
          );
          if (response) {
            setTokenUrl(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            console.log("error in uploading");
          }
        } else {
          formik.setFieldError("projectLogo", "format not supported");
          setLoading(false);
          toast.error(
            "The file you are trying to upload is not in supported format, please try again"
          );
        }
      }
    } else {
      return toast.warning("You have to connect first !", "Attention");
    }
  };

  const onSelectBanner = async (event, formik) => {
    formik.setTouched({ ...formik.touched, "projectBanner": true });
    if (networkDetails && networkDetails.connected) {
      if (event.target.files && event.target.files[0]) {
        if (event.target.files[0].name.match(/\.(jpeg|jpg|png|)$/)) {
          formik.setFieldValue("projectBanner", event.target.files[0]);
          setTempProjectBanner(event.target.files[0])
          const formData = event.target.files[0];
          let uploadType = "image";
          setLoading(true);
          const response = await createMethods.uploadData(
            uploadType,
            formData,
            networkDetails.networkId
          );
          if (response) {
            setProjectBanner(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            console.log("error in uploading");
          }
        } else {
          formik.setFieldError("projectBanner", "format not supported");
          setLoading(false);
          toast.error(
            "The file you are trying to upload is not in supported format, please try again"
          );
        }
      }
    } else {
      return toast.warning("You have to connect first !", "Attention");
    }
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const getSCBalanceDetails = async () => {
    let obj = {

      mysteryInstance: "",
    };

    let mysteryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].mysterySC,
      enviornment[networkDetails.networkId].mysteryABI
    );

    if (
      mysteryInstance &&
      mysteryInstance != undefined
    ) {
      obj.mysteryInstance = mysteryInstance;

      return obj;
    }
  };
  const showToaster = (e) => {
    e.preventDefault();
    toast.warning("You have to connect first !", "Attention");
  };
  return (
    <>
      {loading && <Loader />}
      <Container className="mb-5">
        <Row className="justify-content-center">
          <Col md={12} lg={8}>
            <Form
              className="row"
              onSubmit={(e) =>
                networkDetails.connected
                  ? formik.handleSubmit(e)
                  : showToaster(e)
              }
            >
              <Col md={12}>
                <div className="fs-20px fw-600 border-bottom-yellow pt-4 pb-2 text-yellow mb-4">
                  1 - Project Information
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Network Type
                  </label>
                  <select
                    disabled
                    style={{ background: "#1e2235" }}
                    name="network_type"
                    className="form-control PR_input re_input"
                    // defaultValue={network}  
                    {...formik.getFieldProps("network_type")}
                  // defaultValue={network} 
                  >
                    <option value="" >
                      Select one
                    </option>
                    <option key="binance" value="binance">
                      Binance Smart Chain
                    </option>
                    <option key="ethereum" value="ethereum">
                      Ethereum Network
                    </option>
                    <option key="harmony" value="harmony">
                      Harmony
                    </option>
                    <option key="polygon" value="polygon">
                      Polygon
                    </option>
                    <option key="other" value="other">
                      Please select valid network
                    </option>
                  </select>
                  {formik.touched.network_type &&
                    formik.errors.network_type ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block  text-danger">
                        {formik.errors.network_type}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Name
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "projectName"
                    )}`}
                    name="projectName"
                    {...formik.getFieldProps("projectName")}
                  />
                  {formik.touched.projectName && formik.errors.projectName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectName}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Start Date and Time
                  </label>
                  <DatePicker
                    showTimeSelect
                    filterTime={filterPassedTime}
                    timeIntervals={10}
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={`form-control PR_input ${getInputClasses(
                      "start_date"
                    )}`}
                    selected={formik.values.start_date}
                    minDate={new Date()}
                    name="start_date"
                    // onChangeRaw={handleDateChangeRaw}
                    onChange={(date) => {
                      if (date) {
                        formik.setFieldValue("start_date", date.getTime());
                      } else {
                        formik.setFieldValue("start_date", "");
                      }
                    }}
                    selectsStart
                    startDate={formik.values.start_date}
                    endDate={formik.values.end_date}
                    placeholderText="Start Date"
                    showYearDropdown={true}
                    showMonthDropdown={true}
                  />
                  {formik.touched.start_date && formik.errors.start_date ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.start_date}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    End Date and Time
                  </label>
                  <DatePicker
                    showTimeSelect
                    filterTime={filterPassedTime}
                    timeIntervals={10}
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className={`form-control PR_input ${getInputClasses(
                      "end_date"
                    )}`}
                    selected={formik.values.end_date}
                    name="end_date"
                    minDate={formik.values.start_date}
                    // onChangeRaw={handleDateChangeRaw}
                    onChange={(date) => {
                      if (date) {
                        formik.setFieldValue("end_date", date.getTime());
                      } else {
                        formik.setFieldValue("end_date", "");
                      }
                    }}
                    selectsEnd
                    startDate={formik.values.start_date}
                    endDate={formik.values.end_date}
                    placeholderText="End Date"
                    showYearDropdown={true}
                    showMonthDropdown={true}
                  />
                  {formik.touched.end_date && formik.errors.end_date ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.end_date}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Icon / Logo
                  </label>
                  <div className="upload-btn-wrapper py-1 form-control PR_input">
                    <div className="text-white pr-3">
                      {tempTokenUrl !== "" && tempTokenUrl !== null
                        ? tempTokenUrl.name && tempTokenUrl.name.length > 20 ? tempTokenUrl.name.substring(0, 20) + "..." : tempTokenUrl.name
                        : ""}
                    </div>
                    <input
                      type="file"
                      name="projectLogo"
                      onChange={(event) => onSelectDocument(event, formik)}
                    />
                    <button className="btn">
                      <span>Upload</span>
                    </button>
                  </div>
                  {formik.touched.projectLogo && formik.errors.projectLogo ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectLogo}
                      </div>
                    </div>
                  ) : null}
                  <p className="fs-14px text-white opacity-70 text-left">Image size must be 72*72</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Banner
                  </label>
                  <div className="upload-btn-wrapper py-1 form-control PR_input">
                    <div className="text-white pr-3">
                      {tempProjectBanner !== "" && tempProjectBanner !== null
                        ? tempProjectBanner.name && tempProjectBanner.name.length > 20 ? tempProjectBanner.name.substring(0, 20) + "..." : tempProjectBanner.name
                        : ""}
                    </div>
                    <input
                      type="file"
                      name="projectBanner"
                      onChange={(event) => onSelectBanner(event, formik)}
                    />
                    <button className="btn">
                      <span>Upload</span>
                    </button>
                  </div>
                  {formik.touched.projectBanner && formik.errors.projectBanner ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectBanner}
                      </div>
                    </div>
                  ) : null}
                  <p className="fs-14px text-white opacity-70 text-right">Image size must be 312*212</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Subtitle
                  </label>
                  <input
                    type="text"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "projectTitle"
                    )}`}
                    name="projectTitle"
                    {...formik.getFieldProps("projectTitle")}
                  />
                  {formik.touched.projectTitle && formik.errors.projectTitle ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectTitle}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Claim Time (In seconds)
                  </label>
                  <input
                    type="number"
                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "claim_time"
                    )}`}
                    name="claim_time"
                    {...formik.getFieldProps("claim_time")}
                  />
                  {formik.touched.claim_time && formik.errors.claim_time ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.claim_time}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Short Description
                  </label>

                  <textarea style={{whiteSpace: 'pre-wrap'}}
                    name="projectShortDesc"
                    rows={4}
                    placeholder="Project Short Description"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "projectShortDesc"
                    )}`}
                    {...formik.getFieldProps("projectShortDesc")}
                  ></textarea>
                  {formik.touched.projectShortDesc && formik.errors.projectShortDesc ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectShortDesc}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group">
                  <label className="fs-14px text-white opacity-70">
                    Project Long Description
                  </label>

                  <textarea
                    name="projectLongDesc"
                    rows={4}
                    placeholder="Project Long Description"
                    className={`form-control PR_input re_inputRouded ${getInputClasses(
                      "projectLongDesc"
                    )}`}
                    {...formik.getFieldProps("projectLongDesc")}
                  ></textarea>
                  {formik.touched.projectLongDesc && formik.errors.projectLongDesc ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.projectLongDesc}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} className="text-right mt-10">
                <button
                  className="btn-blue btn w-150px "
                  type="submit"
                >
                  <span>Create</span>
                </button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Create;
