import React from "react";

const Loader = () => {
  return (
    <div className="fridge_Loader_main d-flex align-items-center justify-content-center">
      <div className="fridge_Loader"></div>
    </div>
  );
};
export default Loader;
