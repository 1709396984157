import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Loader from "../loader/loader";
import { SaleItem } from "../../Components/SaleItem";
import { SecTitle } from "../../Components/Typografy";
import { createMethods } from "../../functions/create";
import { createMysteryMethods } from "../../functions/createMystery";
import { Web3Context } from "../../web3/contexts/web3Context";
import { TLSlider } from "../../Components/TLSlider";
// import { TLSlider2 } from "../../Components/TLSlider2";

import wishlistlight from "./../../assets/images/wishlist_light.png";

import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

const TokenSales = () => {
  const { networkDetails } = useContext(Web3Context);
  const history = useHistory();

  const [allCampaignsData, setAllCampaignsData] = useState([]);
  const [onGoingCampaigns, setOnGoingCampaigns] = useState([]);
  const [upcomingCampaigns, setUpcomingCampaigns] = useState([]);
  const [completedCampaigns, setcompletedCampaigns] = useState([]);
  const [allBannersData, setAllBannersData] = useState([]);
  const [mysteryListData, setMysteryListData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [onGoingMysteryListData, setOnGoingMysteryListData] = useState([]);
  const [upcomingMysteryListData, setUpcomingMysteryListData] = useState([]);
  const [completedMysteryListData, setCompletedMysteryListData] = useState([]);

  useEffect(() => {
    // if (networkDetails && networkDetails.connected) {
    getCampaigns();
    getMysteryList();
    // }
  }, []);

  const getCampaigns = async () => {
    // setLoading(true);
    let response = await createMethods.getCampignsDashboard();
    if (response.data.success) {
      setAllCampaignsData(response.data.data);
      setAllBannersData(response.data.bannerData);

      // setLoading(false);
    }
  };

  const getMysteryList = async () => {
    setLoading(true);
    let response = await createMysteryMethods.getMysteryListing();
    if (response.data.success) {
      setMysteryListData(response.data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (allCampaignsData && allCampaignsData.length) {
      const getCompletedCampaigns = allCampaignsData.filter(
        (campaign) => campaign.status === "completed"
      );
      const getOngoingCampaigns = allCampaignsData.filter(
        (campaign) => campaign.status === "ongoing"
      );
      const getUpcomingCampaigns = allCampaignsData.filter(
        (campaign) => campaign.status === "upcoming"
      );
      setcompletedCampaigns(getCompletedCampaigns);
      setOnGoingCampaigns(getOngoingCampaigns);
      setUpcomingCampaigns(getUpcomingCampaigns);
    }
  }, [allCampaignsData]);

  useEffect(() => {
    if (mysteryListData && mysteryListData.length) {
      const getCompletedMysteryListData = mysteryListData.filter(
        (campaign) => campaign.status === "completed"
      );
      const getOngoingMysteryListData = mysteryListData.filter(
        (campaign) => campaign.status === "ongoing"
      );
      const getUpcomingMysteryListData = mysteryListData.filter(
        (campaign) => campaign.status === "upcoming"
      );
      setCompletedMysteryListData(getCompletedMysteryListData);
      setOnGoingMysteryListData(getOngoingMysteryListData);
      setUpcomingMysteryListData(getUpcomingMysteryListData);
    }
  }, [mysteryListData]);

  useEffect(() => {
    return () => {
      setcompletedCampaigns([]);
      setOnGoingCampaigns([]);
      setUpcomingCampaigns([]);

      setCompletedMysteryListData([]);
      setOnGoingMysteryListData([]);
      setUpcomingMysteryListData([]);
    };
  }, []);


  const handleClick = (item) => {
    console.log(item, 'itemitem')
    if (item && item.itemDetails && item.itemDetails.length > 0) {

      const setItems = item.itemDetails.filter((items) => items.set_status && items.set_status !== undefined && items.set_status === 1)
      if (setItems.length > 0) {
        history.push(`/mystery-boxes/${item._id}`)
      } else {
        toast.warning('No items are set in this mysterybox.')
      }
    }
    else {
      toast.warning('No items are present in this mysterybox.')
    }

  }

  const handleCompletedClick = (item) => {

    if (item && item.itemDetails && item.itemDetails.length > 0) {
      const setItems = item.itemDetails.filter((items) => items.set_status && items.set_status !== undefined && items.set_status === 1)
      if (setItems.length > 0) {
        history.push(`/mystery-boxes/${item._id}`)
      } else {
        toast.warning(`No items are set in this mysterybox.`)
      }
    }
    else {
      toast.warning('No items are present in this mysterybox.')
    }

  }


  return (
    <>
      {loading && <Loader />}
      <Container fluid className="px-md-5 px-4">
        <div className="mt-md-5 mt-4">
          {allBannersData && allBannersData.length && (
            <TLSlider>
              {allBannersData &&
                allBannersData.length &&
                allBannersData.map((item, ind) => {
                  return (
                    <div className="PR_slide" key={ind}>
                      <img src={item.image} alt="" />
                      <a
                        href={item.ref_url}
                        className="btn "
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Read More
                      </a>
                    </div>
                  );
                })}
            </TLSlider>
          )}
        </div>
        <div className="mt-md-5 mt-4" name="test1">
          <SecTitle>On-Going </SecTitle>
          <Row>
            {onGoingCampaigns && onGoingCampaigns.length ? (
              onGoingCampaigns.map((item, ind) => {
                return (
                  <Col lg={4} md={6} key={item._id}>
                    <SaleItem item={item} />
                  </Col>
                );
              })
            ) : (
              <div className="col text-white py-5 fs-14px">
                No Campaigns are currently available.
              </div>
            )}
          </Row>
        </div>
        <div className="mt-md-5 mt-4" name="test2">
          <SecTitle>Upcoming</SecTitle>
          <Row>
            {upcomingCampaigns && upcomingCampaigns.length ? (
              upcomingCampaigns.map((item, ind) => {
                return (
                  <Col lg={4} md={6} key={item._id}>
                    <SaleItem item={item} />
                  </Col>
                );
              })
            ) : (
              <div className="col text-white py-5 fs-14px">
                No Campaigns are currently available.
              </div>
            )}
          </Row>
        </div>
        <div className="mt-md-5 mt-4" name="test3">
          <SecTitle>Completed</SecTitle>
          <Row>
            {completedCampaigns && completedCampaigns.length ? (
              completedCampaigns.map((item, ind) => {
                return (
                  <Col lg={4} md={6} key={item._id}>
                    <SaleItem item={item} />
                  </Col>
                );
              })
            ) : (

              <div className="col text-white py-5 fs-14px">
                No Campaigns are currently available.
              </div>
            )}
          </Row>
        </div>


        <div className="mt-md-5 mt-4" name="test3">
          <div className="text-white fs-xl-32px fw-500 pb-4">
            Upcoming MysteryBoxes
          </div>
          <Row>
            {upcomingMysteryListData && upcomingMysteryListData.length ? (
              upcomingMysteryListData.map((item, ind) => {
                return (
                  <Col lg={4} md={6} key={item._id}>
                    <div
                      className="PR_saleItemBox"
                      onClick={() => history.push(`/mystery-boxes/${item._id}`)}
                    >
                      <div className="PR_img">
                        <img src={item.projectLogo} alt="" />
                      </div>
                      <div className="text-white fs-xl-20px fs-16px fw-700 text-truncate text-center my-4">
                        {item.projectName}
                      </div>
                      <div className="text-center mb-3">
                        <button
                          type="button"
                          className="px-4 text-uppercase btn btn-blue w-250px h-55px"
                        >
                          JOin now
                        </button>
                      </div>
                      {/* <div className="wishlist">
                        <a href="#">
                          <img src={wishlistlight} alt="" />
                        </a>
                      </div> */}
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className="col text-white py-5 fs-14px">
                No Mystery Box are currently available.
              </div>
            )}
          </Row>
        </div>

        <div className="mt-md-5 mt-4" name="test3">
          <div className="text-white fs-xl-32px fw-500 pb-4">
            On-Going MysteryBoxes
          </div>
          <Row>
            {onGoingMysteryListData && onGoingMysteryListData.length ? (
              onGoingMysteryListData.map((item, ind) => {
                return (
                  <Col lg={4} md={6} key={item._id}>
                    <div
                      className="PR_saleItemBox"
                      onClick={() => handleClick(item)}
                    >
                      <div className="PR_img">
                        <img src={item.projectLogo} alt="" />
                      </div>
                      <div className="text-white fs-xl-20px fs-16px fw-700 text-truncate text-center my-4">
                        {item.projectName}
                      </div>
                      <div className="text-center mb-3">
                        <button
                          type="button"
                          className="px-4 text-uppercase btn btn-blue w-250px h-55px"
                        >
                          JOin now
                        </button>
                      </div>
                      <div className="wishlist">
                        {/* <a href="#">
                          <img src={wishlistlight} alt="" />
                        </a> */}
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className="col text-white py-5 fs-14px">
                No Mystery Box are currently available.
              </div>
            )}
          </Row>
        </div>


        <div className="mt-md-5 mt-4" name="test3">
          <div className="text-white fs-xl-32px fw-500 pb-4">
            Completed MysteryBoxes
          </div>
          <Row>



            {completedMysteryListData && completedMysteryListData.length ? (
              completedMysteryListData.map((item, ind) => {
                return (
                  <Col lg={4} md={6} key={item._id}>
                    <div className="PR_saleItemBox mb-4 overflow-hidden text-break fix_height" onClick={() => handleCompletedClick(item)}>
                      <div className="igos_ended d-flex align-items-center justify-content-between">
                        <div className="PR_img">
                          <img src={item.projectLogo} alt="" />
                        </div>
                        <div className="fs-16px mr-auto ml-4">
                          <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase text-break">
                            {item.projectName}
                          </h3>
                          <p className="mb-1 text-truncate" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'pre-wrap' }}>  {item.projectTitle}</p>
                          {/* {item.projectTitle} */}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className="col text-white py-5 fs-14px">
                No Mystery Box are currently available.
              </div>
            )}
          </Row>
        </div>

        {/*         
        <div className="mt-md-5 mt-4" name="test3">
          <div className="text-white fs-xl-32px fw-500 pb-4">Ended IGOs</div>
          <Row>
            <Col lg={4} md={6}>
              <div className="PR_saleItemBox mb-4">
                <div className="igos_ended d-flex align-items-center justify-content-between">
                  <div className="PR_img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="fs-16px max-w-150px mr-auto ml-4">
                    <p className="mb-1">By Dinox</p>
                    <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase">
                      repurpose standar
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="PR_saleItemBox">
                <div className="igos_ended d-flex align-items-center justify-content-between">
                  <div className="PR_img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="fs-16px max-w-150px mr-auto ml-4">
                    <p className="mb-1">By Dinox</p>
                    <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase">
                      aggregate stand alone
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="PR_saleItemBox">
                <div className="igos_ended d-flex align-items-center justify-content-between">
                  <div className="PR_img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="fs-16px max-w-150px mr-auto ml-4">
                    <p className="mb-1">By Dinox</p>
                    <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase">
                      portals vis-a-vis.
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="PR_saleItemBox">
                <div className="igos_ended d-flex align-items-center justify-content-between">
                  <div className="PR_img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="fs-16px max-w-150px mr-auto ml-4">
                    <p className="mb-1">By Dinox</p>
                    <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase">
                      redefine enterprise
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="PR_saleItemBox">
                <div className="igos_ended d-flex align-items-center justify-content-between">
                  <div className="PR_img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="fs-16px max-w-150px mr-auto ml-4">
                    <p className="mb-1">By Dinox</p>
                    <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase">
                      foster resource
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="PR_saleItemBox">
                <div className="igos_ended d-flex align-items-center justify-content-between">
                  <div className="PR_img">
                    <img src={img2} alt="" />
                  </div>
                  <div className="fs-16px max-w-105px mr-auto ml-4">
                    <p className="mb-1">By Dinox</p>
                    <h3 className="text-white fs-xl-20px fs-16px fw-500  text-center text-uppercase">
                      Project Seed
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div> */}


      </Container>


      <footer>
        <Container className="text-center">
          <div className="stay_touch">
            Stay In Touch
          </div>
          <div className="join_team">
            Get latest PlayShare updates
          </div>
          <div className="creditby">
            <p className="text-white fs-xl-20px fs-16px fw-400  ">Synergistically customize high-payoff strategic theme areas before excellent growth strategies. Globally integrate diverse metrics whereas.</p>
          </div>
          <div className="newslatter">
            <div className="row d-flex justify-content-center align-items-center rows">
              <div className="col-12">

                <div className="mx-5">
                  <div className="input-group mb-3 mt-4">
                    <input type="text" className="form-control" placeholder="Enter Your Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <button className="btn btn-success border-rad" type="button" id="button-addon2">I’M IN</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Container>
        <Container className="text-center">
          <div className="copy_right">
            <p>© Copyright reBaked 2021. All rights reserved.</p>
          </div>
        </Container>

      </footer>

    </>
  );
};
export default TokenSales;
