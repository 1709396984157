import React, { useEffect, useState, useCallback, useContext } from "react";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Icon from "../../Components/Icon";
import RSlider from "../../Components/RangeSlider/Slider";
import img1 from "./../../assets/images/logo1.png";
import img2 from "./../../assets/images/hero_banner.png";
import giftBox from "./../../assets/images/giftBox.png";
import igo_allocation from "./../../assets/images/igo_allocation.png";
import prem_nft from "./../../assets/images/prem_nft.png";
import igo_nft from "./../../assets/images/igo_nft.png";
import img3 from "./../../assets/images/usdt.png";
import binance from "./../../assets/images/binance.png";
import Loader from "../loader/loader";
import inverntory_1 from "../../assets/images/inverntory_1.png";
import cart from "../../assets/images/cart.png";
import box_golden from "../../assets/images/box_golden.png";
import box_golder_open from "../../assets/images/box_golder_open.png";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../web3/contexts/web3Context";
import { createMysteryMethods } from "../../functions/createMystery";
import { toast } from "react-toastify";
import { enviornment } from "../../constants/constants";
import Countdown from "react-countdown";
import { createMethods } from "../../functions/create";
import DetailTimer from "../../Components/MysteryDetailTimer";
import { Mystryboxslider } from "../../Components/MystryboxSlider";
import { useHistory } from "react-router-dom";
const domain = [0, 100];

const MysteryBoxes = () => {
  const { networkDetails } = useContext(Web3Context);
  const [instance, setInstance] = useState(null);
  const { mysteryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState([]);
  const [mysteryDetailsSc, setMysteryDetailsSc] = useState({});
  const [mysteryData, setMysteryData] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [allMysteryItems, setAllMysteryItems] = useState([])

  // const Countdown = () => {
  //     var countDownDate = new Date("Jan 13, 2022 15:37:25").getTime();

  //     // Update the count down every 1 second
  //     var x = setInterval(function () {

  //         // Get today's date and time
  //         var now = new Date().getTime();

  //         // Find the distance between now and the count down date
  //         var distance = countDownDate - now;

  //         // Time calculations for days, hours, minutes and seconds
  //         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //         var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //         // Display the result in the element with id="demo"
  //         setTimer([days, hours, minutes, seconds])

  //         // If the count down is finished, write some text
  //         if (distance < 0) {
  //             clearInterval(x);
  //             setTimer([0, 0, 0, 0])
  //         }
  //     }, 1000);
  // }
  // useEffect(() => {
  //     Countdown()
  // })

  const [ShowClaim, setShowClaim] = useState(false);
  const handleShowClaimModal = () => setShowClaim(true);
  const handleHideClaimModal = () => setShowClaim(false);

  // useEffect(() => {
  //   if (networkDetails && networkDetails.connected) {
  //     setLoading(true);
  //     getMysteryDataApi();
  //     loadMysteryInstance();
  //   } else {
  //     setLoading(false);
  //     return toast.warning("You have to connect first !", "Attention");
  //   }
  // }, [networkDetails]);

  useEffect(() => {
    setLoading(false);
    if (networkDetails && networkDetails.connected) {
      getMysteryDataApi();
      loadMysteryInstance();
    } else {
      setLoading(false);

      if (networkDetails && networkDetails.isUpdated) {
        toast.error("Please Connect with Metamask first.");
      }
    }
  }, [networkDetails]);

  useEffect(() => {
    return () => {
      setInstance(null);
      setMysteryData({});
      setMysteryDetailsSc({});
    };
  }, []);
  // useEffect(() => {
  //   if (
  //     instance !== null &&
  //     Object.keys(mysteryData).length > 0 &&
  //     networkDetails.connected
  //   ) {
  //     loadMysteryBoxDetailsSc();
  //     loadTotalTickets();
  //     getOpenBoxCount();
  //     getClaimBoxCount();
  //     getUserTreasuryBoxes();
  //   }
  // }, [instance, mysteryData]);

  // const loadMysteryBoxDetailsSc = async () => {
  //   await createMysteryMethods
  //     .getMysteryBoxDetailsSC(
  //       instance,
  //       mysteryData.mysterySC_id,
  //       networkDetails.address
  //     )
  //     .then((response) => {
  //       setMysteryDataSc(response);
  //     })
  //     .catch((err) => {
  //     });
  // };

  // const loadTotalTickets = async () => {
  //   await createMysteryMethods
  //     .loadTotalTickets(instance, networkDetails.address)
  //     .then((response) => {

  //       setTotalTickets(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };



  const nftImage = name => {
    let El = null;
    switch (name) {
      case "IGO Allocation Token":
        El = <img
          alt="inverntory_1"
          src={igo_allocation}
          width="120px"
          height="120px"
        />;

        break;
      case "Airdrop":
        El = <img
          alt="inverntory_1"
          src={giftBox}
          width="120px"
          height="120px"
        />;

        break;
      case "IGO+NFT":
        El = <img
          alt="inverntory_1"
          src={igo_nft}
          width="120px"
          height="120px"
        />;

        break;
      case "Premium NFT":
        El = <img
          alt="inverntory_1"
          src={prem_nft}
          width="120px"
          height="120px"
        />;
        break;
      default:
        El = <img
          alt="inverntory_1"
          src={igo_nft}
          width="120px"
          height="120px"
        />;;

        break;
    }
    return El
  };


  const setMysteryDetails = async (Instance) => {
    try {
      let obj = {};
      if (Object.keys(mysteryData).length > 0) {

        await Instance.methods
          .mysteryBoxes(mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }, (err, data) => {
            if (err) {
              toast.error("Error in fetching mystery box details.");
            }
            if (data) {
              let temp = {
                availableBoxes: data["availableBoxes"],
                claimTime: data["claimTime"],
                endTime: data["endTime"],
                Name: data["Name"],
                startTime: data["startTime"],
                totalTreasureBoxes: data["totalTreasureBoxes"],
                itemCount: data["itemCount"],
                mysteryBoxId: data["mysteryBoxId"],
              };

              obj.mysteryBoxDetail = temp;
            }
          });

        await Instance.methods
          .totalticket(networkDetails.address)
          .call({ from: networkDetails.address }, (err, data) => {
            if (err) {
              toast.error("Error in fetching total tickets.");
            }
            if (data) {
              obj.totalTickets = data;
            }
          });

        await Instance.methods
          .openBoxCount(mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }, (err, data) => {
            if (err) {
              toast.error("Error in fetching open box count.");
            }
            if (data) {
              obj.openBoxCount = data;
            }
          });

        await Instance.methods
          .claimBoxCount(mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }, (err, data) => {
            if (err) {
              toast.error("Error in fetching claim box count.");
            }
            if (data) {
              obj.claimBoxCount = data;
            }
          });

        await Instance.methods
          .UserTreasuryBoxes(networkDetails.address, mysteryData.mysterySC_id)
          .call({ from: networkDetails.address }).then(async (res) => {
            obj.treasuryBoxCount = res;
            if (res && res.length > 0) {

              let treasuryDetails = [];

              for (let i = 0; i < res.length; i++) {

                await Instance.methods
                  .boxes(parseInt(res[i]))
                  .call({ from: networkDetails.address }, (err, res) => {
                    if (err) {

                      toast.error("Error in treasurybox details.");
                      treasuryDetails.push("");
                    }
                    if (res) {

                      treasuryDetails.push(res);
                    }
                  });
              }


              obj.treasuryBoxDetails = treasuryDetails;
            }

          }).catch((err) => {
            console.log(err)
          })


        // , (err, data) => {
        //   if (err) {

        //     toast.error("Error in fetching treasury boxes.");
        //   }
        //   if (data) {
        console.log('--------------obj----------', obj)
        //
        //   }
        // }
      }
      setMysteryDetailsSc(obj);
      setLoading(false);
      setRefresh(false);
    } catch (err) {
      console.log(err, "errr000000000000")
      setLoading(false);
    }
  };

  useEffect(() => {
    if (instance !== null && Object.keys(mysteryData).length > 0 && refresh) {
      setMysteryDetails(instance);
      const getAllMysteryItems = mysteryData.itemDetails.filter(
        (mystery) => mystery.set_status && mystery.set_status !== undefined && mystery.set_status === 1
      );
      setAllMysteryItems(getAllMysteryItems)
    }
  }, [mysteryData, instance, refresh]);

  const loadMysteryInstance = async () => {
    await createMethods
      .getInstance(
        networkDetails.web3,
        enviornment[networkDetails.networkId].mysterySC,
        enviornment[networkDetails.networkId].mysteryABI
      )
      .then((data) => {
        setInstance(data);
      })
      .catch((err) => {
        toast.error("There is some error while creating instance.");

        setLoading(false);
      });
  };

  const getMysteryDataApi = useCallback(async () => {
    if (mysteryId) {
      await createMysteryMethods
        .getMystery(mysteryId)
        .then((response) => {
          if (response.data.success) {
            setMysteryData(response.data.data);
          } else {
            toast.error(
              "Their is some error while fetching data. Please try again after sometime."
            );
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [mysteryId]);

  const handleOpneBox = () => {
    if (
      instance !== null &&
      networkDetails &&
      networkDetails.connected &&
      Object.keys(mysteryData).length > 0
    ) {

      setLoading(true);
      instance.methods
        .buyTreasurybox(mysteryData.mysterySC_id)
        .send({ from: networkDetails.address })
        .on("transactionHash", async function (transactionHash) {
          setRefresh(true);
          // setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "Error while opening mystery box, please try again after sometime."
          );
        });
    }
  };
  const history = useHistory();

  // const onclickOpened = () => {
  // console.log('------------------------onclick opened')

  // }
  return (
    <>
      {loading && <Loader />}
      <Container fluid className="detail_page px-md-5 px-4">
        <p className="close_app">
          {/* Applications close on 27 Nov 2021, 1:00 PM UTC{" "} */}
          {/* <Icon name="arrownext" /> */}
        </p>
        <Row className="mb-3">
          <Col xl={7}>
            <div className="subhead">
              <div className="subhead_logo ">
                <img
                  alt="alter image2"
                  style={{ maxWidth: "72px", maxHeight: "72px" }}
                  src={mysteryData.projectLogo ? mysteryData.projectLogo : img1}
                ></img>
              </div>
              <div className="subhead_content">
                <h1>
                  {mysteryData.projectName ? mysteryData.projectName : ""}
                </h1>
                <p>
                  {mysteryData.projectTitle ? mysteryData.projectTitle : ""}
                </p>
              </div>
            </div>
          </Col>
          <Col xl={5} className=" d-flex flex-column align-items-xl-end">
            <div className="sale_timer_wrap max-w-250px">

              <Button variant="blue" onClick={() => history.goBack()} >Back</Button> &nbsp;
              <Button variant="blue" onClick={() => window.location.reload()} >Refresh</Button>

              <p className="mt-2">
                {mysteryData &&
                  mysteryData.status === "upcoming" &&
                  "Claim Period Starts in"}
                {mysteryData &&
                  mysteryData.status === "ongoing" &&
                  "Claim Period Ends in"}
                {mysteryData &&
                  mysteryData.status === "completed" &&
                  "Completed"}
              </p>
              {/* expired class add after time end */}

              {mysteryData && mysteryData.status === "upcoming" ? (
                <Countdown
                  date={parseInt(mysteryData.start_date + "000")}
                  renderer={DetailTimer}
                />
              ) : (
                mysteryData &&
                mysteryData.status === "ongoing" && (
                  <Countdown
                    date={parseInt(mysteryData.end_date + "000")}
                    renderer={DetailTimer}
                  />
                )
              )}
              {mysteryData && mysteryData.status === "completed" && (
                <Countdown
                  date={parseInt(mysteryData.end_date + "000")}
                  renderer={DetailTimer}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className="mb-3 justify-content-center">
          <Col xl={12}>
            {/* <div className="PR_slide mb-0 h-450px PR_Matbox mb-3 mb-xl-0"> */}
            {/* <div className="PR_TopShape">
                Unlock in {timer[0]}D:{timer[1]}H:{timer[2]}M
              </div> */}
            {/* <img src={img2} alt="mystery-main" /> */}

            {/* className="hide" before timer end  */}

            {/* {mysteryData &&
                mysteryData.status === "ongoing" &&
                mysteryDetailsSc && mysteryDetailsSc.mysteryBoxDetail && (
                  parseInt(mysteryDetailsSc.claimBoxCount) !==
                  parseInt(
                    mysteryDetailsSc.mysteryBoxDetail.totalTreasureBoxes
                  )) && (
                  <Button variant="yellow" onClick={handleOpneBox}>
                    Open your box
                  </Button>
                )} */}
            {/* </div> */}
            {/* <div className="box_golden">
              <img src={box_golden} alt="my_box" />
            </div> */}
            <div className="mystrybox_slides">
              <Mystryboxslider mysteryDetailsSc={mysteryDetailsSc} mysteryData={mysteryData} handleOpneBox={handleOpneBox} openedBoxDetails={mysteryDetailsSc.treasuryBoxDetails ? mysteryDetailsSc.treasuryBoxDetails : []} leftBoxes={(mysteryDetailsSc &&
                mysteryDetailsSc.totalTickets &&
                parseInt(mysteryDetailsSc.totalTickets)) -
                (mysteryDetailsSc &&
                  mysteryDetailsSc.claimBoxCount &&
                  parseInt(mysteryDetailsSc.claimBoxCount))} />
            </div>

          </Col>
          {mysteryDetailsSc &&
            mysteryDetailsSc.totalTickets &&
            parseInt(mysteryDetailsSc.totalTickets) > 0 ? (<div className="sale_timer_wrap  text-center text-white">
              <div className="d-flex flex-wrap justify-content-center mb-3">
                <p className="mr-2 ">
                  This address is eligible to open & claim at total{" "}
                  {mysteryDetailsSc && mysteryDetailsSc.totalTickets
                    ? mysteryDetailsSc.totalTickets
                    : 0}{" "}
                  Treasury Box
                </p>


                <p>

                  You have opened already{" "}
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.treasuryBoxCount &&
                    mysteryDetailsSc.treasuryBoxCount.length}
                  /
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.totalTickets &&
                    mysteryDetailsSc.totalTickets}{" "}
                  Treasury Box
                </p>
              </div>

              <div className="sale_timer_para d-flex justify-content-center">
                <p className="mr-2 ">
                  Total boxes left to claim{" "}
                  {(mysteryDetailsSc.totalTickets != undefined && mysteryDetailsSc.totalTickets != 0 ? (mysteryDetailsSc &&
                    mysteryDetailsSc.totalTickets &&
                    parseInt(mysteryDetailsSc.totalTickets)) -
                    (mysteryDetailsSc &&
                      mysteryDetailsSc.claimBoxCount &&
                      parseInt(mysteryDetailsSc.claimBoxCount)) : 0)}{" "}
                  Treasury Box
                </p>


                <p>
                  {/* availableBoxes */}
                  Available  {
                    mysteryDetailsSc && mysteryDetailsSc.mysteryBoxDetail != undefined && mysteryDetailsSc.mysteryBoxDetail.availableBoxes != undefined ? mysteryDetailsSc.mysteryBoxDetail.availableBoxes : 0
                  } Treasury boxes in this mysterybox.

                </p>

              </div>
            </div>) : (
            <div className="sale_timer_wrap  text-center text-white">
              <div className="d-flex flex-wrap justify-content-center mb-3">
                <p className="mr-2 ">
                  You dont have ticket to Participate.
                </p>
              </div>
            </div>)
          }



          {/* <Col xl={7} className="PR_MyContentBoxMain">
                    <div className="PR_MyContentBox">
                        <div className='d-flex flex-md-row flex-column '>
                            <span className="text-orange "><Icon name="mystery" className="mr-2 w-50px h-50px " /></span>

                            <div>
                                <div className="fs-xl-32px fs-24px "><span className="text-white">You have been assigned
                                    a MysteryBox which worth $500 USD. </span></div>
                                <div className="fs-xl-20px fs-16px text-white mt-3 opacity-70 fw-400  ">Efficiently synthesize bricks-and-clicks channels and interactive infomediaries.
                                    Quickly mesh high-payoff
                                    expertise via bleeding-edge data. Completely benchmark web-enabled supply chains rather than multifunctional systems. Globally customize sustainable
                                    total linkage via technically sound leadership skills. Energistically strategize intuitive infrastructures whereas virtual leadership. Globally.</div>
                                <div className='PR_menu '>
                                    <p>Price of box</p>
                                    <h1>$500/USD</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col> */}
        </Row >
        <Row className="mb-4 align-items-center gift_box_padd">
          <Col xl={5}>
            <p>You could get the secret gifts in box:</p>
            <div className="d-flex  flex-lg-row flex-md-row flex-column">
              <div className="PR_giftBox">
                <div className="d-inline-block position-relative">
                  {/* <Icon name="check" className="PR_checkmark" /> */}
                  <img src={igo_allocation} alt="" width="64px" height="64px" />
                </div>
                {/* <div className="text">In 9D: 7H: 3M</div> */}
                <div className="gift_box_name">
                  <p>IGO Allocation </p>
                </div>
              </div>
              <div className="PR_giftBox">
                <div className="d-inline-block position-relative">
                  {/* <Icon name="check" className="PR_checkmark" /> */}
                  <img src={giftBox} alt="" width="64px" height="64px" />
                </div>
                {/* <div className="text">In 9D: 7H: 3M</div> */}
                <div className="gift_box_name">
                  <p>Free Airdrop</p>
                </div>
              </div>
              <div className="PR_giftBox">
                <div className="d-inline-block position-relative">
                  {/* <Icon name="check" className="PR_checkmark" /> */}
                  <img src={igo_nft} alt="" width="64px" height="64px" />
                </div>
                <div className="gift_box_name">
                  <p>Premium NFTs</p>
                </div>
              </div>
              <div className="PR_giftBox ">
                <div className="d-inline-block position-relative">
                  {/* <Icon name="check" className="PR_checkmark" /> */}
                  <img src={prem_nft} alt="" width="64px" height="64px" />
                </div>
                <div className="gift_box_name">
                  <p>IGO Allocation + NFTs</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={7}>
            <div className="d-flex flex-wrap justify-content-around">
              <div className="text-center px-4 w-175px">
                <div className="fs-xl-32px fs-24px fw-700 text-white">
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.mysteryBoxDetail &&
                    mysteryDetailsSc.mysteryBoxDetail.totalTreasureBoxes}
                </div>
                <div className="fs-xl-16px fs-14px fw-500 text-white opacity-70">
                  Total Treasury Boxes
                </div>
              </div>
              <div className="text-center px-4 w-175px">
                <div className="fs-xl-32px fs-24px fw-700 text-white">
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.openBoxCount &&
                    mysteryDetailsSc.openBoxCount}
                </div>
                <div className="fs-xl-16px fs-14px fw-500 text-white opacity-70">
                  Participants
                </div>
              </div>
              <div className="text-center px-4 w-175px">
                <div className="fs-xl-32px fs-24px fw-700 text-white">
                  {mysteryDetailsSc &&
                    mysteryDetailsSc.claimBoxCount &&
                    mysteryDetailsSc.claimBoxCount}
                </div>
                <div className="fs-xl-16px fs-14px fw-500 text-white opacity-70">
                  Boxes Claimed
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Tabs
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          className="TL_tabs"
        >
          <Tab eventKey="1" title="Rule Introduction">
            <div className="max-w-850px">
              <h2>Short Description:</h2>
              {
                mysteryData && <p className="mb-5" dangerouslySetInnerHTML={{ __html: mysteryData.projectShortDesc }} ></p>
              } 
              <div className="main_banner mb-5">
                <img
                  alt="alter image12"
                  src={
                    mysteryData.projectBanner ? mysteryData.projectBanner : img2
                  }
                />
              </div>
              <h2>Long Description:</h2>

              {
                mysteryData && <p className="mb-5" dangerouslySetInnerHTML={{ __html: mysteryData.projectLongDesc }} ></p>
              }

              {/* <ul className="custom_listing mb-5">
                <li>
                  <span> Dynamically: </span> Collaboratively unleash extensive
                  core competencies through global platforms.Appropriately
                  supply long-term high-impact total linkage after high-quality
                  networks.Interactively maximize emerging vortals via market
                  positioning alignments.Dynamically engineer adaptive
                  e-commerce via team driven communities.Quickly exploit
                  intermandated innovation whereas high-payoff nickhes
                  corporate.
                </li>
                <li>
                  <span>Bandwidth: </span> Completely reconceptualize
                  enterprise-wide information with progressive
                  deliverables.Conveniently impact mission-critical web services
                  rather than strategic bandwidth.Progressively evisculate
                  global "outside the box" thinking with innovative
                  e-markets.Credibly embrace resource-leveling technologies and
                  backend relationships.Objectively disseminate interoperable
                  bandwidth with pandemic methods of empowerment.
                </li>
                <li>
                  <span>Dramatically: </span> Rapidiously synergize functional
                  convergence are after provided by Phosfluorescently drive
                  customer directed testing procedures via corporate
                  infrastructures.Dramatically fabricate low-risk high-yield
                  imperatives via competitive applications.
                </li>
              </ul> */}
            </div>
          </Tab>
          {/* <Tab eventKey="2" title="Opened Box">
            <h1>Opened Box</h1>
            <div className="max-w-850px">
              <Row>
                {mysteryDetailsSc &&
                  mysteryDetailsSc.treasuryBoxDetails &&
                  mysteryDetailsSc.treasuryBoxDetails.length > 0 && mysteryDetailsSc.treasuryBoxDetails.map((item, index) =>
                    <Col xl={6} lg={6}>
                      <div className="main_banner mt-3 p-2 h-auto">
                        <div className="d-flex align-items-center">
                          <div className="inventory_left_img">
                            <img
                              alt="inverntory_1"
                              src={inverntory_1}
                              width="120px"
                              height="120px"
                            />
                          </div>
                          <div className="inventory_right ml-3">
                            <h3>{item["offereditemName"]}</h3>
                            <ul className="pl-0">
                              <li>$190 |</li>
                              <li className="opacity_7 pl-2 ">901 / 950x</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}

              </Row>
            </div>
          </Tab> */}
          <Tab eventKey="3" title="Inventory">
            <h1>Inventory</h1>
            <div className="max-w-850px mt-2 mb-5">
              <Row>

                {allMysteryItems &&
                  allMysteryItems.length > 0 ? allMysteryItems.map((item, index) =>

                    <Col xl={6} lg={6} key={item["_id"]} >
                      <div className="main_banner mt-3 p-2 invent-box-height">
                        <div className="d-flex align-items-center">
                          <div className="inventory_left_img">
                            {nftImage(item["item_category"])}
                          </div>
                          <div className="inventory_right ml-3">
                            <h3>{item["item_category"]}</h3>
                            <ul className="pl-0">
                              <li>{parseInt(item['item_value'])} of {parseInt(item['item_count']) * parseInt(item['item_amount'])} </li>
                              {/* <li className="opacity_7 pl-2 ">901 / 950x</li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : <Col xl={6} lg={6} ><div className=" text-white">No boxes available</div></Col>}

              </Row>
            </div>
          </Tab>
          {/* <Tab eventKey="3" title="Series Content">
                    <h2>Comingsoon..</h2>
                </Tab>
                <Tab eventKey="4" title="Timeline">
                    <h2>Comingsoon..</h2>
                </Tab>
                <Tab eventKey="5" title="Collection (0)">
                    <h2>Comingsoon..</h2>
                </Tab> */}
        </Tabs>
      </Container >
      <Modal
        show={ShowClaim}
        size="xl"
        onHide={handleHideClaimModal}
        centered
        className="PR_modalBlack"
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={5}>
              <div className="PR_claimImagBox height_box">
                <div className="PR_TopShape text-uppercase">
                  You have earned
                </div>
                <img src={img2} alt="mystery-main" />
                <div className="PR_bottom text-center text-white fs-xl-20px fs-16px fw-500 w-100">
                  {" "}
                  <h4>$50</h4>
                  <p>$AGG</p>{" "}
                </div>
              </div>
              <div className="open_goldenbox">
                <img src={box_golder_open} alt="mystery-main" />
              </div>
            </Col>
            <Col lg={7}>
              <div className="fs-xl-32px fs-24px ">
                <span className="text-orange">
                  <Icon name="mystery" className="mr-2 w-50px h-50px" />
                </span>
                <span className="text-white">NFT to be revealed</span>
              </div>
              <hr />
              <div>
                <div className="d-inline-block mr-3">
                  <p className="mb-2">Supported</p>
                  <div className="PR_fieldBox2 mb-1">
                    <img
                      src={img3}
                      className="mr-3"
                      width="24px"
                      height="24px"
                      alt=""
                    />
                    USDT
                  </div>
                </div>
                <div className="d-inline-block">
                  <p className="mb-2">Chain Blockchain</p>
                  <div className="PR_fieldBox2 mb-1">
                    <img
                      src={binance}
                      className="mr-3"
                      width="24px"
                      height="24px"
                      alt=""
                    />
                    Binance Smart Chain
                  </div>
                </div>
              </div>
              <div className="max_total">
                {/* <div className="d-inline-block">
                                <p className="mb-2">Amount</p>
                                <div className="PR_fieldBox2 mb-1">01</div>
                            </div> */}
                <div className="d-inline-block ">
                  <p className="mb-2">Purchase Cost</p>
                  <div className="PR_fieldBox2 mb-1">$50.00</div>
                </div>
                {/* <div className="d-inline-block">
                                <p className="mb-2">Total Cost</p>
                                <div className="PR_fieldBox2 mb-1">725/1,000</div>
                            </div> */}
              </div>
              <div className="sale_timer_wrap ">
                <p>Time left to purchase your Mystery Box</p>
                {/* expired class add after time end */}
                <div className="sale_timer d-flex justify-content-start expired">
                  {/* <div className="time">{timer[0]}<span>DAY</span></div> */}
                  <div className="time">
                    {timer[1]}
                    <span>HOUR</span>
                  </div>
                  <div className="time">
                    {timer[2]}
                    <span>MIN</span>
                  </div>
                  <div className="time">
                    {timer[3]}
                    <span>SEC</span>
                  </div>
                </div>
              </div>
              <div className="progress_wrap">
                <p className="m-0">Total Boxes Claimed</p>
                <div className="re_slider1">
                  <RSlider
                    defaultValues={[75]}
                    domain={domain}
                    mode={2}
                    step={1}
                    left={true}
                  />
                </div>
                <div className="progress_value pt-2">
                  <div id="rangeValue">75%</div>
                  <div className="amnt">20,000 / 30,000Boxes</div>
                </div>
              </div>
              {/* className="hide" before timer end  */}
              <Button
                variant="yellow"
                className=" mt-4 buy_now max-w-100"
                onClick={handleShowClaimModal}
              >
                {" "}
                <img
                  src={cart}
                  alt="cart"
                  className="opacity_7 mr-2 "
                  width="20px"
                  height="20px"
                />
                Purchase Now
              </Button>
              {/* <p className="mb-2">Amount</p>
                        <div className="PR_fieldBox mb-3">1</div>
                        <p className="mb-2">Bought/Max</p>
                        <div className="PR_fieldBox mb-3">725/1,000</div> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MysteryBoxes;
