import React, { useEffect, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { Table, Button, Col } from "react-bootstrap";
import { Web3Context } from "../web3/contexts/web3Context";
import { createMysteryMethods } from "../functions/createMystery";
import { toast } from "react-toastify";
import DeleteFaqModal from "./yesNoModal";

const ViewMysteryBox = (props) => {
  const { networkDetails } = useContext(Web3Context);
  const [selectedItem, setSelectedItem] = useState({})
  const [showModalDelete, setShowModalDelete] = useState(false);
  const {
    onCloseModal,
    showModal,
    loading,
    setLoading,
    mysteryDetails,
    getMysteryBoxes,
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deleteCloseModal = () => {
    setShowModalDelete(false);
  };
  useEffect(() => {
    return (() => {
      setSelectedItem({})
    })
  }, [])

  const removeItem = async (itemId) => {
    setLoading(true);
    await createMysteryMethods
      .removeItem(itemId)
      .then((resApprove) => {
        toast.info("Item removed successfully.");
        setLoading(false);
        getMysteryBoxes();
        setShowModalDelete(false)
        onCloseModal();
      })
      .catch((er) => {
        if (er && er.code) {
          toast.error(er.message);
          setLoading(false);
        }
      });
  };
  const deleteModal = async (res) => {
    setSelectedItem(res)
    setShowModalDelete(true);
  }
  var stringToHTML = (str) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={onCloseModal}
        className="PR_modal"
        centered
        size="lg"
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">Mystery Box</div>
        </Modal.Header>
        <Modal.Body className="px-4">
          <>
            <Col md={4} lg={8}>
              <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                  Network Type :{" "}
                  {mysteryDetails ? mysteryDetails.network_type : ""}
                </label>
              </div>
            </Col>
            <Col md={4} lg={8}>
              <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                  Project Name :{" "}
                  {mysteryDetails ? mysteryDetails.projectName : ""}
                </label>
              </div>
            </Col>

            <Col md={4} lg={8}>
              <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                  Project Title :{" "}
                  {mysteryDetails ? mysteryDetails.projectTitle : ""}
                </label>
              </div>
            </Col>

            <Col md={4} lg={8}>
              <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                  Project Long Desc:{" "}
                  {
                    mysteryDetails && <p className="text-break" dangerouslySetInnerHTML={{ __html: mysteryDetails.projectLongDesc }} ></p>
                  }
                </label>
              </div>
            </Col>
            <Col md={4} lg={8}>
              <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                  Project Short Desc :{" "}
                  {
                    mysteryDetails && <p className="text-break" dangerouslySetInnerHTML={{ __html: mysteryDetails.projectShortDesc }} ></p>
                  }

                </label>
              </div>
            </Col>
            <Col md={4} lg={8}>
              <div className="form-group">
                <label className="fs-14px text-white opacity-70">
                  Status : {mysteryDetails ? mysteryDetails.status : ""}
                </label>
              </div>
            </Col>

            <Table className="text-white">
              <thead>
                <tr>
                  <th> Sr no.</th>
                  {/* <th>Image</th> */}
                  <th> Item Category</th>
                  <th>Item Amount</th>
                  <th>Item Count</th>
                  <th>Item Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {mysteryDetails &&
                  mysteryDetails.itemDetails &&
                  mysteryDetails.itemDetails.length ? (
                  mysteryDetails.itemDetails.map((res, ind) => {
                    return (
                      <tr key={res._id}>
                        <td>{ind + 1}</td>
                        <td>{res.item_category}</td>
                        <td>
                          {res.item_amount && res.item_amount !== ""
                            ? res.item_amount
                            : "-"}
                        </td>
                        <td>{res.item_count}</td>
                        <td>{res.item_value}</td>
                        <td>
                          {(res.set_status == undefined && res.set_status !== 0 &&
                            res.set_status !== 1) && (
                              <div className="btn btn-blue  mr-2 mb-2">
                                <Button
                                  type="button"
                                  onClick={() => deleteModal(res)}
                                  variant="blue"
                                  style={{ fontSize: "14px" }}
                                >
                                  Remove
                                </Button>
                              </div>
                            )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan="9"> No records Found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <DeleteFaqModal
              modalShow={showModalDelete}
              onCloseModal={deleteCloseModal}
              deleteData={() => removeItem(selectedItem && selectedItem._id)}
            />
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewMysteryBox;
