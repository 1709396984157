import React from "react";

const DetailTimer = ({ days, hours, minutes, seconds, props }) => {
  // console.log('----------detail comp',props.vaultdata)
  // Render a countdown
  return (
    <>
      <div className="sale_timer expired">
        {/* <div className="time">{timer[0]}<span>DAY</span></div> */}
        <div className="time">
          {days > 0 ? days * 24 + hours : hours}
          <span>HOUR</span>
        </div>
        <div className="time">
          {minutes}
          <span>MIN</span>
        </div>
        <div className="time">
          {seconds}
          <span>SEC</span>
        </div>
      </div>
    </>
  );
};

export default DetailTimer;
