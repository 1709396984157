import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col } from "react-bootstrap";
import { Web3Context } from "../web3/contexts/web3Context";
import { enviornment } from "../constants/constants";
import { campignMethods } from "../functions/campaign";
import { createMethods } from "../functions/create";
import { toast } from "react-toastify";

const AddWhiteList = (props) => {
  const { networkDetails } = useContext(Web3Context);
  const {
    whiteListModal,
    onCloseWhiteList,
    token_address,
    loading,
    setLoading,
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    address: "",
    ticket:""
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .trim()
      .required("Address is required.")
      .matches(/^0x[a-fA-F0-9]{40}$/, "please enter valid token address"),
      ticket: Yup.number()
      .required("Ticket is required").min(1,"Tickets is required.")
  });

  const onCloseModal = () => {
    onCloseWhiteList();
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
   

      setLoading(true);
      let obj = await getSCBalanceDetails();
      if (obj && obj.campaignInstance) {
        await campignMethods
          .whiteListUser(
            obj.campaignInstance,
            networkDetails.address,
            values.address,values.ticket
          )
          .then((resApprove) => {
            toast.info("Transaction success.");
            setLoading(false);
            onCloseModal();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const getSCBalanceDetails = async () => {
    let obj = {
      campaignAddress: "",
      campaignInstance: "",
      factoryInstance: "",
    };
    let factoryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].FACTORYContract,
      enviornment[networkDetails.networkId].FACTORYABI
    );

    if (factoryInstance && factoryInstance !== undefined) {
      obj.factoryInstance = factoryInstance;

      obj.campaignAddress = await campignMethods.tokenCampaign(
        factoryInstance,
        token_address,
        networkDetails.address
      );

      let campaignInstance = await createMethods.getInstance(
        networkDetails.web3,
        obj.campaignAddress,
        enviornment[networkDetails.networkId].compaignABI
      );
      obj.campaignInstance = campaignInstance;

      return obj;
    } else {
      return obj;
    }
  };

  return (
    <>
      <Modal
        show={whiteListModal}
        onHide={onCloseModal}
        className="PR_modal"
        centered
      >
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className=" py-2 text-center text-white">
            Add WhiteList Users
          </div>
        </Modal.Header>
        <Modal.Body className="px-4">
          <>
            <form className="row" onSubmit={formik.handleSubmit}>
              {/* <Col md={12}>
                <div className="h4 pb-3 ">Whitelist Address</div>
              </Col> */}
              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="address"
                    type="text"
                    placeholder="User address"
                    className={`form-control PR_input ${getInputClasses(
                      "address"
                    )}`}
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12} lg={12}>
                <div className="form-group">
                  <input
                    name="ticket"
                    type="text"
                    placeholder="Ticket /Allocation"
                    className={`form-control PR_input ${getInputClasses(
                      "ticket"
                    )}`}
                    {...formik.getFieldProps("ticket")}
                  />
                  {formik.touched.ticket && formik.errors.ticket ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.ticket}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col md={12}>
                <Button
                  type="submit"
                  variant="blue"
                  className="px-4"
                  disabled={loading}
                >
                  {loading ? "updating..." : "White list"}
                </Button>
              </Col>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddWhiteList;
