import React, { useContext, useEffect, useState } from "react";

import { Container, Table, Button, Col } from "react-bootstrap";
import { createMethods } from "../../functions/create";
import { campignMethods } from "../../functions/campaign";

import { Web3Context } from "../../web3/contexts/web3Context";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
import { enviornment } from "../../constants/constants";
import AddWhiteList from "../../Components/AddWhiteList";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment"
import AddMultipleWhiteList from "../../Components/AddMultipleWhiteList";


const Tranctions = () => {
  const { networkDetails } = useContext(Web3Context);
  const { campaignId } = useParams();

  const history = useHistory();
  const [whiteListModal, setAddWhiteListModal] = useState(false);
  const [multipleWhiteListModal, setMultipleWhiteListModal] = useState(false);


  // const inputRef = useRef(null);

  const [allCampaignsData, setAllCampaignsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCamp, setSelectedCamp] = useState({});
  const [ticketModal, setTicketModal] = useState(false);

  const onCloseTicketModal = () => {
    setTicketModal(false);
  };
  const onCloseWhiteList = () => {
    setAddWhiteListModal(false);
  };

  const onCloseMultipleWhiteList = () => {
    setMultipleWhiteListModal(false);
  };

  const initialValues = {
    address: "",
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required("Address is required")

  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        setLoading(true);

        let ticket = await createMethods.getTicket(values.address);

        if (ticket.data.success) {
          // setBal(ticket.data.data);
          setTicketModal(false)

          Swal.fire({
            title: "Tickets/Allocation = " + ticket.data.data,
            // text: ,
            icon: "success",
            button: "Ok",
          });
          resetForm()
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    },
  });

  const deleteCamp = async (id) => {

    // setLoading(true);
    let obj = {
      is_deleted: true,
      id: id
    }
    Swal.fire({
      title: 'Do you want to save the changes?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Hide',
      denyButtonText: `Don't hide`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let deletedCamp = await createMethods.deleteCamp(obj);

        if (deletedCamp.data.success) {

          Swal.fire('Campaign hided successfully.', '', 'success').then(async (inResult) => {
            window.location.reload();
          })
          // 
        }


      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })

    // setLoading(false);


  };
  useEffect(() => {
    setLoading(true);
    if (networkDetails && networkDetails.connected) {
      if (networkDetails.isAdmin) {
        getCamps();
      } else {
        setLoading(false);
        return toast.warning("You are not a admin.", "Attention");
      }
    }
    else {
      setLoading(false);

      if (networkDetails && networkDetails.isUpdated) {
        toast.warning("You have to connect first !", "Attention");
      }
    }
  }, [networkDetails]);

  const getCamps = async () => {
    let response = await createMethods.getAdminsDashboard();
    if (response.data.success) {
      setLoading(false);
      setAllCampaignsData(response.data.data);
    } else {
      setLoading(false);
      return toast.warning("Error while Fetching.", "Attention");
    }
  };
  //

  const getClassName = (status) => {
    switch (status) {
      case undefined: {
        return "Pending";
      }
      case null: {
        return "Pending";
      }
      case 0: {
        return "InProgress";
      }
      case 1: {
        return "Approved";
      }
      case -1: {
        return "Failed";
      }

      default:
        return "Pending";
    }
  };

  const approveCamp = async (token_address, id) => {
    if (networkDetails && networkDetails.connected) {
      let obj = await getSCBalanceDetails(token_address);
      setLoading(true);
      if (obj && obj.campaignInstance) {
        //

        await campignMethods
          .approveCampaign(obj.campaignInstance, networkDetails.address, {
            id: id,
          }, setLoading)
          .then((resApprove) => {
            toast.info("Transaction confirmed.");
            setLoading(false);
            // history.push("/dashboard");
            window.location.reload();
          })
          .catch((er) => {
            if (er && er.code) {
              toast.error(er.message);
              setLoading(false);
            }
          });
      }
    } else {
      return toast.warning("You have to connect first !", "Attention");
    }
  };

  const getSCBalanceDetails = async (token_address) => {
    let obj = {
      campaignAddress: "",
      campaignInstance: "",
      factoryInstance: "",
    };
    let factoryInstance = await createMethods.getInstance(
      networkDetails.web3,
      enviornment[networkDetails.networkId].FACTORYContract,
      enviornment[networkDetails.networkId].FACTORYABI
    );

    if (factoryInstance && factoryInstance !== undefined) {
      obj.factoryInstance = factoryInstance;

      obj.campaignAddress = await campignMethods.tokenCampaign(
        factoryInstance,
        token_address,
        networkDetails.address
      );

      let campaignInstance = await createMethods.getInstance(
        networkDetails.web3,
        obj.campaignAddress,
        enviornment[networkDetails.networkId].compaignABI
      );
      obj.campaignInstance = await campaignInstance;

      return obj;
    } else {
      return obj;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Container fluid className="px-md-5 px-4">
        <h1 className="text-white mb-5"> Campaigns History </h1>
        {/* <div className="re_uploadCoverBtn btn btn-blue  mr-3 mb-2">
          <input
            title=" "
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={inputRef}
            onChange={(e) => handleBannerPic(e)}
          />
          Edit Banner Image
        </div> */}

        <Table className="text-white">
          <thead>
            <tr>
              <th> Sr. no</th>
              <th> Campaign Name</th>
              <th>Token symbol</th>
              <th>Approval Status</th>
              <th>Creation Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allCampaignsData && allCampaignsData.length ? (
              allCampaignsData.map((res, ind) => {
                return (
                  <tr key={res._id}>
                    <td>
                      {ind + 1}
                    </td>
                    <td
                      onClick={() => {
                        history.push(`/detail/${res._id}`);
                      }}
                    >
                      {res.projectName}
                    </td>
                    <td>{res.tokenName}</td>
                    <td>{getClassName(res.approve_status)}</td>
                    <td>{moment(res.createdAt).format(
                      "LT, DD MMM y"
                    )}</td>
                    <td>
                      {getClassName(res.approve_status) === "Pending" && (
                        <Button
                          variant="blue"
                          onClick={() => {
                            approveCamp(res.token_address, res._id);
                          }}
                          className="px-4  text-uppercase"
                        >
                          Approve
                        </Button>
                      )}
                      {getClassName(res.approve_status) === "Approved" && (
                        <Button
                          variant="blue"
                          onClick={() => {
                            setSelectedCamp(res);
                            setAddWhiteListModal(true);
                          }}
                          className="px-4  mb-2 text-uppercase"
                        >
                          WhiteList
                        </Button>
                      )}{' '}
                      {getClassName(res.approve_status) === "Approved" && (
                        <Button
                          variant="blue"
                          onClick={() => {
                            setSelectedCamp(res);
                            setMultipleWhiteListModal(true);
                          }}
                          className="px-4 mb-2 text-uppercase"
                        >
                          Batch WhiteList
                        </Button>
                      )}{' '}
                      {getClassName(res.approve_status) === "Approved" && (
                        <Button
                          variant="blue"
                          onClick={() => {
                            setSelectedCamp(res);
                            setTicketModal(true);
                          }}
                          className="px-4  mb-2 text-uppercase"
                        >
                          Check Allocation
                        </Button>
                      )}{' '}
                      {res.is_deleted === false && (
                        <Button
                          variant="blue"
                          onClick={() => {
                            setSelectedCamp(res);
                            deleteCamp(res._id);
                          }}
                          className="px-4 mb-2 text-uppercase"
                        >
                          Hide
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan="6"> No records Found.</td>
              </tr>
            )}
          </tbody>
        </Table>

        <AddWhiteList
          token_address={selectedCamp && selectedCamp.token_address}
          whiteListModal={whiteListModal}
          onCloseWhiteList={onCloseWhiteList}
          loading={loading}
          setLoading={setLoading}
        />

        <AddMultipleWhiteList
          token_address={selectedCamp && selectedCamp.token_address}
          multipleWhiteListModal={multipleWhiteListModal}
          onCloseMultipleWhiteList={onCloseMultipleWhiteList}
          loading={loading}
          setLoading={setLoading}
        />

        <Modal
          show={ticketModal}
          onHide={onCloseTicketModal}
          size=""
          centered
          className="PR_modal"
        >
          <Modal.Header className="px-4 pb-0 border-0" closeButton>
            <div className=" py-2 text-center text-white">Check Ticket/Allocation </div>
          </Modal.Header>

          <Modal.Body className="px-4">
            <>
              <form className="row" onSubmit={formik.handleSubmit}>
                {/* <Col md={12}>
                <div className="h4 pb-3 ">Whitelist Address</div>
              </Col> */}
                <Col md={12} lg={12}>
                  <div className="form-group">
                    <input
                      name="address"
                      type="text"
                      placeholder="User Address"
                      className={`form-control PR_input ${getInputClasses(
                        "address"
                      )}`}
                      {...formik.getFieldProps("address")}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block text-danger">
                          {formik.errors.address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col md={12}>
                  <Button
                    type="submit"
                    variant="blue"
                    className="px-4"
                  // disabled={loading}
                  >
                    {loading ? "Checking..." : "Check"}
                  </Button>{" "}
                  &nbsp; &nbsp;

                </Col>
              </form>
            </>
          </Modal.Body>
        </Modal>

      </Container>

    </>
  );
};
export default Tranctions;
