import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import box_golden from "../assets/images/box_golden.png";
import img2 from "../assets/images/hero_banner.png";
import { useHistory, useParams } from "react-router-dom";
export const Mystryboxslider = ({
  mysteryDetailsSc,
  leftBoxes,
  handleOpneBox,
  mysteryData,
  openedBoxDetails,
}) => {
  const [staticBoxesToDisplay, setStaticBoxesToDisplay] = useState([]);
  const { mysteryId } = useParams();
  const history = useHistory();
  // const [openedBoxDetails, setOpenedBoxDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    // accessibility: true,
    slidesToScroll: 1,
    // lazyLoad: 'progressive',
    // initialSlide: 0,
    centerMode: true,
    centerPadding: "60px",
    responsive: [

      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "10px",

        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (
      Object.keys(mysteryDetailsSc).length > 0 &&
      mysteryDetailsSc.totalTickets &&
      mysteryDetailsSc.openBoxCount
    ) {
      setLoading(true);
      const arr = [];
      const response =
        parseInt(mysteryDetailsSc.totalTickets) -
        parseInt(mysteryDetailsSc.openBoxCount);

      if (openedBoxDetails && openedBoxDetails.length > 0) {

        for (let i = 0; i < parseInt(openedBoxDetails.length); i++) {
          console.log('---------------------------------1', openedBoxDetails[i])
          arr.push(openedBoxDetails[i]);
        }
      }

      if (response > 0 && mysteryData && mysteryData.status !== "completed") {
        for (let i = 0; i < parseInt(response); i++) {
          console.log('-----------22----------------------',)

          arr.push({ item: "static" });
        }
      }



      setStaticBoxesToDisplay(arr);
      setLoading(false);
    }
  }, [mysteryDetailsSc, openedBoxDetails, mysteryData]);

  return (
    <>
      {loading ? (
        <h1 className="text-center">Loading....</h1>
      ) : (
        <Slider {...settings}>
          {staticBoxesToDisplay.length > 0 &&
            mysteryData &&
            staticBoxesToDisplay.map((x, i) => (
              // <>
              // {i == 0 && ( 
              <div className="mystrybx_slides_box" key={i}>
                <div className="PR_TopShape">
                  Ready to unlock{" "}
                  {leftBoxes && leftBoxes !== NaN ? leftBoxes : 0} boxes
                </div>
                {/* <img src={img2} alt="mystery-main" /> */}
                <div className="box_golden">
                  <img src={box_golden} alt="my_box" />
                  {/* <h1>{i}</h1> */}
                </div>
                <div className="open_your_btn" style={{ display: 'none' }}>
                  {x.item === undefined &&
                    mysteryData &&
                    mysteryData.status !== "upcoming" ? (
                    <Button
                      variant="yellow"
                      onClick={() =>
                        history.push(
                          `/mystery-boxes-details/${mysteryId}/${x["boxid"]}`
                        )
                      }
                    >
                      {x['isclaimed'] ? 'View' : 'Claim'} your box
                    </Button>
                  ) : (
                    mysteryData &&
                    mysteryData.status !== "upcoming" && (
                      <Button variant="yellow" onClick={handleOpneBox}>
                        Open your box
                      </Button>
                    )
                  )}
                </div>
                {/* {mysteryData.status !=="upcoming"&&
              <div className="open_your_btn">
              
              </div>
              } */}
              </div>
              // )}
              // </>
            ))}
          {(staticBoxesToDisplay.length == 0 || staticBoxesToDisplay.length == 1 || staticBoxesToDisplay.length == 2 || staticBoxesToDisplay.length == 3) && (
            <>
              <div  >

              </div>

            </>)}
          {(staticBoxesToDisplay.length == 0 || staticBoxesToDisplay.length == 1 || staticBoxesToDisplay.length == 2) &&
            (
              <>
                <div >

                </div>

              </>)}
          {(staticBoxesToDisplay.length == 0 || staticBoxesToDisplay.length == 1) &&
            (
              <>
                <div >

                </div>

              </>)}
          {(staticBoxesToDisplay.length == 0) &&
            (
              <>
                <div >

                </div>

              </>)}
        </Slider>
      )}
    </>
  );
};
