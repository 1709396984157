import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const TLSlider = ({ children }) => {
  const slick = useRef()
  const settings = {
    draggable: false,
    arrows: false,
    dots: true,
    autoplay: true,
    fade: true,
    speed: 900,
    infinite: true,
    cssEase: 'ease-in-out',
  }
  return (
    <>
      <Slider ref={slick} {...settings}>
        {children}
      </Slider>
    </>
  )
}
